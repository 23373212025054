import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import LoadingBar from '../../../Components/LoadingBar';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Pagination from '../../../Components/Pagination';
import Util from '../../../Util/Util';
import { Link } from 'react-router-dom';
import { Card, MuiThemeProvider } from '@material-ui/core';
import SortArrows from '../../../Components/SortArrows';
import { loadPointGetLog } from '../../../Redux/Actions/pointGetLog';
import { themeNormalModal } from '../theme_modal';
import { TEXT_HTML_POINT_LOG_MODAL } from '../../../Common/modal_text_info';
import UserDelete from '../../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../../Common/constant';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const pageSize = 10;

function PointGetModal({ visible, onClose, userId, labelDb, user }) {
  const [pageIndex, setPageIndex] = useState(1);

  const loading = useSelector((state) => state.pointGetLog.loading);
  const pointGetLog = useSelector((state) => state.pointGetLog.items);
  const totalRow = useSelector((state) => state.pointGetLog.totalRow);

  const [filter, setFilter] = useState({
    sort: { 'pointgetlog.createdDate': 'DESC' },
  });
  const dispatch = useDispatch();

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (!filter.sort[sortByKey] || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  useEffect(() => {
    dispatch(loadPointGetLog({ pageIndex, pageSize, ...filter, userId }));
  }, [dispatch, pageIndex, userId, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadPointGetLog({ ...filter, pageIndex, pageSize, userId }));
  };

  return (
    <MuiThemeProvider theme={themeNormalModal}>
      <div style={{ position: 'relative' }}>
        <LoadingBar show={loading} />
        <>
          <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={visible}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title"
            className={'d-modal-wrapper'}
          >
            <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
              <div
                style={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  marginRight: 50,
                }}
              >
                <p className={'d-title mb-0'}>ユーザー詳細-ポイント取得ログ</p>
                <div style={{ display: 'flex' }} className={'wrapper-info-title'}>
                  <p className={'mb-0'} style={{ fontSize: '1rem', marginRight: 20 }}>{user?.phoneNumber}</p>
                  <img className="object-fit-cover"
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: '50%',
                        marginRight: 20,
                      }}
                      src={
                        user?.avatar
                          ? user.avatar.url
                          : NoPhoto
                      }
                      alt=""
                  />
                  <p className={'mb-0'} style={{ fontSize: '1rem' }}>{user?.nickName}</p>
                </div>
              </div>
            </DialogTitle>
            <Button
              style={{ position: 'absolute', top: 10, right: 0 }}
              onClick={onClose}
            >
              <CloseIcon color="primary" />
            </Button>
            <div className={'d-title-info-box'}>
              <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_POINT_LOG_MODAL }}></div>
            </div>

            <DialogContent className={'table-modal-wrapper'}>
              <Card className="scroll-x-on-top">
                <Table padding="checkbox" className="d-table-modal mb-0">
                  <TableBody>
                    <TableRow>
                      <TableCell>No</TableCell>
                      <TableCell>
                        <SortArrows
                          sortObject={filter.sort}
                          text="内容"
                          name="pointgetlog.type"
                          onClick={onSort}
                        />
                      </TableCell>
                      <TableCell className="text-center">ギフト送信相手</TableCell>
                      <TableCell>
                        <SortArrows
                          sortObject={filter.sort}
                          text="取得ポイント"
                          name="pointgetlog.point"
                          onClick={onSort}
                        />
                      </TableCell>
                      <TableCell>
                        <SortArrows
                          sortObject={filter.sort}
                          text="日時"
                          name="pointgetlog.createdDate"
                          onClick={onSort}
                          defaultName="pointgetlog.createdDate"
                        />
                      </TableCell>
                    </TableRow>

                    {pointGetLog.map((x, index) => (
                      <TableRow key={x.id}>
                        <TableCell>
                          {(pageIndex - 1) * pageSize + index + 1}
                        </TableCell>
                        <TableCell>{Util.paymentTypeIdToText(x.type)}</TableCell>
                        <TableCell className="text-center">
                          <Link
                            to={Util.applyDynamicRouteParams(
                              ROUTES_CLIENT_DEFINED.USER.DETAIL,
                              [x.giftSender?.id],
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => saveLocalStore(x.giftSender.id)}
                          >
                            <img
                              className="imgRound"
                              src={
                                x?.giftSender?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                                x.giftSender !== null && x.giftSender.avatar !== null
                                  ? x.giftSender.avatar.url
                                  : NoPhoto
                              }
                              alt=""
                            />
                          </Link>
                        </TableCell>
                        <TableCell>{Util.separator(x.point)}</TableCell>
                        <TableCell>
                          {Util.timestampToDate(x.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>

              <center>
                <Pagination
                  className={'d-page-wrapper'}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  totalRow={totalRow}
                  onPageChange={handlePageChange}
                />
              </center>
            </DialogContent>
          </Dialog>
        </>
      </div>
    </MuiThemeProvider>
  );
}

export default PointGetModal;
