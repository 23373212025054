import { lazy } from 'react';
import {
  ROUTES_API_DEFINED,
  ROUTES_CLIENT_DEFINED,
} from 'Config/listConstants';
import Error404 from 'Page/404';
import InformationList from 'Page/InformationManagement/Home';
import LoginAdmin from 'Page/Login/LoginAdmin';
import LoginAgent from 'Page/Login/LoginAgent';
import LoginOpe from 'Page/Login/LoginOpe';
import LoginSys from 'Page/Login/LoginSys';
import UserSearch from 'Page/UserSearch/UserSearch';
import UserRegister from 'Page/UserManagement/UserRegister';
import UserArchiveSearch from 'Page/UserArchive/UserArchiveSearch';
import ImageProfileManagement from 'Page/ImageProfileManagement';
import LikeManagement from 'Page/LikeManagement';
import FavoriteManagement from 'Page/FavoriteManagement';
import FootprintManagement from 'Page/FootprintManagement';
import BlockManagement from 'Page/BlockManagement';
import ChatRoomList from 'Page/ChatRoom/ChatRoomList';
import ChatLogUser from 'Page/ChatLogUser/ChatLogUser';
import BlogsManagement from 'Page/BlogsManagement';
import TagList from 'Page/TagList';
import LogSendManagement from 'Page/LogSendManagement';
import SendingTimerManagement from 'Page/SendingTimerManagement';
import ScheduleTimerManagement from 'Page/ScheduleTimerManagement';
import PaymentManagement from 'Page/PaymentManagement';
import PaymentJobManagement from 'Page/PaymentJobManagement';
import PointGetLogManagement from 'Page/PointGetLogManagement';
import PointUsage from 'Page/PointUsage';
import TwilioManagement from 'Page/TwilioManagement';
import LogChangePhone from 'Page/ChangePhoneHistory/LogChangePhone';
import OtpLog from 'Page/OtpLog';
import Enrollment from 'Page/Enrollment/Enrollment';
import PopupManagement from 'Page/PopupManagement/PopupManagement';
import CampaignManagement from 'Page/CampaignManagement';
import ContactManagement from 'Page/ContactManagement/ContactManagement';
import Report from 'Page/Report';
import ChatSupportLog from 'Page/ChatSupportLog';
import ChatAdminLog from 'Page/ChatAdminLog';
import ChatAgentLog from 'Page/ChatAgentLog';
import ChatSupportIndividual from 'Page/ChatSupportIndividual';
import ChatAdminIndividual from 'Page/ChatAdminIndividual';
import Operator from 'Page/OperatorManagement/Operator';
import OperatorRegist from 'Page/OperatorManagement/Operator/OperatorRegist';
import SalaryLogManagement from 'Page/SalaryLogManagement';
import OperatorSalaryPoint from 'Page/OperatorSalaryPoint';
import AgentList from 'Page/AgentManagement/AgentList';
import AgentRegister from 'Page/AgentManagement/AgentRegister';
import JobList from 'Page/JobManagement/JobList';
import JobRegister from 'Page/JobManagement/JobRegister';
import JobEntryList from 'Page/JobEntryManagement/JobEntryList';
import FavoriteJobManagement from 'Page/FavoriteJobManagement';
import MemberAdminList from 'Page/MemberAdmin/MemberAdminList';
import MemberAdmin from 'Page/MemberAdmin';
import NameProfile from 'Page/NameProfile';
import NameProfileAgent from 'Page/NameProfileAgent';
import NameItemJob from 'Page/NameItemJob';
import NameState from 'Page/NameState';
import NameStateAgent from 'Page/NameStateAgent';
import NameStateJob from 'Page/NameStateJob';
import PointSetting from 'Page/PointSetting/PointSetting';
import PointRateManagement from 'Page/PointRateManagement';
import InformationManagement from 'Page/InformationManagement';
import MailSmsTextList from 'Page/MailSmsText/MailSmsTextList';
import ConversionTag from 'Page/ConversionTag';
import TagSetting from 'Page/TagSetting';
import HelpCmsList from 'Page/HelpCms/HelpCmsList';
import RankSetting from 'Page/RankSetting';
import TextWebPageManagement from 'Page/TextWebPageManagement';
import FileUpload from 'Page/FileUpload';
import EnvInfo from 'Page/EnvInfo';
import EnvUiWebSetting from 'Page/EnvUiWebSetting';
import EnvValueSetting from 'Page/EnvValueSetting';
import EnvTopNaviWebSetting from 'Page/EnvTopNaviWebSetting';
import EnvAspSetting from 'Page/EnvAspSetting';
import Database from 'Page/Database';
import Home from 'Page/InformationManagement/Home';
import ListRawUsers from 'Page/Database/ListRawUsers';
import ListContacts from 'Page/Database/ListContacts';
import ListMessage from 'Page/Database/ListMessage';
import ListCampaign from 'Page/Database/ListCampaign';
import ListChat from 'Page/Database/ListChat';
import ListPayment from 'Page/Database/ListPayment';
import ListRawInformation from 'Page/Database/ListRawInformation';
import ListRawTwilioHistory from 'Page/Database/ListRawTwilioHistory';
import ListRawTextWebPage from 'Page/Database/ListRawTextWebPage';
import ListRawOperator from 'Page/Database/ListRawOperator';
import ListRawAgent from 'Page/Database/ListRawAgent';
import ListRawMemberArchive from 'Page/Database/ListRawMemberArchive';
import ListRawJobDetail from 'Page/Database/ListRawJobDetail';
import ListRawMemberAdmin from 'Page/Database/ListRawMemberAdmin';
import ListRawOtp from 'Page/Database/ListRawOtp';
import ListRawPaymentLogJob from 'Page/Database/ListRawPaymentLogJob';
import ListRawPopupSetting from 'Page/Database/ListRawPopupSetting';
import ListRawLogMailVerification from 'Page/Database/ListRawLogMailVerification';
import UserEditMulti from 'Page/UserEditMulti';
import UserDetail from 'Page/UserManagement/UserDetail';
import UserDetailShort from 'Page/UserManagement/UserDetailShort';
import UserEdit from 'Page/UserManagement/UserEdit';
import UserEditShort from 'Page/UserManagement/UserEditShort';
import AgentDetail from 'Page/AgentManagement/AgentDetail';
import AgentEdit from 'Page/AgentManagement/AgentEdit';
import AgentCharaList from 'Page/AgentManagement/AgentCharaList';
import OperatorDetail from 'Page/OperatorManagement/Operator/OperatorDetail';
import OperatorEdit from 'Page/OperatorManagement/Operator/OperatorEdit';
import OpeSearchUser from 'Page/OpeSearchUser/OpeSearchUser';
import MemberAdminDetail from 'Page/MemberAdmin/MemberAdminDetail';
import MemberAdminEdit from 'Page/MemberAdmin/MemberAdminEdit';
import OpeWorkPageIndividual from 'Page/OpeWorkPageIndividual';
import AgentPageRoot from 'Page/AgentManagementAgentPage/AgentPageRoot';
import AgentPageDetail from 'Page/AgentManagementAgentPage/AgentPageDetail';
import AgentPageEdit from 'Page/AgentManagementAgentPage/AgentPageEdit';
import AgentJobList from 'Page/AgentManagement/JobList';
import JobRegisterRoot from 'Page/JobManagement/JobRegisterRoot';
import JobDetail from 'Page/JobManagement/JobDetail';
import JobEdit from 'Page/JobManagement/JobEdit';
import OpeCharaPageDetail from 'Page/OperatorManagement/OpeCharaPage/OpeCharaPageDetail';
import OpeCharaPageEdit from 'Page/OperatorManagement/OpeCharaPage/OpeCharaPageEdit';
import AgentWorkPageIndividual from 'Page/AgentWorkPageIndividual';
import EnvSysCmsSetting from 'Page/EnvSysCmsSetting';

export const PUBLIC_ROUTES_COMPONENTS_LIST = [
  {
    path: ROUTES_CLIENT_DEFINED.AUTH.LOGIN_SYS,
    //component: lazy(() => import('Page/Login/LoginSys')),
    component: LoginSys,
  },
  {
    path: ROUTES_CLIENT_DEFINED.AUTH.LOGIN_ADMIN,
    //component: lazy(() => import('Page/Login/LoginAdmin')),
    component: LoginAdmin,
  },
  {
    path: ROUTES_CLIENT_DEFINED.AUTH.LOGIN_OPERATOR,
    //component: lazy(() => import('Page/Login/LoginOpe')),
    component: LoginOpe,
  },
  {
    path: ROUTES_CLIENT_DEFINED.AUTH.LOGIN_AGENT,
    //component: lazy(() => import('Page/Login/LoginAgent')),
    component: LoginAgent,
  },
  {
    path: ROUTES_CLIENT_DEFINED.NOT_FOUND,
    //component: lazy(() => import('Page/404')),
    component: Error404,
  },
];

export const PRIVATE_ROUTES_COMPONENTS_LIST = [
  {
    path: ROUTES_API_DEFINED.HOME.INFORMATION_HOME,
    //component: lazy(() => import('Page/InformationManagement/Home')),
    component: InformationList,
    pageTitle: 'ホーム',
  },
  {
    path: ROUTES_API_DEFINED.MEMBER_USER.SEARCH,
    //component: lazy(() => import('Page/UserSearch/UserSearch')),
    component: UserSearch,
    pageTitle: 'ユーザー検索',
  },
  {
    path: ROUTES_API_DEFINED.MEMBER_USER.REGISTER,
    //component: lazy(() => import('Page/UserManagement/UserRegister')),
    component: UserRegister,
    pageTitle: '新規ユーザー登録',
  },
  {
    path: ROUTES_API_DEFINED.MEMBER_USER.ARCHIVE,
    //component: lazy(() => import('Page/UserArchive/UserArchiveSearch')),
    component: UserArchiveSearch,
    pageTitle: '削除ユーザー一時保存',
  },
  {
    path: ROUTES_API_DEFINED.MANAGEMENT.IMAGE_PROFILE,
    //component: lazy(() => import('Page/ImageProfileManagement')),
    component: ImageProfileManagement,
    pageTitle: 'プロフィール画像管理',
  },
  {
    path: ROUTES_API_DEFINED.MANAGEMENT.LIKE_LOG,
    //component: lazy(() => import('Page/LikeManagement')),
    component: LikeManagement,
    pageTitle: 'いいね一覧',
  },
  {
    path: ROUTES_API_DEFINED.MANAGEMENT.FAVORITE_USER,
    //component: lazy(() => import('Page/FavoriteManagement')),
    component: FavoriteManagement,
    pageTitle: 'お気に入り一覧',
  },
  {
    path: ROUTES_API_DEFINED.MANAGEMENT.FOOTPRINT_USER,
    //component: lazy(() => import('Page/FootprintManagement')),
    component: FootprintManagement,
    pageTitle: '足跡一覧',
  },
  {
    path: ROUTES_API_DEFINED.MANAGEMENT.BLOCK_LIST,
    //component: lazy(() => import('Page/BlockManagement')),
    component: BlockManagement,
    pageTitle: 'ブロック一覧',
  },
  {
    path: ROUTES_API_DEFINED.MANAGEMENT.CHAT_ROOM,
    //component: lazy(() => import('Page/ChatRoom/ChatRoomList')),
    component: ChatRoomList,
    pageTitle: 'チャットルーム管理',
  },
  {
    path: ROUTES_API_DEFINED.MANAGEMENT.CHAT_MESSAGE_USER,
    //component: lazy(() => import('Page/ChatLogUser/ChatLogUser')),
    component: ChatLogUser,
    pageTitle: 'チャットログ管理',
  },
  {
    path: ROUTES_API_DEFINED.MANAGEMENT.BLOG_LIST,
    //component: lazy(() => import('Page/BlogsManagement')),
    component: BlogsManagement,
    pageTitle: 'ブログ管理',
  },
  {
    path: ROUTES_API_DEFINED.MANAGEMENT.TAG_MEMBER_USER,
    //component: lazy(() => import('Page/TagList')),
    component: TagList,
    pageTitle: 'タグ登録リスト',
  },
  {
    path: ROUTES_API_DEFINED.MANAGEMENT.LOG_SEND,
    //component: lazy(() => import('Page/LogSendManagement')),
    component: LogSendManagement,
    pageTitle: '一斉送信ログ',
  },
  {
    path: ROUTES_API_DEFINED.MANAGEMENT.TIMER_STANDBY,
    //component: lazy(() => import('Page/SendingTimerManagement')),
    component: SendingTimerManagement,
    pageTitle: '一斉送信ログ',
  },
  {
    path: ROUTES_API_DEFINED.MANAGEMENT.SCHEDULE_STANDBY,
    //component: lazy(() => import('Page/ScheduleTimerManagement')),
    component: ScheduleTimerManagement,
    pageTitle: 'スケジュール送信一覧',
  },
  {
    path: ROUTES_API_DEFINED.LOG.PAYMENT_MATCH,
    //component: lazy(() => import('Page/PaymentManagement')),
    component: PaymentManagement,
    pageTitle: '入金ログ',
  },
  {
    path: ROUTES_API_DEFINED.LOG.PAYMENT_JOB,
    //component: lazy(() => import('Page/PaymentJobManagement')),
    component: PaymentJobManagement,
    pageTitle: '入金ログ(仕事）',
  },
  {
    path: ROUTES_API_DEFINED.LOG.GET_POINT,
    //component: lazy(() => import('Page/PointGetLogManagement')),
    component: PointGetLogManagement,
    pageTitle: 'ポイント取得ログ',
  },
  {
    path: ROUTES_API_DEFINED.LOG.POINT_USAGE,
    //component: lazy(() => import('Page/PointUsage')),
    component: PointUsage,
    pageTitle: 'ポイント消費ログ',
  },
  {
    path: ROUTES_API_DEFINED.LOG.TWILIO_HISTORY,
    //component: lazy(() => import('Page/TwilioManagement')),
    components: TwilioManagement,
    pageTitle: 'Twilio通話ログ',
  },
  {
    path: ROUTES_API_DEFINED.LOG.CHANGE_PHONE,
    //component: lazy(() => import('Page/ChangePhoneHistory/LogChangePhone')),
    component: LogChangePhone,
    pageTitle: '電話番号変更ログ',
  },
  {
    path: ROUTES_API_DEFINED.LOG.OTP_LOG,
    //component: lazy(() => import('Page/OtpLog')),
    component: OtpLog,
    pageTitle:
      'OTP SMSログ-Result/0:Delivered/1:Failed/11:Unknown/12:Rejected-OtpSend/0:送信/1:擬送信',
  },
  {
    path: ROUTES_API_DEFINED.LOG.ENROLLMENT,
    //component: lazy(() => import('Page/Enrollment/Enrollment')),
    component: Enrollment,
    pageTitle: '登録者数',
  },
  {
    path: ROUTES_API_DEFINED.CONTENTS.POPUP_SETTING,
    //component: lazy(() => import('Page/PopupManagement/PopupManagement')),
    component: PopupManagement,
    pageTitle: 'ポップアップ設定',
  },
  {
    path: ROUTES_API_DEFINED.CONTENTS.CAMPAIGN,
    //component: lazy(() => import('Page/CampaignManagement')),
    component: CampaignManagement,
    pageTitle: 'キャンペーン設定－リスト',
  },
  {
    path: ROUTES_API_DEFINED.SUPPORT.CONTACT_USER,
    //component: lazy(() => import('Page/ContactManagement/ContactManagement')),
    component: ContactManagement,
    pageTitle: '問合せ一覧',
  },
  {
    path: ROUTES_API_DEFINED.SUPPORT.REPORT,
    //component: lazy(() => import('Page/Report')),
    component: Report,
    pageTitle: '報告一覧',
  },
  {
    path: ROUTES_API_DEFINED.SUPPORT.CHAT_ADMIN_USER_LOG,
    //component: lazy(() => import('Page/ChatSupportLog')),
    component: ChatSupportLog,
    pageTitle: 'サポートチャットログ',
  },
  {
    path: ROUTES_API_DEFINED.SUPPORT.CHAT_ADMIN_AGENT_LOG,
    //component: lazy(() => import('Page/ChatAdminLog')),
    component: ChatAdminLog,
    pageTitle: 'サポート×会社チャットログ',
  },
  {
    path: ROUTES_API_DEFINED.SUPPORT.CHAT_AGENT_USER_LOG,
    //component: lazy(() => import('Page/ChatAgentLog')),
    component: ChatAgentLog,
    pageTitle: '会社チャットログ',
  },
  {
    path: ROUTES_API_DEFINED.SUPPORT.CHAT_ADMIN_USER_INDIVIDUAL,
    //component: lazy(() => import('Page/ChatSupportIndividual')),
    component: ChatSupportIndividual,
    pageTitle: 'サポートチャット画面',
  },
  {
    path: ROUTES_API_DEFINED.SUPPORT.CHAT_ADMIN_AGENT_INDIVIDUAL,
    //component: lazy(() => import('Page/ChatAdminIndividual')),
    component: ChatAdminIndividual,
    pageTitle: 'サポート×会社チャット画面',
  },
  {
    path: ROUTES_API_DEFINED.OPERATOR.SEARCH,
    //component: lazy(() => import('Page/OperatorManagement/Operator')),
    component: Operator,
    pageTitle: 'オペレーター管理-キャラクターリスト',
  },
  {
    path: ROUTES_API_DEFINED.OPERATOR.REGISTER,
    //component: lazy(
    //  () => import('Page/OperatorManagement/Operator/OperatorRegist'),
    //),
    component: OperatorRegist,
    pageTitle: 'オペレーター管理-オペレーター新規登録',
  },
  {
    path: ROUTES_API_DEFINED.OPERATOR.SALARY_LOG,
    //component: lazy(() => import('Page/SalaryLogManagement')),
    component: SalaryLogManagement,
    pageTitle: '給与全ポイントログ',
  },
  {
    path: ROUTES_API_DEFINED.OPERATOR.SALARY_SUMMARY,
    //component: lazy(() => import('Page/OperatorSalaryPoint')),
    component: OperatorSalaryPoint,
    pageTitle: 'オペレーター管理-月別給与集計',
  },
  {
    path: ROUTES_API_DEFINED.AGENT.LIST,
    //component: lazy(() => import('Page/AgentManagement/AgentList')),
    component: AgentList,
    pageTitle: '会社一覧',
  },
  {
    path: ROUTES_API_DEFINED.AGENT.REGISTER,
    //component: lazy(() => import('Page/AgentManagement/AgentRegister')),
    component: AgentRegister,
    pageTitle: '会社登録',
  },
  {
    path: ROUTES_API_DEFINED.JOB.LIST,
    //component: lazy(() => import('Page/JobManagement/JobList')),
    component: JobList,
    pageTitle: '仕事一覧',
  },
  {
    path: ROUTES_API_DEFINED.JOB.REGISTER,
    //component: lazy(() => import('Page/JobManagement/JobRegister')),
    component: JobRegister,
    pageTitle: '仕事登録',
  },
  {
    path: ROUTES_API_DEFINED.JOB.ENTRY_USER_LIST,
    //component: lazy(() => import('Page/JobEntryManagement/JobEntryList')),
    component: JobEntryList,
    pageTitle: '仕事応募者一覧',
  },
  {
    path: ROUTES_API_DEFINED.JOB.FAVORITE_JOB_USER_LIST,
    //component: lazy(() => import('Page/FavoriteJobManagement')),
    component: FavoriteJobManagement,
    pageTitle: 'お気に入り一覧（仕事）',
  },
  {
    path: ROUTES_API_DEFINED.ADMINISTRATOR.LIST,
    //component: lazy(() => import('Page/MemberAdmin/MemberAdminList')),
    component: MemberAdminList,
    pageTitle: '管理者一覧',
  },
  {
    path: ROUTES_API_DEFINED.ADMINISTRATOR.REGISTER,
    //component: lazy(() => import('Page/MemberAdmin')),
    component: MemberAdmin,
    pageTitle: '管理者登録新規登録',
  },

  {
    path: ROUTES_API_DEFINED.SETTING.PROFILE_USER,
    //component: lazy(() => import('Page/NameProfile')),
    component: NameProfile,
    pageTitle: 'プロフィール設定-ルート',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.PROFILE_AGENT,
    //component: lazy(() => import('Page/NameProfileAgent')),
    component: NameProfileAgent,
    pageTitle: 'プロフィール設定(会社)',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.ITEM_JOB,
    //component: lazy(() => import('Page/NameItemJob')),
    component: NameItemJob,
    pageTitle: '仕事項目設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.STATE_USER,
    //component: lazy(() => import('Page/NameState')),
    component: NameState,
    pageTitle: '都道府県設定(ユーザー)',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.STATE_AGENT,
    //component: lazy(() => import('Page/NameStateAgent')),
    component: NameStateAgent,
    pageTitle: '都道府県設定(会社)',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.STATE_JOB,
    //component: lazy(() => import('Page/NameStateJob')),
    component: NameStateJob,
    pageTitle: '都道府県設定(仕事)',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.POINT_RATE,
    //component: lazy(() => import('Page/PointSetting/PointSetting')),
    component: PointSetting,
    pageTitle: 'ポイント設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.PAYMENT_RATE,
    //component: lazy(() => import('Page/PointRateManagement')),
    component: PointRateManagement,
    pageTitle: '課金レート設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.INFORMATION_SETTING,
    //component: lazy(() => import('Page/InformationManagement')),
    component: InformationManagement,
    pageTitle: 'お知らせ設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.ENV_MAILSMS_TEXT,
    //component: lazy(() => import('Page/MailSmsText/MailSmsTextList')),
    component: MailSmsTextList,
    pageTitle: 'メールSMSテキスト挿入設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.CONVERSION_TAG,
    //component: lazy(() => import('Page/ConversionTag')),
    component: ConversionTag,
    pageTitle: '変換タグ設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.TAG_NAME,
    //component: lazy(() => import('Page/TagSetting')),
    component: TagSetting,
    pageTitle: 'タグ設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.HELP_CMS,
    //component: lazy(() => import('Page/HelpCms/HelpCmsList')),
    component: HelpCmsList,
    pageTitle: 'ヘルプ表示設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.RANK,
    //component: lazy(() => import('Page/RankSetting')),
    component: RankSetting,
    pageTitle: 'ユーザーランク設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.WEB_TEXT_PAGE,
    //component: lazy(() => import('Page/TextWebPageManagement')),
    component: TextWebPageManagement,
    pageTitle: 'Web上部テキスト',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.FILE_UPLOAD,
    //component: lazy(() => import('Page/FileUpload')),
    component: FileUpload,
    pageTitle: 'ファイルアップロード',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.ENV_INFO,
    //component: lazy(() => import('Page/EnvInfo')),
    component: EnvInfo,
    pageTitle: '番組情報設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.ENV_UI_WEB,
    //component: lazy(() => import('Page/EnvUiWebSetting')),
    component: EnvUiWebSetting,
    pageTitle: 'ユーザー画面表示設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.ENV_VALUE,
    //component: lazy(() => import('Page/EnvValueSetting')),
    component: EnvValueSetting,
    pageTitle: '一斉上限・問合メール設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.TOP_NAV,
    //component: lazy(() => import('Page/EnvTopNaviWebSetting')),
    component: EnvTopNaviWebSetting,
    pageTitle: 'トップメニュー設定',
  },
  {
    path: ROUTES_API_DEFINED.SETTING.ENV_ASP,
    //component: lazy(() => import('Page/EnvAspSetting')),
    component: EnvAspSetting,
    pageTitle: 'ASP環境ファイル設定',
  },
  {
    path: ROUTES_API_DEFINED.DATABASE.MYSQL,
    //component: lazy(() => import('Page/Database')),
    component: Database,
    pageTitle: 'データーベース参照-一覧',
  },
  // Client defined
  {
    path: ROUTES_CLIENT_DEFINED.HOME.HOME,
    //component: lazy(() => import('Page/InformationManagement/Home')),
    component: Home,
    pageTitle: '',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.MEMBERS,
    //component: lazy(() => import('Page/Database/ListRawUsers')),
    component: ListRawUsers,
    pageTitle: 'ユーザー',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.CONTACTS,
    //component: lazy(() => import('Page/Database/ListContacts')),
    component: ListContacts,
    pageTitle: '問合せ',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.MESSAGE,
    //component: lazy(() => import('Page/Database/ListMessage')),
    component: ListMessage,
    pageTitle: 'チャットログ',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.CAMPAIGN,
    //component: lazy(() => import('Page/Database/ListCampaign')),
    component: ListCampaign,
    pageTitle: 'キャンペーン設定－リスト',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.CHAT,
    //component: lazy(() => import('Page/Database/ListChat')),
    component: ListChat,
    pageTitle: 'チャットルーム管理',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.PAYMENT,
    //component: lazy(() => import('Page/Database/ListPayment')),
    component: ListPayment,
    pageTitle: '入金ログ',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.INFORMATION,
    //component: lazy(() => import('Page/Database/ListRawInformation')),
    component: ListRawInformation,
    pageTitle: 'お知らせ設定',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.TWILIO_HISTORY,
    //component: lazy(() => import('Page/Database/ListRawTwilioHistory')),
    component: ListRawTwilioHistory,
    pageTitle: 'Twilio通話ログ',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.TEXT_WEB_PAGE,
    //component: lazy(() => import('Page/Database/ListRawTextWebPage')),
    component: ListRawTextWebPage,
    pageTitle: 'テキストページ',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.OPERATOR,
    //component: lazy(() => import('Page/Database/ListRawOperator')),
    component: ListRawOperator,
    pageTitle: '担当者',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.AGENT,
    //component: lazy(() => import('Page/Database/ListRawAgent')),
    component: ListRawAgent,
    pageTitle: '会社',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.MEMBER_ARCHIVE,
    //component: lazy(() => import('Page/Database/ListRawMemberArchive')),
    component: ListRawMemberArchive,
    pageTitle: 'ユーザー削除一時保存',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.JOB_DETAIL,
    //component: lazy(() => import('Page/Database/ListRawJobDetail')),
    component: ListRawJobDetail,
    pageTitle: 'Job detail',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.MEMBER_ADMIN,
    //component: lazy(() => import('Page/Database/ListRawMemberAdmin')),
    component: ListRawMemberAdmin,
    pageTitle: '管理者',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.OTP,
    //component: lazy(() => import('Page/Database/ListRawOtp')),
    component: ListRawOtp,
    pageTitle:
      'OTP SMSログ-Result/0:Delivered/1:Failed/11:Unknown/12:Rejected-OtpSend/0:送信/1:擬送信',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.PAYMENT_LOG_JOB,
    //component: lazy(() => import('Page/Database/ListRawPaymentLogJob')),
    component: ListRawPaymentLogJob,
    pageTitle: '入金ログ(仕事）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.POPUP_SETTING,
    //component: lazy(() => import('Page/Database/ListRawPopupSetting')),
    component: ListRawPopupSetting,
    pageTitle: 'ポップアップ設定',
  },
  {
    path: ROUTES_CLIENT_DEFINED.DATABASE.LOG_MAIL_VERIFICATION,
    //component: lazy(() => import('Page/Database/ListRawLogMailVerification')),
    component: ListRawLogMailVerification,
    pageTitle: 'メール認証ログ',
  },
  {
    path: ROUTES_CLIENT_DEFINED.USER.EDIT_MULTI,
    //component: lazy(() => import('Page/UserEditMulti')),
    component: UserEditMulti,
    pageTitle: '一括変換',
  },
  {
    path: ROUTES_CLIENT_DEFINED.USER.DETAIL,
    //component: lazy(() => import('Page/UserManagement/UserDetail')),
    component: UserDetail,
    pageTitle: 'ユーザー詳細',
  },
  {
    path: ROUTES_CLIENT_DEFINED.USER.DETAIL_SHORT,
    //component: lazy(() => import('Page/UserManagement/UserDetailShort')),
    component: UserDetailShort,
    pageTitle: 'ユーザー詳細（Short）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.USER.EDIT,
    //component: lazy(() => import('Page/UserManagement/UserEdit')),
    component: UserEdit,
    pageTitle: 'ユーザー詳細-編集',
  },
  {
    path: ROUTES_CLIENT_DEFINED.USER.EDIT_SHORT,
    //component: lazy(() => import('Page/UserManagement/UserEditShort')),
    component: UserEditShort,
    pageTitle: 'ユーザー詳細編集（Short）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.CHARACTER.DETAIL_SHORT,
    //component: lazy(() => import('Page/UserManagement/UserDetailShort')),
    component: UserDetailShort,
    pageTitle: 'ユーザー詳細（Short）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.CHARACTER.EDIT_SHORT,
    //component: lazy(() => import('Page/UserManagement/UserEditShort')),
    component: UserEditShort,
    pageTitle: 'ユーザー詳細編集（Short）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.AGENT.DETAIL,
    //component: lazy(() => import('Page/AgentManagement/AgentDetail')),
    component: AgentDetail,
    pageTitle: '会社詳細ルート（SA）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.AGENT.EDIT,
    //component: lazy(() => import('Page/AgentManagement/AgentEdit')),
    component: AgentEdit,
    pageTitle: '会社詳細-編集（SA）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.AGENT.LIST_CHARA,
    //component: lazy(() => import('Page/AgentManagement/AgentCharaList')),
    component: AgentCharaList,
    pageTitle: '会社-仕事一覧',
  },
  {
    path: ROUTES_CLIENT_DEFINED.OPERATOR.DETAIL,
    //component: lazy(
    //  () => import('Page/OperatorManagement/Operator/OperatorDetail'),
    //),
    component: OperatorDetail,
    pageTitle: '管理-オペレーター詳細',
  },
  {
    path: ROUTES_CLIENT_DEFINED.OPERATOR.EDIT,
    //component: lazy(
    //  () => import('Page/OperatorManagement/Operator/OperatorEdit'),
    //),
    component: OperatorEdit,
    pageTitle: '管理-オペレーター編集',
  },
  {
    path: ROUTES_CLIENT_DEFINED.OPERATOR.SALARYPOINT,
    //component: lazy(() => import('Page/OperatorSalaryPoint')),
    component: OperatorSalaryPoint,
    pageTitle: 'オペレーター管理-オペレーター給与ポイント',
  },
  {
    path: ROUTES_CLIENT_DEFINED.OPERATOR.SEARCH_USER,
    //component: lazy(() => import('Page/OpeSearchUser/OpeSearchUser')),
    component: OpeSearchUser,
    pageTitle: '一斉送信（オペレーター）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.ADMIN_OPE.SEARCH_USER,
    //component: lazy(() => import('Page/OpeSearchUser/OpeSearchUser')),
    component: OpeSearchUser,
    pageTitle: '一斉送信（オペレーター）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.ADMINISTATOR.DETAIL,
    //component: lazy(() => import('Page/MemberAdmin/MemberAdminDetail')),
    component: MemberAdminDetail,
    pageTitle: '管理者詳細',
  },
  {
    path: ROUTES_CLIENT_DEFINED.ADMINISTATOR.EDIT,
    //component: lazy(() => import('Page/MemberAdmin/MemberAdminEdit')),
    component: MemberAdminEdit,
    pageTitle: '管理者詳細-編集',
  },
  {
    path: ROUTES_CLIENT_DEFINED.ADMIN_OPE.WORK_PAGE_INDIVIDUAL,
    //component: lazy(() => import('Page/OpeWorkPageIndividual')),
    component: OpeWorkPageIndividual,
    pageTitle: '個別送信画面(SA）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.AGENT_OPE.DETAIL_AGENT,
    //component: lazy(
    //  () => import('Page/AgentManagementAgentPage/AgentPageRoot'),
    //),
    component: AgentPageRoot,
    pageTitle: '会社詳細ルート（SA）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.AGENT_OPE.DETAIL_OPE,
    //component: lazy(
    //  () => import('Page/AgentManagementAgentPage/AgentPageDetail'),
    //),
    component: AgentPageDetail,
    pageTitle: '会社詳細（SA）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.AGENT_OPE.EDIT_AGENT,
    //component: lazy(
    //  () => import('Page/AgentManagementAgentPage/AgentPageEdit'),
    //),
    component: AgentPageEdit,
    pageTitle: '会社詳細-編集（SA）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.AGENT_OPE.JOB_LIST,
    //component: lazy(() => import('Page/AgentManagement/JobList')),
    component: AgentJobList,
    pageTitle: '会社-仕事一覧',
  },
  {
    path: ROUTES_CLIENT_DEFINED.AGENT_OPE.POINT_USAGE_LOG,
    //component: lazy(() => import('Page/PointUsage')),
    component: PointUsage,
    pageTitle: 'ポイント消費ログ（SA）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.OPE_JOB.JOB_REGISTER,
    //component: lazy(() => import('Page/JobManagement/JobRegisterRoot')),
    component: JobRegisterRoot,
    pageTitle: '仕事登録(A）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.OPE_JOB.JOB_DETAIL,
    //component: lazy(() => import('Page/JobManagement/JobDetail')),
    component: JobDetail,
    pageTitle: '仕事一覧-詳細',
  },
  {
    path: ROUTES_CLIENT_DEFINED.OPE_JOB.JOB_EDIT,
    //component: lazy(() => import('Page/JobManagement/JobEdit')),
    component: JobEdit,
    pageTitle: '仕事-詳細-編集',
  },

  {
    path: ROUTES_CLIENT_DEFINED.OPE_CHARA.DETAIL,
    //component: lazy(
    //  () => import('Page/OperatorManagement/OpeCharaPage/OpeCharaPageDetail'),
    //),
    component: OpeCharaPageDetail,
    pageTitle: 'オペレーター詳細（O）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.OPE_CHARA.EDIT,
    //component: lazy(
    //  () => import('Page/OperatorManagement/OpeCharaPage/OpeCharaPageEdit'),
    //),
    component: OpeCharaPageEdit,
    pageTitle: 'オペレーター編集（O）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.OPE_CHARA.SALARYPOINT,
    //component: lazy(() => import('Page/OperatorSalaryPoint')),
    component: OperatorSalaryPoint,
    pageTitle: '給与ポイント（O）',
  },
  {
    path: ROUTES_CLIENT_DEFINED.ADMIN_AGENT.WORK_PAGE_INDIVIDUAL,
    //component: lazy(() => import('Page/AgentWorkPageIndividual')),
    component: AgentWorkPageIndividual,
    pageTitle: 'chat-agent-individual',
  },
  {
    path: ROUTES_CLIENT_DEFINED.SETTING.ENV_SYS_SETTING,
    //component: lazy(() => import('Page/EnvSysCmsSetting')),
    component: EnvSysCmsSetting,
    pageTitle: '管理画面表示設定',
  },
];
