const EnvSysCms = {
  GET_ENV_SYS_CMS: 'GET_ENV_SYS_CMS',
  GET_ENV_SYS_CMS_SUCCESS: 'GET_ENV_SYS_CMS_SUCCESS',
  GET_ENV_SYS_CMS_FAILURE: 'GET_ENV_SYS_CMS_FAILURE',

  GET_ENV_SYS_CMS_ALL: 'GET_ENV_SYS_CMS_ALL',
  GET_ENV_SYS_CMS_ALL_SUCCESS: 'GET_ENV_SYS_CMS_ALL_SUCCESS',
  GET_ENV_SYS_CMS_ALL_FAILURE: 'GET_ENV_SYS_CMS_ALL_FAILURE',

  UPDATE_ENV_SYS_CMS: 'UPDATE_ENV_SYS_CMS',
  UPDATE_ENV_SYS_CMS_SUCCESS: 'UPDATE_ENV_SYS_CMS_SUCCESS',
  UPDATE_ENV_SYS_CMS_FAILURE: 'UPDATE_ENV_SYS_CMS_FAILURE',
};

export default EnvSysCms;

