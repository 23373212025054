import { sendDelete, sendGet, sendPost, sendPut } from './axios';

export const getUsers = (filter = {}) => {
  return sendPost('/cms/users', filter, {
    pageIndex: filter.pageIndex,
    pageSize: filter.pageSize,
    sortBy: filter.sortBy,
    orderBy: filter.orderBy,
    archive: filter?.archive,
  });
};

export const getUserExport = (filter = {}) => {
  return sendPost('/cms/user-export/filter', filter, {
    pageIndex: filter.pageIndex,
    pageSize: filter.pageSize,
    sortBy: filter.sortBy,
    orderBy: filter.orderBy,
    archive: filter?.archive,
  });
};

export const getAdmin = () => sendGet(`/cms/admin`);

export const getUserIds = (filter = {}) => {
  return sendPost('/cms/list-user-id', filter);
};
export const getUser = (id) => sendGet(`/cms/user/${id}`);
export const getAllTimerMail = (data) => sendGet(`/cms/get-time-mail`, data);
export const getAllTimerNoti = (data) => sendGet(`/cms/get-time-noti`, data);
export const getAllTimerSms = (data) => sendGet(`/cms/get-time-sms`, data);
export const updateUser = (user) => sendPut(`/cms/user/${user.id}`, user);
export const checkExistingUserBeforeCreate = (filter = {}) => {
  return sendPost(`/cms/user/check-user-existed`, filter);
};
export const createUser = (user) => sendPost('/cms/user/create', user);
export const getSerialChara = (data) =>
  sendPost('/cms/user/serial-chara', data);
export const removeUser = (id) => sendDelete(`/cms/user/${id}`);
export const removeMultipleUsers = (data) =>
  sendPost(`/cms/delete-bulk-users`, data);
export const moveToArchiveUser = (id) =>
  sendDelete(`/cms/user/move-to-archive/${id}`);
export const moveToArchiveMultipleUsers = (data) =>
  sendPost(`/cms/move-bulk-to-archive`, data);

export const sendSmsUser = (user) => {
  return sendPost(`/cms/send-sms-user/${user.id}`, { data: user.data });
};

export const setTimeSms = (data) => sendPost(`/cms/set-time-sms`, data);

export const sendEmailUser = (data) => {
  return sendPost(`/cms/send-email-user/${data.id}`, {
    title: data.title,
    content: data.content,
    from_mail_address: data.from_mail_address,
    force_send: data?.force_send || false,
  });
};

export const setTimeMail = (data) => sendPost(`/cms/set-time-mail`, data);

export const setTimeNoti = (data) => sendPost(`/cms/set-time-noti`, data);

export const sendNotiUser = (data) => sendPost(`/noti/send`, data);

export const sendSmsListUser = (data) => {
  return sendPost(`/cms/send-sms-by-list-user`, data);
};

export const sendEmailListUser = (data) => {
  return sendPost(`/cms/send-email-by-list-user`, data);
};

export const sendNotiListUser = (data) => {
  return sendPost(`/cms/send-noti-by-list-user`, data);
};

export const sendChatListUser = (data) => {
  return sendPost(`/cms/send-chat-to-list-user`, data);
};

export const updateUsers = (data) => {
  return sendPut(`/cms/users/update`, data);
};

export const addPointUser = (params) => {
  return sendPost('/cms/users/add-point', params);
};

export const createLike = (payload) => {
  return sendPost(`/like`, payload);
};

export const getLogEnRollmentMembers = (params) => {
  return sendPost('/log-enrollment', params);
};

export const savePresets = (data) => sendPost('/cms/memory-search', data);
export const getPresets = () => sendGet('/cms/memory-search');
export const updatePreset = (id, data) =>
  sendPut(`/cms/memory-search/${id}`, data);
export const deletePreset = (id) => sendDelete(`/cms/memory-search/${id}`);
