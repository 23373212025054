import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MuiThemeProvider,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import NoPhoto from '../../assets/image/no_user_photo.png';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from 'moment';
import _ from 'lodash';
import { Col, Row } from 'reactstrap';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { loadListConversation } from '../../Redux/Actions/listConversation';
import AppChatWork from './WorkPageManagement/AppChatWork';
import Util from '../../Util/Util';
import socket from '../../socket';
import ImageModal from '../../Components/ImageModal';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS, REPLIED_STATUS } from '../../Common/constant';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const recordStyle = {
  display: 'grid',
  gridTemplateColumns: '31% 31% 8% 20% 10%',
};

export default function OpeWorkPageIndividual() {
  const params = useParams();
  const inputEl = useRef(null);
  const operatorId = params.operatorId;
  const [filter, setFilter] = useState({ pageIndex: 1, pageSize: 1000 });
  const [listConversations, setListConversations] = useState();
  const [listMessages, setListMessages] = useState();
  const [conversationId, setConversationId] = useState();
  const [values, setValues] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const [loadMore, setLoadMore] = useState(true);
  const [imageUrl, setImageUrl] = useState();
  const [isOpeChara, setIsOpeChara] = useState(false);

  const dispatch = useDispatch();
  const conversations = useSelector((state) => state.listConversation.items);
  useEffect(() => {
    dispatch(loadListConversation({ ...filter, operatorId }));
    setLoadMore(false);
    setIsOpeChara(
      (localStorage.getItem('homePage') || '').includes('opechara'),
    );
  }, [dispatch, filter, loadMore, operatorId]);

  useEffect(() => {
    socket.emit('online', {
      userId: operatorId,
    });
    socket.on('updateConversationsList', (data) => {
      handleRefetchData();
    });

    return () => socket.off('updateConversationsList');
  });

  useEffect(() => {
    if (conversations) {
      const workPage = conversations?.map((message, index) => {
        if (message?.sender?.charaStatus) {
          const source = message.sender;
          const target = message.receiver;
          message.sender = target;
          message.receiver = source;
        }

        const lastMessage = message?.messages[0];
        if (
          lastMessage &&
          lastMessage.senderId !== message?.receiver?.id &&
          lastMessage.replied === REPLIED_STATUS.NOT_REPLIED
        )
          message.lastMessage = 1;
        else message.lastMessage = 0;

        return message;
      });
      workPage.sort(
        (a, b) =>
          b.lastMessage - a.lastMessage ||
          b.messages[0]?.createdDate - a.messages[0]?.createdDate,
      );
      setListConversations(workPage);
    }
  }, [conversations]);

  const handleScroll = (e) => {
    const bottom =
      Number((e.target.scrollHeight - e.target.scrollTop).toFixed(0)) -
        e.target.clientHeight <
      50;
    if (bottom && !loadMore) {
      setFilter({ ...filter, pageSize: filter.pageSize + 1 });
      setLoadMore(bottom);
    }
  };

  const handleListItemClick = (
    sender,
    receiver,
    messages,
    index,
    conversationId,
  ) => {
    setSelectedIndex(index);
    setValues((values) => ({
      ...values,
      index,
      sender,
      receiver,
    }));
    let messagesBaseDate = _.groupBy(messages, function (message) {
      return moment(Number(message.createdDate)).format('YYYY-MM-DD');
    });
    setListMessages(messagesBaseDate);
    setConversationId(conversationId);

    socket.emit('joinConversation', {
      conversationId,
      dataMemberId: receiver.id,
    });
  };

  const scrollToRef = (ref) => {
    if (ref.current) ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (listMessages) scrollToRef(inputEl);
  }, [listMessages]);

  const showImage = (imageUrl) => {
    setImageUrl(imageUrl);
  };

  const handleRefetchData = (isResetData = false) => {
    dispatch(loadListConversation({ ...filter, operatorId }));
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ textAlign: 'left' }}>
          <Link
            to={Util.applyDynamicRouteParams(
              isOpeChara
                ? ROUTES_CLIENT_DEFINED.OPE_CHARA.DETAIL
                : ROUTES_CLIENT_DEFINED.OPERATOR.DETAIL,
              [operatorId],
            )}
            target="_self"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button
              className="btn-blue"
              style={{
                margin: 'auto',
                width: 'auto',
                color: 'white',
              }}
              variant="contained"
            >
              前の画面に戻る
            </Button>
          </Link>
        </div>
      </div>
      <Row>
        <Col xs={6} className="mt-3 d-wrapper-worker">
          <Row>
            <Col xs={12}>
              <div className="container-conversation">
                <div className="list-conversation" onScroll={handleScroll}>
                  <List component="nav" className="item-head">
                    <ListItem style={recordStyle}>
                      <div style={{ paddingLeft: 50 }}>ユーザー</div>
                      <div style={{ paddingLeft: 72 }}>ユーザー</div>
                      <div style={{ textAlign: 'start' }}></div>
                      <div style={{ marginLeft: 10, textAlign: 'start' }}>
                        最終チャット日時
                      </div>
                    </ListItem>
                  </List>
                  {listConversations &&
                    listConversations?.map((item, index) => (
                      <List
                        component="nav"
                        className="item-content"
                        key={index}
                      >
                        <ListItem style={recordStyle}>
                          <div className="user" style={{ width: '100%' }}>
                            <Link
                              to={Util.applyDynamicRouteParams(
                                isOpeChara
                                  ? ROUTES_CLIENT_DEFINED.CHARACTER.DETAIL_SHORT
                                  : ROUTES_CLIENT_DEFINED.USER.DETAIL_SHORT,
                                [item?.sender?.id],
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => {
                                item?.sender?.id
                                  ? localStorage.setItem(
                                      'userId',
                                      item.sender.id,
                                    )
                                  : e.preventDefault();
                              }}
                            >
                              <img
                                alt="Avatar"
                                src={
                                  item?.sender?.memberStatus ===
                                  MEMBER_USER_STATUS.IN_ACTIVE
                                    ? UserDelete
                                    : item?.sender?.avatar?.url || NoPhoto
                                }
                                className="avatar"
                              />
                            </Link>
                            <ListItemText primary={item?.sender?.nickName} />
                          </div>

                          <div
                            className="user d-flex d-justify-start"
                            style={{ width: '100%' }}
                          >
                            <SyncAltIcon className="icon-transfer" />
                            <Link
                              to={Util.applyDynamicRouteParams(
                                isOpeChara
                                  ? ROUTES_CLIENT_DEFINED.CHARACTER.DETAIL_SHORT
                                  : ROUTES_CLIENT_DEFINED.USER.DETAIL_SHORT,
                                [item?.receiver?.id],
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() =>
                                localStorage.setItem(
                                  'userId',
                                  item?.receiver?.id,
                                )
                              }
                            >
                              <img
                                alt="Avatar"
                                src={
                                  item?.receiver?.memberStatus ===
                                  MEMBER_USER_STATUS.IN_ACTIVE
                                    ? UserDelete
                                    : item?.receiver?.avatar?.url || NoPhoto
                                }
                                className="avatar"
                              />
                            </Link>
                            <div>
                              <span className="user-other">
                                {item?.receiver?.nickName}
                              </span>
                            </div>
                          </div>

                          <div></div>

                          <div style={{ marginLeft: 10, textAlign: 'start' }}>
                            {Util.timestampToDate(
                              item.messages[0]?.createdDate,
                              'YYYY/MM/DD',
                            )}
                            <br />
                            {Util.timestampToDate(
                              item.messages[0]?.createdDate,
                              'HH:mm',
                            )}
                          </div>

                          <div
                            style={{ textAlign: 'end', position: 'relative' }}
                          >
                            <Button
                              style={{
                                margin: 'auto',
                                width: 'auto',
                                backgroundColor:
                                  selectedIndex === index
                                    ? 'rgb(0, 90, 193)'
                                    : '#58C2FE',
                                color: 'white',
                                maxWidth: 24,
                                minWidth: 24,
                              }}
                              variant="contained"
                              onClick={() =>
                                handleListItemClick(
                                  item?.sender,
                                  item?.receiver,
                                  item?.messages,
                                  index,
                                  item.id,
                                )
                              }
                            >
                              <ArrowForwardIosIcon className="icon-transfer" />
                            </Button>
                            {!!item.lastMessage && (
                              <div
                                style={{
                                  width: 10,
                                  height: 10,
                                  borderRadius: '50%',
                                  backgroundColor: 'red',
                                  position: 'absolute',
                                  top: 2,
                                  right: -3,
                                }}
                              ></div>
                            )}
                          </div>
                        </ListItem>
                      </List>
                    ))}
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        <Col
          xs={6}
          className={[
            'mt-3',
            conversationId ? 'd-wrapper-conversation-work' : '',
          ]}
        >
          <div className="container-conversation">
            <div className="conversation-content">
              {values.sender && (
                <React.Fragment>
                  <div className="d-head-chat timebar">
                    <div className="flexCenter">
                      <ListItemAvatar>
                        <Avatar
                          alt="Avatar"
                          src={
                            values.sender.memberStatus ===
                            MEMBER_USER_STATUS.IN_ACTIVE
                              ? UserDelete
                              : values.sender.avatar?.url || NoPhoto
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText primary={values.sender.nickName} />
                      <SyncAltIcon style={{ margin: '0 10px' }} />
                      <ListItemAvatar>
                        <Avatar
                          alt="Avatar"
                          src={
                            values.receiver.memberStatus ===
                            MEMBER_USER_STATUS.IN_ACTIVE
                              ? UserDelete
                              : values.receiver.avatar?.url || NoPhoto
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText primary={values.receiver.nickName} />
                    </div>
                  </div>
                  <AppChatWork
                    conversationId={conversationId}
                    values={values}
                    viewImage={showImage}
                    refetch={handleRefetchData}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </Col>
      </Row>

      {imageUrl && (
        <ImageModal
          image={{ url: imageUrl }}
          onClose={() => setImageUrl(null)}
        />
      )}
    </MuiThemeProvider>
  );
}
