import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box } from '@material-ui/core';
import { SCHEDULE_TIMER_OPTIONS } from 'Common/constant';
import { scheduleSendCategoryOptions } from 'Common/constant_text';
import RadioGroup from 'Components/RadioGroup';
import ScheduleTimerPicker from './partials/ScheduleTimerPicker';
import ScheduleDynamicTimerInput from './partials/ScheduleDynamicTimerInput';
import styles from './styles.module.scss';
import dayjs from 'dayjs';

export default function ScheduleSelect({ onChange }) {
  const [scheduleSelectValue, setScheduleSelectValue] =
    useState(DEFAULT_VALUE_OPTION);

  const shouldDisableScheduleTimerPicker = useMemo(
    () =>
      +scheduleSelectValue.scheduleTimerOption >
      SCHEDULE_TIMER_OPTIONS.EVERY_DAY,
    [scheduleSelectValue.scheduleTimerOption],
  );

  useEffect(() => {
    const passValue = { ...scheduleSelectValue };
    if (+passValue.scheduleTimerOption <= SCHEDULE_TIMER_OPTIONS.EVERY_DAY)
      delete passValue.frameTime;
    else {
      delete passValue.sendTime;
      passValue.frameTime = dayjs
        .duration({ minutes: +passValue.frameTime })
        .asMilliseconds();
    }
    delete Object.assign(passValue, {
      category: +passValue.scheduleTimerOption,
    }).scheduleTimerOption;

    onChange(passValue);
  }, [onChange, scheduleSelectValue]);

  const handleChangeSetTimeRadioButton = (e) => {
    e.persist();
    setScheduleSelectValue((prev) => ({
      ...prev,
      scheduleTimerOption: e.target.value,
      //sendTime:
      //  e.target.value <= SCHEDULE_TIMER_OPTIONS.EVERY_DAY
      //    ? dayjs().startOf('year').valueOf()
      //    : null,
    }));
  };

  const handleChangeSetTimeTextField = (e) => {
    e.persist();
    setScheduleSelectValue((prev) => ({
      ...prev,
      frameTime: +e.target.value,
    }));
  };

  const handleChangeScheduleTimer = useCallback((value) => {
    setScheduleSelectValue((prev) => ({
      ...prev,
      sendTime: value,
    }));
  }, []);

  return (
    <>
      <div className="mt-3 mb-1 timer-container">
        <label className="radio-option">
          <input
            checked={
              scheduleSelectValue.scheduleTimerOption <=
              SCHEDULE_TIMER_OPTIONS.EVERY_DAY
            }
            onChange={(e) =>
              setScheduleSelectValue((prev) => ({
                ...prev,
                scheduleTimerOption: SCHEDULE_TIMER_OPTIONS.EVERY_YEAR,
              }))
            }
            type="radio"
          />
          <span>スケジュールタイマー</span>
        </label>
      </div>
      <Box
        width={{ xs: '100%', md: '60%' }}
        className="d-flex align-items-center"
      >
        <RadioGroup
          controlled
          defaultValue={scheduleSelectValue.scheduleTimerOption}
          options={scheduleSendCategoryOptions.slice(0, 4)}
          onChange={handleChangeSetTimeRadioButton}
          classCustom={styles.customRadioGroup}
        />
      </Box>
      <Box width={{ xs: '100%', md: '60%' }} className="mt-1">
        {
          <ScheduleTimerPicker
            category={scheduleSelectValue.scheduleTimerOption}
            onChange={handleChangeScheduleTimer}
            disabled={shouldDisableScheduleTimerPicker}
          />
        }
      </Box>
      {SET_TIME_FIELD_DATA.map((e) => {
        return (
          <ScheduleDynamicTimerInput
            key={e.value}
            globalOptionState={scheduleSelectValue.scheduleTimerOption}
            label={e.label}
            radioValue={e.value}
            inputValue={scheduleSelectValue.frameTime}
            onChangeRadio={handleChangeSetTimeRadioButton}
            onChangeInput={handleChangeSetTimeTextField}
          />
        );
      })}
    </>
  );
}

const DEFAULT_VALUE_OPTION = {
  scheduleTimerOption: SCHEDULE_TIMER_OPTIONS.EVERY_YEAR,
  frameTime: undefined,
  sendTime: dayjs().valueOf(),
};
const SET_TIME_FIELD_DATA = [
  {
    value: SCHEDULE_TIMER_OPTIONS.REGISTER_DATE,
    label: '登録日から分後(2分以上で指定して下さい、制作時間より前の時刻は送信されません）',
  },
  {
    value: SCHEDULE_TIMER_OPTIONS.FIRST_MESSAGE_SENDING_DATE,
    label: '初回メッセージ送信から分後(2分以上で指定して下さい、制作時間より前の時刻は送信されません）',
  },
  {
    value: SCHEDULE_TIMER_OPTIONS.LAST_PAYING_DATE,
    label: '最終入金日から分後(2分以上で指定して下さい、制作時間より前の時刻は送信されません）',
  },
];
