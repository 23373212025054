import { Button, Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Pagination from '../../Components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { loadPointUsage } from '../../Redux/Actions/pointUsage';
import NoPhoto from '../../assets/image/no_user_photo.png';
import Util from '../../Util/Util';
import SortArrows from '../../Components/SortArrows';
import PointUsageSearchBox from './PointUsageSearchBox';
import PointUsageDetail from './PointUsageDetail';
import { convertEnvInfo } from '../../Util/utils';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const pageSize = 50;

function Index() {
  const params = useParams();
  const location = useLocation();
  let agentId;
  if (params.agentId) agentId = params.agentId;
  const [filter, setFilter] = useState({
    sort: { 'pointUsageLog.createdDate': 'DESC' },
  });
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const [pageIndex, setPageIndex] = useState(1);
  const pointUsage = useSelector((state) => state.pointUsage.items);
  const totalRow = useSelector((state) => state.pointUsage.totalRow);
  const dispatch = useDispatch();
  const isAgent = (localStorage.getItem('homePage') || '').includes('agentope');
  const [pointUsageDetail, setPointUsageDetail] = useState(null);

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo])

  useEffect(() => {
    if (!agentId) {
      dispatch(loadPointUsage({ pageIndex, pageSize, ...filter }));
    } else {
      dispatch(loadPointUsage({ pageIndex, pageSize, agentId: agentId, ...filter }));
    }
  }, [dispatch, pageIndex, filter, agentId]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadPointUsage({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (!filter.sort[sortByKey] || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };
  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort });
    setPageIndex(1);
  };

  const onClear = () => setFilter({ sort: { 'pointUsageLog.createdDate': 'DESC' } });

  const handleClickDetail = (pointUsage) => {
    setPointUsageDetail(pointUsage);
  };

  const handleClose = () => {
    setPointUsageDetail(null);
  };

  const reload = () => {
    dispatch(loadPointUsage({ pageIndex: 1, pageSize, ...filter }));
  };

  return (
    <div>
      {location.pathname.includes('agentope') && (
        <div style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }} className='mt-3'>
          <Link
            to={location?.state?.prevPath}
            target="_self"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button
              className="btn-blue"
              style={{
                margin: 'auto',
                width: 'auto',
                color: 'white',
              }}
              variant="contained"
            >
              前の画面に戻る
            </Button>
          </Link>
        </div>
      )}

      <PointUsageSearchBox onSubmit={handleSearch} onClear={onClear} labelDb={labelDb} />

      {pointUsageDetail && (
        <PointUsageDetail
          onClose={handleClose}
          item={pointUsageDetail}
          reload={reload}
          labelDb={labelDb}
        />
      )}

      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="mt-3 scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（送信者）"
                    name="user.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell className="text-center">画像</TableCell>
                <TableCell>{labelDb?.label_nickName?.content}</TableCell>
                <TableCell>性別</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（受信者）"
                    name="target.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell className="text-center">画像</TableCell>
                <TableCell>{labelDb?.label_nickName?.content}</TableCell>
                <TableCell>性別</TableCell>
                <TableCell>オペNum</TableCell>
                <TableCell>会社Num</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="消費ポイント"
                    name="pointUsageLog.point"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>消費前P</TableCell>
                <TableCell>消費後P</TableCell>
                <TableCell>端末</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="種類"
                    name="pointUsageLog.type"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="日時"
                    name="pointUsageLog.createdDate"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {pointUsage.map((x, index) => (
                <TableRow key={x.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{x.user !== null ? x.user.phoneNumber : null}</span>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        isAgent
                          ? ROUTES_CLIENT_DEFINED.CHARACTER.DETAIL_SHORT
                          : ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [x.user?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(x.user.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          x?.user?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          x.user !== null && x.user.avatar !== null
                            ? x.user.avatar.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>
                    {x.user !== null ? x.user.nickName : null}
                  </TableCell>
                  <TableCell>{Util.genderIdToText(x.user?.gender)}</TableCell>
                  <TableCell>
                    <span>
                      {x.target !== null ? x.target.phoneNumber : null}
                    </span>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        isAgent
                          ? ROUTES_CLIENT_DEFINED.CHARACTER.DETAIL_SHORT
                          : ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [x.target?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(x.target.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          x?.target?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          x.target !== null && x.target.avatar !== null
                            ? x.target.avatar.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>
                    {x.target !== null ? x.target.nickName : null}
                  </TableCell>
                  <TableCell>{Util.genderIdToText(x.target?.gender)}</TableCell>
                  <TableCell>{x.target?.operator?.operatorNumber}</TableCell>
                  <TableCell>{x.target?.agent?.agentNumber}</TableCell>
                  <TableCell>{Util.separator(x.point)}</TableCell>
                  <TableCell>{Util.separator(x.pointBefore)}</TableCell>
                  <TableCell>{Util.separator(x.pointAfter)}</TableCell>
                  <TableCell>
                    {Util.typeConnectionToText(x?.device)}
                  </TableCell>
                  <TableCell>
                    {Util.convertPointUsageTypeToText(x.type)}
                  </TableCell>
                  <TableCell>
                    {Util.timestampToDate(x.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                  </TableCell>
                  <TableCell className="text-center" align="center">
                    <Link onClick={() => handleClickDetail(x)} className="d-color-link">
                      表示
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Index;
