import React, { forwardRef, useState } from 'react';
import DatePicker from './DatePicker';
import { Col, FormGroup } from 'reactstrap';
import { FormLabel } from '@material-ui/core';
import moment from 'moment';
import SelectList from './SelectList';

const hour = Array.from({ length: 24 }, (_, i) => ({
  value: i,
  label: i.toLocaleString('ja-JP', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  }),
}));

const minute = Array.from({ length: 60 }, (_, i) => ({
  value: i,
  label: i.toLocaleString('ja-JP', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  }),
}));

const DateTimePicker = forwardRef(function (
  { name, title, titleStyle, containerStyle, timestamp, onChange },
  ref,
) {
  const [dateState, setDateState] = useState(
    moment(+timestamp).format('YYYY-MM-DD'),
  );
  const [hourState, setHourState] = useState(moment(+timestamp).hour());
  const [minuteState, setMinuteState] = useState(
    moment(+timestamp).minute().toString(),
  );

  const handleDate = (e) => {
    const value = moment(e.target.value).format('YYYY-MM-DD');
    setDateState(value);

    let time = moment(`${value} ${hourState}:${minuteState}:00`)
      .valueOf()
      .toString();
    onChange(name, time);
  };

  const handleHour = (e) => {
    let value = e.target.value;
    setHourState(value);
    let time = moment(`${dateState} ${value}:${minuteState}:00`)
      .valueOf()
      .toString();
    onChange(name, time);
  };

  const handleMinute = (e) => {
    let value = e.target.value;
    setMinuteState(value);
    let time = moment(`${dateState} ${hourState}:${value}:00`)
      .valueOf()
      .toString();
    onChange(name, time);
  };

  return (
    <React.Fragment>
      <FormGroup row>
        <Col xs={12}>
          <FormLabel component="legend" style={titleStyle}>
            {title}
          </FormLabel>
        </Col>

        <Col
          className="datePicker"
          xs={12}
          md={7}
          style={{ display: 'flex', ...containerStyle }}
        >
          <DatePicker
            className="date"
            defaultValue={new Date(dateState)}
            onChange={(e) => handleDate(e)}
            locale="ja"
            dateFormat="yyyy/MM/dd"
          />
        </Col>
        <Col xs={12} md={5} style={{ display: 'flex', alignItems: 'center' }}>
          <SelectList
            options={hour}
            name="hour"
            defaultValue={hourState}
            onChange={(e) => handleHour(e)}
          />
          <span className="px-2">時</span>
          <SelectList
            options={minute}
            name="minute"
            defaultValue={minuteState}
            onChange={(e) => handleMinute(e)}
          />
          <span className="px-2">分</span>
        </Col>
      </FormGroup>
    </React.Fragment>
  );
});

export default DateTimePicker;
