import { createAction } from 'redux-actions';
import ScheduleChangeProfile from '../Types/scheduleChangeProfile';

export const loadScheduleChangeProfile = createAction(
  ScheduleChangeProfile.LOAD_SCHEDULE_CHANGE_PROFILE,
);
export const loadScheduleChangeProfileSuccess = createAction(
  ScheduleChangeProfile.LOAD_SCHEDULE_CHANGE_PROFILE_SUCCESS,
);
export const loadScheduleChangeProfileFail = createAction(
  ScheduleChangeProfile.LOAD_SCHEDULE_CHANGE_PROFILE_FAIL,
);
export const estimateScheduleChangeProfileReceivers = createAction(
  ScheduleChangeProfile.ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS,
);
export const estimateScheduleChangeProfileReceiversSuccess = createAction(
  ScheduleChangeProfile.ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS_SUCCESS,
);
export const estimateScheduleChangeProfileReceiversFail = createAction(
  ScheduleChangeProfile.ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS_FAIL,
);  

