import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  estimateScheduleChangeProfileReceivers,
  loadScheduleChangeProfile,
} from 'Redux/Actions/scheduleChangeProfile';
import LoadingBar from '../../../Components/LoadingBar';
import Pagination from '../../../Components/Pagination';
import ScheduleChangeProfileDetailModal from './ScheduleChangeProfileDetailModal';
import ScheduleChangeProfileList from './ScheduleChangeProfileList';
import ScheduleChangeProfileSearchBox from './ScheduleChangeProfileSearchBox';
const pageSize = 50;

function ScheduleChangeProfileStandbyComponent() {
  const {
    loading,
    loadingReceivers,
    items: scheduleChangeProfile,
    totalRow,
  } = useSelector((state) => state.scheduleChangeProfile);
  const [filter, setFilter] = useState({
    timer: 0,
    sort: { sendTime: 'ASC' },
    orderBy: 'ASC',
    sortBy: 'sendTime',
  });
  const [pageIndex, setPageIndex] = useState(1);
  const [scheduleChangeProfileDetail, setScheduleChangeProfileDetail] =
    useState(null);

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'DESC';
    }
    if (filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const dispatch = useDispatch();

  const dispatchPayload = useMemo(() => {
    const { sort, ...serverSort } = filter;
    return { pageIndex, pageSize, ...serverSort };
  }, [pageIndex, filter]);

  useEffect(() => {
    dispatch(loadScheduleChangeProfile(dispatchPayload));
  }, [dispatch, dispatchPayload]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadScheduleChangeProfile(dispatchPayload));
  };

  const handleClose = () => {
    setScheduleChangeProfileDetail(null);
  };

  const reload = () => {
    dispatch(loadScheduleChangeProfile(dispatchPayload));
  };

  const getEstimateReceivers = () => {
    dispatch(
      estimateScheduleChangeProfileReceivers({
        ...dispatchPayload,
        estimateReceivers: true,
      }),
    );
  };

  const handleClickDetail = (item) => {
    setScheduleChangeProfileDetail(item);
  };

  const handleSearch = (searchFilter) => {
    setFilter((prev) => ({ ...prev, ...searchFilter, sort: filter.sort }));
    setPageIndex(1);
  };

  const onClear = () => setFilter({ timer: 0, sort: { sendTime: 'ASC' } });

  return (
    <div>
      <LoadingBar show={loading} />
      <ScheduleChangeProfileSearchBox
        loadingReceivers={loadingReceivers}
        onSubmit={handleSearch}
        onClear={onClear}
        estimateReceivers={getEstimateReceivers}
      />
      {scheduleChangeProfileDetail && (
        <ScheduleChangeProfileDetailModal
          onClose={handleClose}
          scheduleChangeProfile={scheduleChangeProfileDetail}
          reload={reload}
        />
      )}
      <ScheduleChangeProfileList
        loading={loading}
        loadingReceivers={loadingReceivers}
        logSendChat={scheduleChangeProfile}
        pageIndex={pageIndex}
        pageSize={pageSize}
        filter={filter}
        onSort={onSort}
        handleClickDetail={handleClickDetail}
      />
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default ScheduleChangeProfileStandbyComponent;
