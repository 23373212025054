import React, { useEffect, useState } from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { loadFavorites } from '../../../Redux/Actions/favoriteLog';
import { loadFavoritesToMe } from '../../../Redux/Actions/favoriteUserToMe';
import { Link } from 'react-router-dom';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Util from '../../../Util/Util';
import Pagination from '../../../Components/Pagination';
import { themeStyleNormal2Modal } from '../theme_modal';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const pageSize = 20;

function FavoriteModel({ visible, onClose, userId, labelDb }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const favorites = useSelector((state) => state.favorite.items);
  const favoriteUserToMe = useSelector((state) => state.favoriteUserToMe.items);
  const totalRow = useSelector((state) => state.favorite.totalRow);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFavorites({ pageIndex, pageSize, userId }));
    dispatch(loadFavoritesToMe({ pageIndex, pageSize, userId }));
  }, [dispatch, pageIndex, userId]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  return (
    <MuiThemeProvider theme={themeStyleNormal2Modal}>
      <div style={{ position: 'relative' }}>
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={visible}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
            ユーザー詳細-お気に入り
          </DialogTitle>
          <Button
            style={{ position: 'absolute', top: 10, right: 0 }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent className={'table-modal-wrapper'}>
            <Card>
              <Paper square className={classes.root}>
                <Tabs
                  value={value}
                  variant="fullWidth"
                  indicatorColor="none"
                  aria-label="disabled tabs example"
                  onChange={handleChange}
                >
                  <Tab className="async-blue" label="お相手から" />
                  <Tab className="async-blue" label="あなたから" />
                </Tabs>
              </Paper>
              {value === 0 ? (
                <Table padding="checkbox" className="d-table-modal mb-0">
                  <TableBody>
                    <TableRow>
                      <TableCell className="d-width-no">No</TableCell>
                      <TableCell className="d-width-phone">電話番号</TableCell>
                      <TableCell align="center">画像</TableCell>
                      <TableCell>{labelDb?.label_nickName?.content}</TableCell>
                      <TableCell>性別</TableCell>
                      <TableCell>登録日時</TableCell>
                    </TableRow>
                    {favoriteUserToMe &&
                    favoriteUserToMe.map((favorite, index) => (
                      <TableRow key={index + 1}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {favorite.favoriter !== null
                            ? favorite.favoriter.phoneNumber
                            : null}
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            to={Util.applyDynamicRouteParams(
                              ROUTES_CLIENT_DEFINED.USER.DETAIL,
                              [favorite?.favoriter?.id],
                            )}
                            favoriter="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              saveLocalStore(favorite?.favoriter?.id)
                            }
                            style={{ paddingRight: 10 }}
                          >
                            <img
                              className="object-fit-cover"
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: '50%',
                              }}
                              src={
                                favorite.favoriter !== null &&
                                favorite.favoriter.avatar
                                  ? favorite.favoriter.avatar.url
                                  : NoPhoto
                              }
                              alt=""
                            />
                          </Link>
                        </TableCell>
                        <TableCell>
                          {favorite.favoriter !== null
                            ? favorite.favoriter.nickName
                            : null}
                        </TableCell>
                        <TableCell>
                          {favorite.favoriter !== null
                            ? Util.genderIdToText(favorite.favoriter.gender)
                            : null}
                        </TableCell>
                        <TableCell className="text-muted">
                          {Util.timestampToDate(
                            favorite.createdDate,
                            'YYYY/MM/DD HH:mm:ss',
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Table padding="checkbox" className="d-table-modal mb-0">
                  <TableBody>
                    <TableRow>
                      <TableCell className="d-width-no">No</TableCell>
                      <TableCell className="d-width-phone">電話番号</TableCell>
                      <TableCell align="center">画像</TableCell>
                      <TableCell>{labelDb?.label_nickName?.content}</TableCell>
                      <TableCell>性別</TableCell>
                      <TableCell>登録日時</TableCell>
                    </TableRow>
                    {favorites &&
                    favorites.map((favorite, index) => (
                      <TableRow key={index + 1}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {favorite.target !== null
                            ? favorite.target.phoneNumber
                            : null}
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            to={Util.applyDynamicRouteParams(
                              ROUTES_CLIENT_DEFINED.USER.DETAIL,
                              [favorite?.target?.id],
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => saveLocalStore(favorite?.target?.id)}
                            style={{ paddingRight: 10 }}
                          >
                            <img
                              className="object-fit-cover"
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: '50%',
                              }}
                              src={
                                favorite.target !== null &&
                                favorite.target.avatar
                                  ? favorite.target.avatar.url
                                  : NoPhoto
                              }
                              alt=""
                            />
                          </Link>
                        </TableCell>
                        <TableCell>
                          {favorite.target !== null
                            ? favorite.target.nickName
                            : null}
                        </TableCell>
                        <TableCell>
                          {favorite.target !== null
                            ? Util.genderIdToText(favorite.target.gender)
                            : null}
                        </TableCell>
                        <TableCell className="text-muted">
                          {Util.timestampToDate(
                            favorite.createdDate,
                            'YYYY/MM/DD HH:mm:ss',
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Card>

            <center>
              <Pagination
                className={'d-page-wrapper'}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalRow={totalRow}
                onPageChange={handlePageChange}
              />
            </center>
          </DialogContent>
        </Dialog>
      </div>
    </MuiThemeProvider>
  );
}

export default FavoriteModel;
