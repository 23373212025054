import React, { useEffect, useState } from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { loadFootprints } from '../../../Redux/Actions/footprintLog';
import { loadFootprintsToMe } from '../../../Redux/Actions/footprintUserToMe';
import { Link } from 'react-router-dom';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Util from '../../../Util/Util';
import Pagination from '../../../Components/Pagination';
import { themeStyleNormal2Modal } from '../theme_modal';
import { TEXT_HTML_FOOTER_PRINT_MODAL } from '../../../Common/modal_text_info';
import UserDelete from '../../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../../Common/constant';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});


const pageSize = 9999;

function FootprintModel({ visible, onClose, userId, labelDb }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const footprints = useSelector((state) => state.footprint.items);
  const footprintUserToMe = useSelector(
    (state) => state.footprintUserToMe.items,
  );
  const totalRow = useSelector((state) => state.footprint.totalRow);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFootprints({ pageIndex, pageSize, userId }));
    dispatch(loadFootprintsToMe({ pageIndex, pageSize, userId }));
  }, [dispatch, pageIndex, userId]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  return (
    <MuiThemeProvider theme={themeStyleNormal2Modal}>
      <div style={{ position: 'relative' }}>
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={visible}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
            ユーザー詳細-足跡
          </DialogTitle>
          <Button
            style={{ position: 'absolute', top: 10, right: 0 }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
          <div className={'d-title-info-box'}>
            <div className={'text mb-0'} dangerouslySetInnerHTML={{ __html: TEXT_HTML_FOOTER_PRINT_MODAL }}></div>
          </div>

          <DialogContent className={'table-modal-wrapper'}>
            <Card>
              <Paper square className={classes.root}>
                <Tabs
                  value={value}
                  variant="fullWidth"
                  indicatorColor="none"
                  aria-label="disabled tabs example"
                  onChange={handleChange}
                >
                  <Tab className="async-blue" label="お相手から" />
                  <Tab className="async-blue" label="あなたから" />
                </Tabs>
              </Paper>
              {value === 1 ? (
                <Table padding="checkbox" className="d-table-modal mb-0">
                  <TableBody>
                    <TableRow>
                      <TableCell className="d-width-no">No</TableCell>
                      <TableCell className="d-width-phone">電話番号</TableCell>
                      <TableCell align="center">画像</TableCell>
                      <TableCell>{labelDb?.label_nickName?.content}</TableCell>
                      <TableCell>性別</TableCell>
                      <TableCell>登録日時</TableCell>
                    </TableRow>
                    {footprints &&
                    footprints.map((footprint, index) => (
                      <TableRow key={index + 1}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {footprint.target !== null
                            ? footprint.target.phoneNumber
                            : null}
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            to={Util.applyDynamicRouteParams(
                              ROUTES_CLIENT_DEFINED.USER.DETAIL,
                              [footprint?.target?.id],
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              saveLocalStore(footprint.target.id)
                            }
                            style={{ paddingRight: 10 }}
                          >
                            <img className="object-fit-cover"
                                 style={{
                                   width: 30,
                                   height: 30,
                                   borderRadius: '50%',
                                 }}
                                 src={
                                   footprint.target !== null &&
                                   footprint.target.avatar
                                     ? footprint.target.avatar.url
                                     : NoPhoto
                                 }
                                 alt=""
                            />
                          </Link>
                        </TableCell>
                        <TableCell>
                          {footprint.target !== null
                            ? footprint.target.nickName
                            : null}
                        </TableCell>
                        <TableCell>
                          {footprint.target !== null
                            ? Util.genderIdToText(footprint.target.gender)
                            : null}
                        </TableCell>
                        <TableCell className="text-muted">
                          {Util.timestampToDate(
                            footprint.createdDate,
                            'YYYY/MM/DD HH:mm:ss',
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Table padding="checkbox" className="d-table-modal mb-0">
                  <TableBody>
                    <TableRow>
                      <TableCell className="d-width-no">No</TableCell>
                      <TableCell className="d-width-phone">電話番号</TableCell>
                      <TableCell align="center">画像</TableCell>
                      <TableCell>{labelDb?.label_nickName?.content}</TableCell>
                      <TableCell>性別</TableCell>
                      <TableCell>登録日時</TableCell>
                    </TableRow>
                    {footprintUserToMe &&
                    footprintUserToMe.map((footprint, index) => (
                      <TableRow key={index + 1}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {footprint.footprinter !== null
                            ? footprint.footprinter.phoneNumber
                            : null}
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            to={Util.applyDynamicRouteParams(
                              ROUTES_CLIENT_DEFINED.USER.DETAIL,
                              [footprint?.footprinter?.id],
                            )}
                            footprinter="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              saveLocalStore(footprint.footprinter.id)
                            }
                            style={{ paddingRight: 10 }}
                          >
                            <img className="object-fit-cover"
                                 style={{
                                   width: 30,
                                   height: 30,
                                   borderRadius: '50%',
                                 }}
                                 src={
                                  footprint?.footprinter?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                                  footprint.footprinter !== null &&
                                  footprint.footprinter.avatar
                                    ? footprint.footprinter.avatar.url
                                    : NoPhoto
                                 }
                                 alt=""
                            />
                          </Link>
                        </TableCell>
                        <TableCell>
                          {footprint.footprinter !== null
                            ? footprint.footprinter.nickName
                            : null}
                        </TableCell>
                        <TableCell>
                          {footprint.footprinter !== null
                            ? Util.genderIdToText(
                              footprint.footprinter.gender,
                            )
                            : null}
                        </TableCell>
                        <TableCell className="text-muted">
                          {Util.timestampToDate(
                            footprint.createdDate,
                            'YYYY/MM/DD HH:mm:ss',
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Card>

            <center>
              <Pagination
                className={'d-page-wrapper'}
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalRow={totalRow}
                onPageChange={handlePageChange}
              />
            </center>
          </DialogContent>
        </Dialog>
      </div>
    </MuiThemeProvider>
  );
}

export default FootprintModel;
