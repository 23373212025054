import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CustomEditor from '../../../Components/CustomEditor';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, Col, Row } from 'reactstrap';
import { getEnvironment } from '../../../Api/environmentApi';
import { setScheduleMail } from 'Api/scheduleAPI';
import { SCHEDULE_SMS_SENDING_TYPE } from '../../../Common/constant';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import { TEXT_HTML_MANAGEMENT_USER_SEARCH_EMAIL_LOOP_LINK_MODAL } from '../../../Common/modal_text_info';
import LoadingBar from '../../../Components/LoadingBar';
import { loadEnvInfo } from '../../../Redux/Actions/envInfo';
import { loadUserIds } from '../../../Redux/Actions/users';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // ES 2015
import SearchPresetSelect from '../shared/SearchPresetSelect';
import ScheduleSelect from '../shared/ScheduleSelect';
import { previewScheduleMail } from 'Api/scheduleAPI';
import ConfirmPopup from './confirmPopup.jsx';

dayjs.extend(duration);

function SendScheduledEmailModal({ visible, onClose, filter, senderId }) {
  const [data, setData] = useState({});
  const [userIds, setUserIds] = useState([]);
  const [confirmVisible, setConFirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scheduleSelectValue, setScheduleSelectValue] = useState({});
  const [selectedPresets, setSelectedPresets] = useState('');

  const [fromMails, setFromMails] = useState([]);
  const [contentHtml, setContentHtml] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadEnvInfo());
  }, [dispatch]);

  const users = useSelector((state) => state.users.itemsUserIds);
  const { data: fetchedPresets } = useSelector((state) => state.searchPresets);

  useEffect(() => {
    dispatch(loadUserIds(filter));
    getEnvironment({}).then((res) => {
      const env = res;
      const sendGridAddess = Object.keys(env.data.data)
        .filter((t) => t.indexOf('sendgridAddress') > -1)
        .map((t) => {
          return {
            key: t,
            value: env.data.data[t],
          };
        });
      const sendGridAddressFirst = sendGridAddess?.[0];
      data['fromAddress'] = sendGridAddressFirst?.key;
      data['from_mail_address'] = sendGridAddressFirst?.value;

      setData(data);
      setFromMails(sendGridAddess);
    });
  }, [dispatch, filter]);

  useEffect(() => {
    const userIds = users.map((item) => item.id);
    setUserIds(userIds);
  }, [users]);

  const onChange = (event) => {
    data[event.target.name] = event.target.value.trim();
    setData(data);
  };

  const onChangeContent = (event) => {
    data['content'] = event.editor.getData();
    setData(data);
  };

  const handleConfirm = () => {
    if (!data.title) {
      return toast.error('タイトルしてください!');
    }
    if (!data.content) {
      return toast.error('本文してください!');
    }
    if (!data.label) {
      return toast.error('説明テキストしてください!');
    }
    if (!selectedPresets) {
      return toast.error('メモリーの適用してください!');
    }
    replaceUrlPreview(data.content);
  };

  const handleFromMailAddress = (e, key) => {
    data[key] = e.target.value;
    data['fromAddress'] =
      e.target[e.target.selectedIndex].getAttribute('data-key');
    setData(data);
  };

  const handleChangeSelectPresets = (e) => {
    setSelectedPresets(e.target.value);
  };

  const handleChangeScheduleSelectValue = useCallback(
    (value) => setScheduleSelectValue(value),
    [],
  );

  const onCloseConfirm = () => {
    setConFirmVisible(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (users.length > 0) {
      data['users'] = users;
    }

    if (!userIds || (userIds && !userIds.length)) {
      setLoading(false);
      toast.error('受信者数が０人のため、EMAIL送信できません。');
      return;
    }

    const { title, content, label } = data;
    await setScheduleMail({
      ...scheduleSelectValue,
      fromAddress: data['fromAddress'],
      type: senderId
        ? SCHEDULE_SMS_SENDING_TYPE.FROM_OPE_USER_SEARCH
        : SCHEDULE_SMS_SENDING_TYPE.FROM_USER_SEARCH,
      senderId,
      userQuery:
        (selectedPresets &&
          fetchedPresets.find((e) => e.id === selectedPresets)?.content) ||
        filter,
      title,
      content,
      label,
    })
      .then((response) => {
        onClose();
        toast.success(TOAST_MESSAGE.SUCCESS_ADD);
      })
      .catch((e) => {
        toast.error(TOAST_MESSAGE.ERROR_ADD);
        onClose();
      });
  };

  const replaceUrlPreview = async (content) => {
    const { data: replaceContent } = await previewScheduleMail({ content });
    setContentHtml(replaceContent.data);
    setConFirmVisible(true);
  };

  return (
    <div>
      <LoadingBar show={loading} />
      {confirmVisible && (
        <ConfirmPopup
          scheduleSelectValue={scheduleSelectValue}
          selectedPresets={selectedPresets}
          data={data}
          onClose={onCloseConfirm}
          onSubmit={handleSubmit}
          isVisible={confirmVisible}
          contentHtml={contentHtml}
        />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={visible}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogTitle
          id="max-width-dialog-title"
          className={'d-title-modal-custom'}
        >
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              marginRight: 50,
            }}
          >
            <p className={'d-title mb-0'}>EMAIL送信（スケジュール）</p>
          </div>
        </DialogTitle>
        <Button
          style={{
            position: 'absolute',
            top: 8,
            right: 0,
          }}
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </Button>

        <div className={'d-title-info-box'}>
          <div
            className={'text mb-0'}
            dangerouslySetInnerHTML={{
              __html: TEXT_HTML_MANAGEMENT_USER_SEARCH_EMAIL_LOOP_LINK_MODAL,
            }}
          ></div>
        </div>
        <DialogContent>
          <Card style={{ marginBottom: 10 }}>
            <Row style={{ padding: 20 }}>
              <Col xs={12}>
                <div className="mt-3 mb-1">
                  <span style={{ color: 'red' }}>*</span>
                  <span>説明テキスト</span>
                </div>
                <textarea
                  name="label"
                  rows={3}
                  onChange={onChange}
                  style={{ width: '100%' }}
                />

                <div style={{ margin: '16px 0 8px 0' }}>
                  <span style={{ color: 'red' }}>*</span>タイトル
                </div>
                <input
                  style={{
                    width: '100%',
                    marginBottom: '20px',
                    border: '1px solid rgb(118, 118, 118)',
                  }}
                  name="title"
                  onChange={onChange}
                />
                <div style={{ margin: '16px 0 8px 0' }}>
                  <span style={{ color: 'red' }}>*</span>本文
                </div>
                <CustomEditor
                  name="content"
                  rows="3"
                  data=""
                  onChange={onChangeContent}
                  style={{ width: '100%', marginBottom: '20px' }}
                  maxLength={500}
                />
                <div style={{ margin: '16px 0 8px 0' }}>
                  <span style={{ color: 'red' }}>*</span>送信元メールアドレス
                </div>
                <Row>
                  <Col className="datePicker" xs={12}>
                    <select
                      onChange={(e) =>
                        handleFromMailAddress(e, 'from_mail_address')
                      }
                      style={{ width: '100%' }}
                    >
                      {fromMails.map((t) => (
                        <option key={t.key} value={t.value}>
                          {t.value}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>

                <Box width={{ xs: '100%', md: '60%' }} className="mt-3">
                  <SearchPresetSelect
                    isShow={visible}
                    isRequired
                    isHideLoadBtn
                    shouldShowCopyPresetNameBtn
                    selectedPresets={selectedPresets}
                    handleChange={handleChangeSelectPresets}
                    titleStyle={{
                      color: '$gray-900',
                      fontWeight: '400',
                      fontSize: '0.875rem',
                      lineHeight: '1.43',
                    }}
                  />
                </Box>
                <ScheduleSelect onChange={handleChangeScheduleSelectValue} />
              </Col>
            </Row>
          </Card>

          <div style={{ textAlign: 'right', marginBottom: 2 }}>
            {data ? (
              <Button
                onClick={handleConfirm}
                color="primary"
                className="btn-custom btn-blue"
              >
                送信
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: 'lightgray',
                  color: 'white',
                  cursor: 'no-drop',
                  padding: '4px 36px',
                  fontSize: 18,
                  fontWeight: 800,
                }}
              >
                送信
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SendScheduledEmailModal;
