import React, { useEffect, useState } from 'react';
import { MuiThemeProvider, createMuiTheme, Button } from '@material-ui/core';
import { Row, Col, Label, Card } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadDetailOperator } from '../../../../Redux/Actions/operator';
import LoadingBar from '../../../../Components/LoadingBar';
import RemoveModal from './RemoveModal';
import { DISPLAY_CMS_UI_TYPE, MEMBER_USER_STATUS } from '../../../../Common/constant';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { loadOperator } from '../../../../Redux/Actions/operator';
import NoPhoto from '../../../../assets/image/no_user_photo.png';
import Util from '../../../../Util/Util';
import { REPLIED_STATUS } from '../../../../Common/constant';
import Pagination from '../../../../Components/Pagination';
import { deleteOperator } from '../../../../Api/operatorAPI';
import { toast } from 'react-toastify';
import SortArrows from '../../../../Components/SortArrows';
import { TOAST_MESSAGE } from '../../../../Common/constant_text';
import { convertEnvInfo, convertStandByText } from '../../../../Util/utils';
import { LINK_MAP_DEFINED_API, ROUTES_CLIENT_DEFINED } from '../../../../Config/listConstants';
import UserDelete from '../../../../assets/image/delete_user.png';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
  },
});

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

const pageSize = 50;

function Index() {
  const params = useParams();
  const envUICms = useSelector((state) => state.envUICms.data);
  const admin = useSelector((state) => state.admin.data);
  const operatorId = params.operatorId;
  const dispatch = useDispatch();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const listChara = useSelector((state) => state.operator.data);
  const totalRow = useSelector((state) => state.operator.totalRow);
  const [pageIndex, setPageIndex] = useState(1);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const [filter, setFilter] = useState({
    sortBy: 'chara.lastlogin',
    orderBy: 'DESC',
    sort: { 'chara.lastlogin': 'DESC' },
  });

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  useEffect(() => {
    dispatch(loadOperator({ operatorId, pageIndex, pageSize, ...filter }));
  }, [dispatch, operatorId, pageIndex, filter]);

  const deleteButton = envUICms.find((item) => item.link === LINK_MAP_DEFINED_API.OPERATOR_DETAIL_DELETE);
  const smsChatButton = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.ADMIN_OPE_SEARCH_USER_SEND_MESSAGE);
  const detailWorkPageBtn = envUICms.find(item => item.link === LINK_MAP_DEFINED_API.OPERATOR_DETAIL_WORK_PAGE);

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const handleRemove = () => {
    if ((listChara || []).length > 0) {
      deleteOperator(operatorId)
        .then((response) => {
          toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
        })
        .catch((e) => {
          // toast.error(e.response.data.message);
          toast.error(TOAST_MESSAGE.ERROR_DELETE);
        });
    } else {
      setConfirmVisible(true);
    }
  };
  useEffect(() => {
    dispatch(loadDetailOperator(operatorId));
  }, [dispatch, operatorId]);

  const loading = useSelector((state) => state.detailOperator.loading);
  const operator = useSelector((state) => state.detailOperator.data);
  const saveLocalStore = (id) => {
    localStorage.setItem('operatorId', id);
  };

  const countNotReplie = (item) => {
    let total = 0;
    if (item.message.length > 0) {
      item.message.map((message) => {
        if (message.replied === REPLIED_STATUS.NOT_REPLIED) {
          total += 1;
        }
        return true;
      });
    }
    return total;
  };


  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const styleTableCellTd = {
    padding: "0 .75rem"
  }

  return (
    <>
      {confirmVisible && (
        <RemoveModal
          visible={confirmVisible}
          onClose={handleClose}
          operatorId={operatorId}
        />
      )}
      <MuiThemeProvider theme={customTheme}>
        <LoadingBar show={loading} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px 0',
          }}
        >
          <Link
            to={Util.applyDynamicRouteParams(
              ROUTES_CLIENT_DEFINED.OPE_CHARA.SALARYPOINT,
              [operatorId],
            )}
            target="_self"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button
              className="btn-blue"
              style={{
                margin: 'auto',
                width: 'auto',
                color: 'white',
              }}
              variant="contained"
            >
              給与ポイント
            </Button>
          </Link>

          <div>
            {(admin?.memberType === 0 ||
                detailWorkPageBtn.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (<Link
                    to={
                      detailWorkPageBtn.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                        ? Util.applyDynamicRouteParams(
                            ROUTES_CLIENT_DEFINED.ADMIN_OPE.WORK_PAGE_INDIVIDUAL,
                            [operatorId],
                          )
                        : '#'
                    }
                    target="_self"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      marginRight: 15,
                    }}
                  >
                    <Button
                      className="btn-blue"
                      style={{
                        margin: 'auto',
                        color: 'white',
                      }}
                      variant="contained"
                    >
                      {detailWorkPageBtn.content}
                    </Button>
                  </Link>
            )}
            <Link
              to={Util.applyDynamicRouteParams(
                ROUTES_CLIENT_DEFINED.OPE_CHARA.EDIT,
                [operatorId?.id],
              )}
              target="_self"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
              }}
            >
              <Button
                className="btn-blue"
                style={{
                  margin: 'auto',
                  color: 'white',
                }}
                variant="contained"
              >
                編集
              </Button>
            </Link>
          </div>
        </div>
        <Row>
          <Col xs={4}>
            <Card body className="mb-4">
              <Row>

                <Label style={style} xs={5}>
                  <p style={{ paddingLeft: 3 }}>電話番号（重複NG）</p>
                  <hr style={stylehrleft} />
                </Label>
                <Label style={style} xs={7}>
                  {operator?.phoneNumber}
                  <hr style={stylehrright} />
                </Label>
                <Label style={style} xs={5}>
                  <p style={{ paddingLeft: 3 }}>オペレーターNumber（重複NG）</p>
                  <hr style={stylehrleft} />
                </Label>
                <Label style={style} xs={7}>
                  {operator?.operatorNumber}
                  <hr style={stylehrright} />
                </Label>
                <Label style={style} xs={5}>
                  <p style={{ paddingLeft: 3 }}>パスワード（数字6桁）</p>
                  <hr style={stylehrleft} />
                </Label>
                <Label style={style} xs={7}>
                  {operator?.password}
                  <hr style={stylehrright} />
                </Label>
                <Label style={style} xs={5}>
                  <p style={{ paddingLeft: 3 }}>オペレーター名</p>
                  <hr style={stylehrleft} />
                </Label>
                <Label style={style} xs={7}>
                  {operator?.operatorName}
                  <hr style={stylehrright} />
                </Label>
              </Row>
            </Card>

            <Card body className="mb-4">
              <Row>
                <Label style={style} xs={12}>&nbsp;</Label>
                <Label style={style} xs={12}>&nbsp;</Label>
              </Row>
            </Card>
          </Col>
          <Col xs={8}>
            <Card body className="mb-4">
              <Row>
                <Label style={style} xs={12}>&nbsp;</Label>
                <Label style={style} xs={12}>&nbsp;</Label>
                <Label style={style} xs={12}>&nbsp;</Label>
                <Label style={style} xs={12}>&nbsp;</Label>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Label style={style} xs={2}>
                  <p style={{ paddingLeft: 3 }}>管理者メモ</p>
                </Label>
                <Label style={{ ...style, whiteSpace: 'pre-line' }} xs={10}>
                  {operator?.adminMemo}
                </Label>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card
              className="scroll-x-on-top"
              style={{ marginBottom: 20, marginTop: 20 }}
            >
              <Table padding="checkbox" className="table mb-0">
                <TableBody>
                  <TableRow>
                    <TableCell style={{minWidth:20, maxWidth:20}}>No</TableCell>
                    <TableCell align="center">画像</TableCell>
                    <TableCell style={{minWidth:70, maxWidth:90}}>{labelDb?.label_nickName?.content}</TableCell>
                    <TableCell>性別</TableCell>
                    <TableCell>オペレーターNumber</TableCell>
                    <TableCell>都道府県</TableCell>
                    <TableCell style={{minWidth:70, maxWidth:70}}>給与ポイント</TableCell>
                    <TableCell>送信数</TableCell>
                    <TableCell>
                      {/* <SortArrows
                        sortObject={filter.sort}
                        text="受信数"
                        name="chara.messageNoReplied"
                        onClick={onSort}
                      /> */}
                      受信数
                    </TableCell>
                    <TableCell>未返信数</TableCell>
                    <TableCell style={{minWidth:30, maxWidth:30}}>登録日</TableCell>

                    <TableCell style={{minWidth:80, maxWidth:80}}>
                      <SortArrows
                        sortObject={filter.sort}
                        text="ログイン"
                        name="chara.lastlogin"
                        onClick={onSort}
                      />
                    </TableCell>

                    <TableCell>
                      <SortArrows
                        sortObject={filter.sort}
                        text="待機状態"
                        name="chara.standBy"
                        onClick={onSort}
                      />
                    </TableCell>
                    <TableCell align="center">作業</TableCell>
                  </TableRow>
                  {listChara?.map((chara, index) => (
                    <TableRow key={chara.id} style={{ height: 48 }}>
                      <TableCell style={{minWidth:20, maxWidth:20, ...styleTableCellTd}}>{(pageIndex - 1) * pageSize + index + 1}</TableCell>
                      <TableCell align="center" style={styleTableCellTd}>
                        <Link
                          to={Util.applyDynamicRouteParams(ROUTES_CLIENT_DEFINED.CHARACTER.DETAIL_SHORT, [chara.id])}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => saveLocalStore(chara.id)}
                        >
                          <img className="object-fit-cover"
                            style={{ width: 30, height: 30, borderRadius: '50%' }}
                            src={
                              chara?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                              chara.avatar ? chara.avatar.url : NoPhoto}
                            alt=""
                          />
                        </Link>
                      </TableCell>
                      <TableCell style={styleTableCellTd}>{chara.nickName}</TableCell>
                      <TableCell style={styleTableCellTd}>{Util.genderIdToText(chara.gender)}</TableCell>
                      <TableCell style={styleTableCellTd}>{chara.operator.operatorNumber}</TableCell>
                      <TableCell style={styleTableCellTd}>{chara.states?.label}</TableCell>
                      <TableCell style={{minWidth:70, maxWidth:70, ...styleTableCellTd}}>{Util.separator(chara.salarypoint)}</TableCell>
                      <TableCell style={styleTableCellTd}>{chara.countTextSending}</TableCell>
                      <TableCell style={styleTableCellTd}>{chara.countTextReceived}</TableCell>
                      <TableCell style={styleTableCellTd}>{countNotReplie(chara)}</TableCell>
                      <TableCell className="text-muted" style={{minWidth:50, maxWidth:50, ...styleTableCellTd}}>
                        <time>
                          {Util.timestampToDate(chara.registerDate, 'YYYY/MM/DD')}
                        </time>
                      </TableCell>
                      <TableCell className="text-muted" style={{minWidth:80, maxWidth:80}}>
                        <time>
                          {Util.timestampToDate(chara.lastlogin, 'YYYY/MM/DD HH:mm:ss')}
                        </time>
                      </TableCell>
                      <TableCell style={styleTableCellTd}>{convertStandByText(chara?.standBy)}</TableCell>
                      <TableCell style={styleTableCellTd} align="center">
                        {(admin?.memberType === 0 ||
                          smsChatButton.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
                            admin?.memberType === 0 ||
                              smsChatButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                              ? <Link
                                to={`${ROUTES_CLIENT_DEFINED.OPERATOR.SEARCH_USER}?userId=${chara.id}`}
                                target="_self"
                                rel="noopener noreferrer"
                                className="d-color-link"
                              >
                                {smsChatButton?.content}
                            </Link>
                              : smsChatButton?.content
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Card>
            <center>
              <Pagination
                pageIndex={pageIndex}
                pageSize={pageSize}
                totalRow={totalRow}
                onPageChange={handlePageChange}
              />
            </center>
          </Col>
        </Row>
        {(admin?.memberType === 0 ||
          deleteButton.type !== DISPLAY_CMS_UI_TYPE.HIDDEN) && (
            <Button
              variant="contained"
              className="btn-custom btn-red"
              style={{ position: 'absolute', bottom: 20, right: 10 }}
              onClick={
                admin?.memberType === 0 ||
                  deleteButton.type !== DISPLAY_CMS_UI_TYPE.NOT_LINK
                  ? handleRemove
                  : ''}
            >
              {deleteButton.content}
            </Button>
          )}
      </MuiThemeProvider>
    </>
  );
}

export default Index;
