import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';
export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        Cookies.get('token') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES_CLIENT_DEFINED.AUTH.LOGIN_SYS,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
