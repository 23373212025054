import React, { useEffect, useState } from 'react';
import { FormLabel, MenuItem, Select } from '@material-ui/core';
import RadioGroup from 'Components/RadioGroup';
import { useSelector } from 'react-redux';
import { Label } from 'reactstrap';
import { memberTypeOfUserOptionsRequired, standByRequired } from 'Util/utils';
import { DEFAULT_FORM_DATA_ARRAY, FORM_DATA_FIELDS } from './formDataConst';

export default function ChangeProfileForm({ onChange, existedData }) {
  const [data, setData] = useState(new Map(DEFAULT_FORM_DATA_ARRAY));
  const [activatedFields, setActivatedFields] = useState(new Set(['label']));

  useEffect(() => {
    if (!existedData) return;
    const existedDataMap = new Map(existedData);
    const existedActivedFields = new Set([...existedDataMap.keys()]);
    setData(existedDataMap);
    setActivatedFields(existedActivedFields);
  }, [existedData]);

  useEffect(() => {
    const dataMap = new Map();
    [...data.keys()].forEach((key) => {
      if (!activatedFields.has(key)) {
        if (!existedData) return;
        return dataMap.set(key, null);
      }
      return dataMap.set(key, data.get(key));
    });
    onChange(Object.fromEntries(dataMap));
  }, [activatedFields, data, onChange, existedData]);

  const rankOptions = useSelector((state) => state.nameRank.data)?.map((x) => ({
    value: x.id,
    label: `${x.markWord} : ${x.label}`,
  }));

  const handleCheckboxItem = (e) => {
    e.persist();
    const itemVal = e.target.value;
    const newSet = new Set(activatedFields);
    if (newSet.has(itemVal)) newSet.delete(itemVal);
    else newSet.add(itemVal);

    setActivatedFields(newSet);
  };

  const handleChangeData = (e) => {
    const dataMap = new Map(data);
    dataMap.set(e.target.name, e.target.value);
    setData(dataMap);
  };

  return (
    <>
      <div className="mt-3 mb-1">
        <span style={{ color: 'red' }}>*</span>
        <span>説明テキスト</span>
      </div>
      <textarea
        name={FORM_DATA_FIELDS.label}
        rows={3}
        defaultValue={data.get(FORM_DATA_FIELDS.label)}
        onChange={handleChangeData}
        style={{ width: '100%' }}
      />
      <Label xs={12} style={{ padding: 0, marginTop: '12px' }}>
        <FormLabel style={{ display: 'flex', alignItems: 'center' }}>
          <input
            name={FORM_DATA_FIELDS.newMemberTypeValue}
            value={FORM_DATA_FIELDS.newMemberTypeValue}
            type="checkbox"
            onChange={handleCheckboxItem}
            style={{ marginRight: '6px', width: '20px', minWidth: '20px' }}
            checked={activatedFields.has(FORM_DATA_FIELDS.newMemberTypeValue)}
          />
          <p style={{ margin: 'auto', marginLeft: '0px' }}>会員種類</p>
        </FormLabel>
        <RadioGroup
          controlled
          name={FORM_DATA_FIELDS.newMemberTypeValue}
          disabled={!activatedFields.has(FORM_DATA_FIELDS.newMemberTypeValue)}
          defaultValue={data.get(FORM_DATA_FIELDS.newMemberTypeValue)}
          options={memberTypeOfUserOptionsRequired()}
          onChange={handleChangeData}
        />
      </Label>
      <Label xs={12} style={{ padding: 0, marginTop: '12px' }}>
        <FormLabel
          component="legend"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <input
            name={FORM_DATA_FIELDS.newStandbyValue}
            value={FORM_DATA_FIELDS.newStandbyValue}
            type="checkbox"
            onChange={handleCheckboxItem}
            style={{ marginRight: '6px', width: '20px', minWidth: '20px' }}
            checked={activatedFields.has(FORM_DATA_FIELDS.newStandbyValue)}
          />
          <p style={{ margin: 'auto', marginLeft: '0px' }}>待機状態</p>
        </FormLabel>
        <RadioGroup
          controlled
          name={FORM_DATA_FIELDS.newStandbyValue}
          defaultValue={data.get(FORM_DATA_FIELDS.newStandbyValue)}
          options={standByRequired()}
          onChange={handleChangeData}
          disabled={!activatedFields.has(FORM_DATA_FIELDS.newStandbyValue)}
        />
      </Label>
      <Label xs={12} style={{ padding: 0, marginTop: '12px' }}>
        <FormLabel
          component="legend"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <input
            name={FORM_DATA_FIELDS.newRankValue}
            value={FORM_DATA_FIELDS.newRankValue}
            type="checkbox"
            onChange={handleCheckboxItem}
            style={{ marginRight: '6px', width: '20px', minWidth: '20px' }}
            checked={activatedFields.has(FORM_DATA_FIELDS.newRankValue)}
          />
          <p style={{ margin: 'auto', marginLeft: '0px' }}>ランク</p>
        </FormLabel>
        <div style={{ width: '100%' }} className="d-line-select-border">
          <Select
            name={FORM_DATA_FIELDS.newRankValue}
            onChange={handleChangeData}
            value={data.get(FORM_DATA_FIELDS.newRankValue)}
            className="w-100 d-select-multiple-cus"
            disabled={!activatedFields.has(FORM_DATA_FIELDS.newRankValue)}
          >
            {rankOptions.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Label>
      <Label xs={12} style={{ padding: 0, marginTop: '12px' }}>
        <FormLabel
          component="legend"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <input
            name={FORM_DATA_FIELDS.newAdcodeValue}
            value={FORM_DATA_FIELDS.newAdcodeValue}
            type="checkbox"
            onChange={handleCheckboxItem}
            style={{ marginRight: '6px', width: '20px', minWidth: '20px' }}
            checked={activatedFields.has(FORM_DATA_FIELDS.newAdcodeValue)}
          />
          <p style={{ margin: 'auto', marginLeft: '0px' }}>広告コード</p>
        </FormLabel>
        <input
          defaultValue={data.get(FORM_DATA_FIELDS.newAdcodeValue)}
          name={FORM_DATA_FIELDS.newAdcodeValue}
          type="text"
          maxLength={11}
          onChange={handleChangeData}
          disabled={!activatedFields.has(FORM_DATA_FIELDS.newAdcodeValue)}
        />
      </Label>
    </>
  );
}
