import React, { useCallback, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { SCHEDULE_TIMER_OPTIONS } from 'Common/constant';
import { scheduleSendCategoryOptions } from 'Common/constant_text';
import { useSelector } from 'react-redux';
import { Card, Col, Label, Row } from 'reactstrap';
import dayjs from 'dayjs';
import { convertMemberTypeArrayText, convertStandByText } from 'Util/utils';

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

export default function ConfirmPopup({
  scheduleSelectValue,
  selectedPresets,
  data,
  onClose,
  onSubmit,
  isVisible,
  contentHtml,
}) {
  const rankOptions = useSelector((state) => state.nameRank.data);
  const { data: fetchedPresets } = useSelector((state) => state.searchPresets);

  const displayPresetLabel = () => {
    const item = fetchedPresets.find((e) => e.id === selectedPresets);
    return item?.label;
  };

  const displaySendTimeToString = () => {
    const { category, sendTime, frameTime } = scheduleSelectValue;
    const { label } = scheduleSendCategoryOptions.find(
      (e) => e.value === category,
    );
    if (frameTime) return `${label} ${frameTime}分`;
    switch (category) {
      case SCHEDULE_TIMER_OPTIONS.EVERY_YEAR:
        return `${label} ${dayjs(sendTime).format('MM/DD HH:mm')}`;
      case SCHEDULE_TIMER_OPTIONS.EVERY_MONTH:
        return `${label} ${dayjs(sendTime).format('DD HH:mm')}`;
      case SCHEDULE_TIMER_OPTIONS.EVERY_WEEK:
        return `${label} ${dayjs(sendTime).format('dddd HH:mm')}`;
      case SCHEDULE_TIMER_OPTIONS.EVERY_DAY:
        return `${label} ${dayjs(sendTime).format('HH:mm')}`;
      default:
        return 'Error: Cannot find category';
    }
  };

  const displayRankName = useCallback(
    (value) => {
      if (!value) return '';
      const item = rankOptions.find((e) => e.id === value);
      return `${item.markWord} : ${item.label}`;
    },
    [rankOptions],
  );

  const DataRenderArray = useMemo(
    () => [
      {
        label: '会員種類',
        value: convertMemberTypeArrayText(data.newMemberTypeValue),
      },
      { label: '待機状態', value: convertStandByText(data.newStandbyValue) },
      { label: 'ランク', value: displayRankName(data.newRankValue) },
      { label: '広告コード', value: data.newAdcodeValue },
    ],
    [data, displayRankName],
  );

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={isVisible}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
      className={'d-modal-wrapper'}
    >
      <DialogTitle
        id="max-width-dialog-title"
        className={'d-title-modal-custom'}
      />
      <Button
        style={{
          position: 'absolute',
          top: 8,
          right: 0,
        }}
        onClick={onClose}
      >
        <CloseIcon color="primary" />
      </Button>
      <DialogContent>
        <div style={{ margin: '0.6rem 0 1rem 0' }}>
          {/*実行してよろしいですか？*/}
          <Card body className="mb-4">
            <Row>
              <Col
                style={style}
                xs={6}
                className={'d-line-content d-flex d-justify-space-between'}
              >
                <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <p style={{ paddingLeft: 3 }}>スケジュール</p>
                </div>
                <div
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    textAlign: 'end',
                  }}
                  className={'mr-1'}
                >
                  <p>{displaySendTimeToString()}</p>
                </div>
              </Col>
              <Col style={style} xs={6} className={'d-line-content'}>
                <div
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    textAlign: 'end',
                  }}
                  className={'mr-1'}
                >
                  <span className="d-red-color">スケジュールタイマー</span>
                </div>
              </Col>
            </Row>

            <Row className={'d-line-col'}>
              <Col
                style={style}
                xs={12}
                className={'d-line-content d-flex d-justify-space-between'}
              >
                <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <p style={{ paddingLeft: 3 }}>
                    検索条件メモリーの適用（メモリーを編集しても条件は更新されません）
                  </p>
                </div>
                <div
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    textAlign: 'end',
                  }}
                  className={'mr-1'}
                >
                  {displayPresetLabel()}
                </div>
              </Col>
            </Row>
          </Card>
          <Card body className="mb-4">
            <Row>
              <Label style={style} xs={3}>
                <p style={{ paddingLeft: 3 }}>説明テキスト</p>
              </Label>
              <Label style={style} xs={9}>
                {data?.label}
              </Label>
            </Row>
          </Card>
          <Card body className="mb-4">
            {DataRenderArray.map((item) => (
              <Row key={item.label}>
                <Label style={style} xs={3}>
                  <p style={{ paddingLeft: 3 }}>{item.label}</p>
                </Label>
                <Label style={style} xs={9}>
                  <p>{item.value}</p>
                </Label>
              </Row>
            ))}
          </Card>
        </div>
        <div>
          <center style={{ margin: 15 }}>
            <Button
              onClick={onClose}
              color="primary"
              className="btn-custom btn-red"
            >
              いいえ
            </Button>
            <Button
              onClick={onSubmit}
              color="primary"
              className="btn-custom btn-blue"
            >
              はい
            </Button>
          </center>
        </div>
      </DialogContent>
    </Dialog>
  );
}
