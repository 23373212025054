import {
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Pagination from '../../Components/Pagination';
import SortArrows from '../../Components/SortArrows';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { loadSalaryLog } from '../../Redux/Actions/salaryLog';
import NoPhoto from '../../assets/image/no_user_photo.png';
import Util from '../../Util/Util';
import SearchBox from './SearchBox';
import SalaryLogDetail from './SalaryLogDetail';
import { loadChatLog } from '../../Redux/Actions/chatLog';
import { convertEnvInfo } from '../../Util/utils';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';
import _ from 'lodash';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const pageSize = 50;

function SalaryLogManagement() {
  const salaryLog = useSelector((state) => state.salary.items);
  const totalRow = useSelector((state) => state.salary.totalRow);
  const [pageIndex, setPageIndex] = useState(1);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const [filter, setFilter] = useState({
    sort: { 'salaryLog.createdDate': 'DESC' },
  });
  const [salaryLogDetail, setSalaryLogDetail] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadSalaryLog({ pageIndex, pageSize, ...filter }));
  }, [dispatch, filter, pageIndex]);

  const reload = () => {
    dispatch(loadChatLog({ pageIndex: 1, pageSize, ...filter }));
  };

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);

    dispatch(loadSalaryLog({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (
      filter.sort[sortByKey] === undefined ||
      filter.sort[sortByKey] === 'DESC'
    ) {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const handleClickDetail = (salaryLog) => {
    setSalaryLogDetail(salaryLog);
  };

  const handleClose = () => {
    setSalaryLogDetail(null);
  };

  const handleSearch = (searchFilter) => {
    const reducedFilter = _.pickBy(searchFilter, (value) => value !== '');
    setFilter({ sort: { 'salaryLog.createdDate': 'DESC' }, ...reducedFilter });
    setPageIndex(1);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <SearchBox onSubmit={handleSearch} labelDb={labelDb} />

      {salaryLogDetail && (
        <SalaryLogDetail
          onClose={handleClose}
          salaryLog={salaryLogDetail}
          reload={reload}
          labelDb={labelDb}
        />
      )}

      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                {/* user */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（ユーザー）"
                    name="user.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={`${labelDb?.label_nickName?.content}（ユーザー）`}
                    name="user.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>

                {/* chara */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（キャラ）"
                    name="chara.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={`${labelDb?.label_nickName?.content}（キャラ）`}
                    name="chara.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>
                <TableCell>オペNum</TableCell>
                <TableCell>会社Num</TableCell>
                <TableCell>給与ポイント</TableCell>
                <TableCell>種類</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="登録日"
                    name="salaryLog.createdDate"
                    onClick={onSort}
                    defaultName="salaryLog.createdDate"
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {salaryLog.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{item.user?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [item.user?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(item.user?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          item.user?.memberStatus ===
                          MEMBER_USER_STATUS.IN_ACTIVE
                            ? UserDelete
                            : item.user?.avatar
                              ? item.user.avatar.url
                              : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{item.user?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(item.user?.gender)}
                  </TableCell>
                  <TableCell>
                    <span>{item.chara?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [item.chara?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(item.chara?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          item.chara?.memberStatus ===
                          MEMBER_USER_STATUS.IN_ACTIVE
                            ? UserDelete
                            : item.chara?.avatar
                              ? item.chara?.avatar.url
                              : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{item.chara?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(item.chara?.gender)}
                  </TableCell>
                  <TableCell>
                    {item.chara?.operator
                      ? item.chara.operator.operatorNumber
                      : ''}
                  </TableCell>
                  <TableCell>
                    {item.chara?.agent ? item.chara.agent.agentNumber : ''}
                  </TableCell>
                  <TableCell>{Util.separator(item.addPoint)}</TableCell>
                  <TableCell>
                    {Util.convertPointUsageTypeToText(item.type)}
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        item.createdDate,
                        'YYYY/MM/DD HH:mm',
                      )}
                    </time>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link
                      onClick={() => handleClickDetail(item)}
                      className="d-color-link"
                    >
                      表示
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default SalaryLogManagement;
