import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { loadSalaryLog } from '../../Redux/Actions/salaryLog';
import moment from 'moment';
import _ from 'lodash';
import { Button, Card, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { POINT_TYPE } from '../../Common/constant';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { useParams } from 'react-router';
import Util from '../../Util/Util';
import { Link, useLocation } from 'react-router-dom';

import { loadDetailOperator } from '../../Redux/Actions/operator';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const useStyles = makeStyles({
  td: {
    width: '100px !important',
  },
});
const pageSize = 1000;

export default function Index() {
  const salaryLog = useSelector((state) => state.salary.items);
  const location = useLocation();

  const isSalarySumaryEndpoint = location.pathname.split('/')[2] === 'salary_summary';

  const params = useParams();
  let operatorId;
  if (params.operatorId && !isSalarySumaryEndpoint) {
    operatorId = params.operatorId;
  }

  const classes = useStyles();

  const [pointByMonth, setPointByMonth] = useState();
  const [months, setMonths] = useState();
  const [month, setMonth] = useState('2021-01');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDetailOperator(operatorId));
  }, [dispatch, operatorId]);

  const operator = useSelector((state) => state.detailOperator.data);

  useEffect(() => {
    if (!operatorId) dispatch(loadSalaryLog({ pageIndex: 1, pageSize }));
    else
      dispatch(
        loadSalaryLog({ pageIndex: 1, pageSize, operatorId, operatorid: operator?.operatorNumber || '' }),
      );

  }, [dispatch, operatorId, operator]);

  useEffect(() => {
    let data = _.groupBy(salaryLog, function(salary) {
      return moment(Number(salary.createdDate)).format('YYYY-MM');
    });
    setMonths(Object.keys(data));
    setMonth(Object.keys(data)[0]);
    setPointByMonth(data);
  }, [salaryLog]);

  const totalSend = (array) => {
    let total = 0;
    array.map((item) => {
      if (
        item.type === POINT_TYPE.CHAT_SEND ||
        item.type === POINT_TYPE.PHOTO_SEND
      )
        total += item.addPoint;
      return true;
    });
    return total;
  };

  const totalReceiver = (array) => {
    let total = 0;
    array.map((item) => {
      if (
        item.type === POINT_TYPE.CHAT_RECEIVE ||
        item.type === POINT_TYPE.PHOTO_RECEIVE
      )
        total += item.addPoint;
      return true;
    });
    return total;
  };

  const countTotal = (array) => {
    const dataTotal = {
      totalChatSend: 0,
      totalChatReceive: 0,
      totalPhotoSend: 0,
      totalPhotoReceive: 0,
      totalCall: 0,
    };

    for (let i = 0; i < array.length; i++) {
      const currentArrayIndex = array[i];
      switch (currentArrayIndex.type) {
        case (POINT_TYPE.CHAT_SEND):
          dataTotal.totalChatSend += currentArrayIndex?.addPoint || 0;
          break;
        case (POINT_TYPE.PHOTO_SEND):
          dataTotal.totalPhotoSend += currentArrayIndex?.addPoint || 0;
          break;
        case (POINT_TYPE.CHAT_RECEIVE):
          dataTotal.totalChatReceive += currentArrayIndex?.addPoint || 0;
          break;
        case (POINT_TYPE.PHOTO_RECEIVE):
          dataTotal.totalPhotoReceive += currentArrayIndex?.addPoint || 0;
          break;
        case (POINT_TYPE.CALL_TALK):
          dataTotal.totalCall += currentArrayIndex?.addPoint || 0;
          break;
        default:
          break;
      }
    }

    return dataTotal;
  };

  const generateData = () => {
    if (pointByMonth && pointByMonth[month]) {
      let data = _.groupBy(pointByMonth[month], function(salary) {
        return moment(Number(salary.createdDate)).format('YYYY-MM-DD');
      });
      data = Object.entries(data);
      return data.map((item, index) => {
        const { totalChatSend, totalChatReceive, totalPhotoSend, totalPhotoReceive, totalCall } = countTotal(item[1]);
        return (
          <TableRow key={index}>
            <TableCell>{item[0]}</TableCell>
            <TableCell>{Util.separator(totalChatSend)}</TableCell>
            <TableCell>{Util.separator(totalChatReceive)}</TableCell>
            <TableCell>{Util.separator(totalPhotoSend)}</TableCell>
            <TableCell>{Util.separator(totalPhotoReceive)}</TableCell>
            <TableCell>{Util.separator(totalCall)}</TableCell>
            {/* <TableCell>{totalSend(item[1]) + totalReceiver(item[1])}</TableCell> */}
          </TableRow>
        );
      });
    }
  };

  const totalData = () => {
    if (pointByMonth && pointByMonth[month]) {
      let chatSend = 0, chatReceive = 0, photoSend = 0, photoReceive = 0, call = 0;

      pointByMonth[month].map((item) => {
        switch (item.type) {
          case (POINT_TYPE.CHAT_SEND):
            chatSend += item.addPoint;
            break;
          case (POINT_TYPE.PHOTO_SEND):
            photoSend += item.addPoint;
            break;
          case (POINT_TYPE.CHAT_RECEIVE):
            chatReceive += item.addPoint;
            break;
          case (POINT_TYPE.PHOTO_RECEIVE):
            photoReceive += item.addPoint;
            break;
          case (POINT_TYPE.CALL_TALK):
            call += item.addPoint;
            break;
          default:
            break;
        }
        return true;
      });
      return (
        <TableRow>
          <TableCell>合計</TableCell>
          <TableCell>{Util.separator(chatSend)}</TableCell>
          <TableCell>{Util.separator(chatReceive)}</TableCell>
          <TableCell>{Util.separator(photoSend)}</TableCell>
          <TableCell>{Util.separator(photoReceive)}</TableCell>
          <TableCell>{Util.separator(call)}</TableCell>
          {/* <TableCell>{chatSend + chatReceive}</TableCell> */}
        </TableRow>
      );
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {!isSalarySumaryEndpoint ? (<div style={{ textAlign: 'left', display: 'flex', alignItems: 'flex-end' }}>
          <Link
            to={Util.applyDynamicRouteParams(
              ROUTES_CLIENT_DEFINED.OPERATOR.DETAIL,
              [operatorId],
            )}
            target="_self"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <Button
              className="btn-blue"
              style={{
                margin: 'auto',
                width: 'auto',
                color: 'white',
              }}
              variant="contained"
            >
              前の画面に戻る
            </Button>
          </Link>
        </div>) : (<div />)}

        <div style={{ textAlign: 'right' }}>
          <FormControl className={classes.formControl}>
            <InputLabel shrink>月表示</InputLabel>
            <Select
              name="state"
              onChange={(e) => setMonth(e.target.value)}
              displayEmpty
              className={classes.selectEmpty}
              required={true}
              style={{ width: 200 }}
              value={month}
            >
              {months &&
              months.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top" style={{ marginTop: 20 }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>日付</TableCell>
                <TableCell>チャット送信</TableCell>
                <TableCell>チャット受信</TableCell>
                <TableCell>画像送信</TableCell>
                <TableCell>画像受信</TableCell>
                <TableCell>音声会話</TableCell>
              </TableRow>
              {generateData()} {totalData()}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}
