import moment from 'moment';
import {
  blogType,
  hiddenStatus,
  inspectionStatus,
  loginControlArray,
  memberArray,
  memberTypeArray,
  payingTypeArray,
  paymentTypeArray,
  readStatus,
  repliedStatus,
  sexArray,
  typeConnection,
  usagePointType,
  likeMatchTypes,
} from '../Common/constant_text';
import configs from '../Config/config';
import { uploadImage } from '../Api/uploadApi';
import {
  APP_ALLOW_UPLOAD_TYPE,
  CONFIG_LINK,
  OTP_TYPE,
} from '../Common/constant';
import _ from 'lodash';

class Util {
  static timestampToDate(timestamp, format) {
    if (!+timestamp || !timestamp) return '';
    const date = new Date(Number(timestamp));
    return moment(date).format(format);
  }

  static genderIdToText = (genderId) => {
    return sexArray.find((ele) => ele.id === genderId)?.value;
  };

  static separator(numb) {
    let str = String(numb).toString().split('.');
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return str.join('.');
  }

  static readIdToText = (readId) => {
    return readStatus.find((ele) => ele.id === +readId)?.value;
  };

  static repliedToText = (repliedId) => {
    return repliedStatus.find((ele) => ele.id === +repliedId)?.value;
  };

  static hiddenToText = (id) => {
    return hiddenStatus.find((ele) => ele.id === id)?.value;
  };

  static inspectionToText = (id) => {
    return inspectionStatus.find((ele) => ele.id === id)?.value;
  };

  static convertPointUsageTypeToText = (type) => {
    return usagePointType.find((ele) => ele.id === type)?.value;
  };

  static typeConnectionToText = (typeConnectionId) => {
    return typeConnection.find((ele) => ele.id === typeConnectionId)?.value;
  };

  static memberStateToText = (stateId) => {
    return memberArray.find((item) => item.id === stateId)?.value;
  };

  static memberTypeToText = (stateId) => {
    return memberTypeArray.find((item) => item.id === stateId)?.value;
  };

  static loginControlToText = (loginControlId) => {
    return loginControlArray.find((ele) => ele.id === loginControlId)?.value;
  };

  static paymentTypeIdToText = (paymentTypeId) => {
    return paymentTypeArray.find((ele) => ele.id === paymentTypeId)?.value;
  };

  static payingTypeIdToText = (payingTypeId) => {
    return payingTypeArray.find((ele) => ele.id === payingTypeId)?.value;
  };

  static rankValueToText = (rankValue, nameRankArray) => {
    return (nameRankArray ?? []).find((ele) => ele.id === rankValue)?.label;
  };

  static blogTypeToText = (valueType) => {
    return (blogType ?? []).find((ele) => ele.id === +valueType)?.value;
  };

  static likeMatchTypeToText = (valueType) => {
    return (likeMatchTypes ?? []).find((ele) => ele.id === +valueType)?.value;
  };

  static calcAge = (birthDate) => moment().diff(moment(birthDate), 'year');

  static firebaseUpload = ({ ref, filename, blob, dir }) => {
    if (configs.allowUpload === APP_ALLOW_UPLOAD_TYPE.SERVER) {
      return new Promise(async (resolve, reject) => {
        const filename = new Date().getTime() + '.jpg';
        const formData = new FormData();
        formData.append('imageFile', new File([blob], filename));
        const imagePath = `${configs.apiDomain}/${dir}/${filename}`;
        const { data: uploaded } = await uploadImage(formData, {
          dir: btoa(dir),
        });
        if (uploaded.data) {
          resolve(imagePath);
        }
      });
    }
  };

  static findLabel = (gender, ProfileArray) => {
    let result = ProfileArray.find((item) => item.gender === gender);
    return result ? result.label : '';
  };

  static findValue = (gender, ProfileArray) => {
    let result = ProfileArray.find((item) => item.gender === gender);
    return result ? result.value : '';
  };

  static findProfile = (gender, profileArray) => {
    return (profileArray ?? []).find((item) => item.gender === gender);
  };

  static findProfileId = (gender, profileArray) => {
    let result = (profileArray ?? []).find((item) => item.gender === gender);
    return result ? result.id : '';
  };

  static getFilePath = (url) => {
    return url.replace(`${configs.apiDomain}/`, '');
  };

  static myProfSrc = (user, dir = `myprofile`) => {
    const url = `${this.domainWebUseWithUseWWW()}/autologin/?id=:autoLoginEncode&v=${Math.random()}`;

    let autoLoginEncode = '';

    autoLoginEncode =
      user && user.phoneNumber && user.needOtp !== OTP_TYPE.OTP
        ? btoa(`${user.phoneNumber}-${user.password}-${dir}`)
        : btoa(`${user.phoneNumber}-needOTP-${dir}`);
    return url.replace(':autoLoginEncode', autoLoginEncode);
  };

  static buildLink = (path) => {
    const host = window.location.host.replace(
      'admin.',
      !!configs.allowUseWww ? 'www.' : '',
    );
    return window.location.protocol + '//' + host + path;
  };

  static buildVerifyOtpAutoLogin = (payload = {}) => {
    return `${payload?.phoneNumber}-${(payload?.needOtp ? payload?.password : 'needOTP') || ''}${payload?.path}`; // needOtp = 0, needOTP, needOTP = 1, none
  };

  static previewJobDetail = (job, dir = 'myprofile') => {
    return `${this.domainWebUseWithUseWWW()}/${CONFIG_LINK.WEB}/job-detail/${job.id}?preview=1&ver=${Math.random()}`;
  };

  static domainWebUseWithUseWWW = () => {
    return `${window.location.origin.replace('admin.', !!configs.allowUseWww ? 'www.' : '')}`;
  };

  static splitArrayIntoSubArrays = (arr, colsNum) => {
    const result = [];
    const cloneArr = _.cloneDeep(arr);
    const len = arr.length;
    for (let i = 0; i < colsNum; i++) {
      result.push(
        cloneArr.slice(
          Math.ceil(len / colsNum) * i,
          Math.ceil(len / colsNum) * (i + 1),
        ),
      );
    }
    return result;
  };

  static applyDynamicRouteParams(routeStr, paramsArr) {
    const paramRegex = new RegExp(/(?<=\/):\w+\d*/);
    let result = routeStr.toString();
    paramsArr.forEach((e) => {
      result = result.replace(paramRegex, e);
    });
    return result;
  }
}

export default Util;
