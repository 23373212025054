import { Card, MuiThemeProvider, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import SortArrows from '../../Components/SortArrows';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import NoPhoto from '../../assets/image/no_user_photo.png';
import NoAvatar from '../../assets/image/sys_photo.png';
import Util from '../../Util/Util';
import React from 'react';
import { convertReadStatusText } from '../../Util/utils';
import { CHAT_MESSAGE_TYPE, MEMBER_USER_STATUS } from '../../Common/constant';
import UserDelete from '../../assets/image/delete_user.png';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

function ChatSupportLogList(props) {
  const {
    chatLog,
    onUpdate,
    pageSize = 2,
    pageIndex = 1,
    filter = { sort: {} },
    onSort = (sortByKey) => {
    },
    handleClickDetail,
    labelDb
  } = props;

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <Card className="scroll-x-on-top">
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell>No</TableCell>
              {/* Sender */}
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="電話番号"
                  name="sender.phoneNumber"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell align="center">画像</TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text={labelDb?.label_nickName?.content}
                  name="sender.nickName"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>性別</TableCell>
              {/* Receiver */}
              <TableCell align="center">画像</TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="受信者"
                  name="receiver.nickName"
                  onClick={onSort}
                />
              </TableCell>
              {/* content */}
              <TableCell>本文</TableCell>

              <TableCell>投稿画像</TableCell>

              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="既読"
                  name="message.read"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="返信"
                  name="message.replied"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="×表示"
                  name="message.hidden"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="×認証"
                  name="message.inspection"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="登録日"
                  name="message.createdDate"
                  onClick={onSort}
                  defaultName="message.createdDate"
                />
              </TableCell>
              <TableCell align="center">ボタン</TableCell>
            </TableRow>

            {chatLog.map((mess, index) => {
              const sender = mess?.sender || mess?.adminSender;
              const receiver = mess?.receiver || mess.userReceiver;
              return (
                <TableRow key={mess.id}>
                  <TableCell>{(pageIndex - 1) * pageSize + index + 1}</TableCell>
                  <TableCell>
                    <span>{sender?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [sender?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(sender?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          sender?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          sender?.avatar ? sender?.avatar?.url : (sender?.isSupport ? NoAvatar : NoPhoto)
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{sender?.nickName}</TableCell>
                  <TableCell>{Util.genderIdToText(sender?.gender)}</TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [receiver?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(receiver?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          receiver?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          receiver?.avatar
                            ? receiver?.avatar.url
                            : (!sender?.isSupport ? NoAvatar : NoPhoto)
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{receiver?.nickName}</TableCell>
                  <TableCell>{mess.type === CHAT_MESSAGE_TYPE.TEXT && mess.content}</TableCell>
                  <TableCell>{mess.type === CHAT_MESSAGE_TYPE.IMAGE && (
                    <img src={mess.content ?? NoPhoto} alt='messageImage' className='imgSquare object-fit-cover' />)}</TableCell>
                  <TableCell>{convertReadStatusText(mess.read)}</TableCell>
                  <TableCell>{Util.repliedToText(mess.replied)}</TableCell>
                  <TableCell>{Util.hiddenToText(mess.hidden)}</TableCell>
                  <TableCell>{Util.inspectionToText(mess.inspection)}</TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(mess.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link onClick={() => handleClickDetail(mess)} className="d-color-link">表示</Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
    </MuiThemeProvider>
  );
}

export default ChatSupportLogList;
