export const FORM_DATA_FIELDS = {
  label: 'label',
  newMemberTypeValue: 'newMemberTypeValue',
  newStandbyValue: 'newStandbyValue',
  newRankValue: 'newRankValue',
  newAdcodeValue: 'newAdcodeValue',
};

export const DEFAULT_FORM_DATA_ARRAY = [
  [FORM_DATA_FIELDS.newMemberTypeValue, '1'],
  [FORM_DATA_FIELDS.newStandbyValue, '0'],
  [FORM_DATA_FIELDS.newRankValue, 'rank_01'],
  [FORM_DATA_FIELDS.newAdcodeValue, ''],
];
