import { call, put, takeLatest } from 'redux-saga/effects';
import { getAllScheduleMail } from 'Api/scheduleAPI';
import {
  estimateScheduleMailReceiversFail,
  estimateScheduleMailReceiversSuccess,
  loadScheduleMailFail,
  loadScheduleMailSuccess,
} from '../Actions/scheduleMail';
import ScheduleMail from '../Types/scheduleMail';

function* handleLoadScheduleMail(action) {
  try {
    const res = yield call(getAllScheduleMail, action.payload);
    const { data } = res;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadScheduleMailSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadScheduleMailFail(error));
  }
}

function* handleEstimatingScheduleMailReceivers(action) {
  try {
    const res = yield call(getAllScheduleMail, action.payload);
    const { data } = res;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(estimateScheduleMailReceiversSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(estimateScheduleMailReceiversFail(error));
  }
}

export default function* scheduleMailSaga() {
  yield takeLatest(ScheduleMail.LOAD_SCHEDULE_MAIL, handleLoadScheduleMail);
  yield takeLatest(
    ScheduleMail.ESTIMATE_SCHEDLULE_MAIL_RECEIVERS,
    handleEstimatingScheduleMailReceivers,
  );
}
