import { handleActions } from 'redux-actions';
import envSysCms from '../Types/envSysCms';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const actions = {
  [envSysCms.GET_ENV_SYS_CMS]: (state) => ({
    ...state,
    loading: true,
  }),
  [envSysCms.GET_ENV_SYS_CMS_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.data,
    loading: false,
  }),
  [envSysCms.GET_ENV_SYS_CMS_FAILURE]: (state) => ({
    ...state,
    data: [],
    loading: false,
  }),
  [envSysCms.GET_ENV_SYS_CMS_ALL]: (state) => ({
    ...state,
    loading: true,
  }),
  [envSysCms.GET_ENV_SYS_CMS_ALL_SUCCESS]: (state, action) => ({
    ...state,
    data: action.payload.data,
    loading: false,
  }),
  [envSysCms.GET_ENV_SYS_CMS_ALL_FAILURE]: (state) => ({
    ...state,
    data: [],
    loading: false,
  }),
  [envSysCms.UPDATE_ENV_SYS_CMS]: (state) => ({
    ...state,
    updating: true,
  }),
  [envSysCms.UPDATE_ENV_SYS_CMS_SUCCESS]: (state, action) => ({
    ...state,
    updating: false,
    success: true,
    data: action.payload.data,
  }),
  [envSysCms.UPDATE_ENV_SYS_CMS_FAILURE]: (state) => ({
    ...state,
    updating: false,
  }),
};

export default handleActions(actions, initialState);
