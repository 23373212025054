import {
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import SortArrows from '../../../Components/SortArrows';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import Util from '../../../Util/Util';
import {
  displayTextScheduleCronjobTypeOptions,
  displayTextScheduleSendStatusOptions,
  displayTextScheduleSendTypeOptions,
} from '../../../Util/utils';
import { scheduleSendCategoryOptions } from '../../../Common/constant_text';
import { MEMBER_USER_STATUS } from '../../../Common/constant';
import NoPhoto from 'assets/image/no_user_photo.png';
import UserDelete from 'assets/image/delete_user.png';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // ES 2015
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

dayjs.extend(duration);

function ScheduleMailList({
  logSendMail = [],
  pageIndex,
  pageSize,
  loading,
  loadingReceivers,
  filter = { sort: {} },
  onSort = (sortByKey) => {},
  handleClickDetail,
}) {
  return (
    <div className="align-top">
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>設定名</TableCell>
                <TableCell>配信カテゴリー</TableCell>
                <TableCell>送信間隔（分）</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="次回送信日時"
                    name="sendTime"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="最終送信日時"
                    name="lastSentDate"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="登録日"
                    name="createdDate"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>送信元ユーザー</TableCell>
                <TableCell>登録環境</TableCell>
                <TableCell>配信処理</TableCell>
                <TableCell>配信設定</TableCell>
                <TableCell>送信想定数</TableCell>
                <TableCell>ボタン</TableCell>
              </TableRow>

              {logSendMail.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>

                  <TableCell>{item.label}</TableCell>
                  <TableCell>
                    {
                      scheduleSendCategoryOptions.find(
                        (e) => e.value === +item.category,
                      )?.label
                    }
                  </TableCell>
                  <TableCell>
                    {item.frameTime &&
                      `${dayjs.duration(+item.frameTime).asMinutes()}分`}
                  </TableCell>
                  <TableCell>
                    {item.sendTime &&
                      Util.timestampToDate(
                        item.sendTime,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        item.lastSentDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        item.createdDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell>
                    {item.sender && (
                      <div className="flex" style={{ alignItems: 'center' }}>
                        <Link
                          to={Util.applyDynamicRouteParams(
                            ROUTES_CLIENT_DEFINED.USER.DETAIL,
                            [item?.sender?.id],
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginRight: '8px' }}
                        >
                          <img
                            className="imgRound"
                            src={
                              item.sender.memberStatus ===
                              MEMBER_USER_STATUS.IN_ACTIVE
                                ? UserDelete
                                : item.avatar
                                  ? item.avatar.url
                                  : NoPhoto
                            }
                            alt=""
                          />
                        </Link>
                        <span>{item.sender.nickName}</span>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    {displayTextScheduleSendTypeOptions(item.type)}
                  </TableCell>
                  <TableCell>
                    {displayTextScheduleCronjobTypeOptions(item.cronjobStatus)}
                  </TableCell>
                  <TableCell>
                    {displayTextScheduleSendStatusOptions(item.sendStatus)}
                  </TableCell>
                  <TableCell>
                    {loadingReceivers ? (
                      <Skeleton.Button block active />
                    ) : (
                      item.countReceivers && `${item.countReceivers}人`
                    )}
                  </TableCell>
                  <TableCell
                    className="d-color-link"
                    style={{ cursor: 'pointer' }}
                  >
                    <Link onClick={() => handleClickDetail(item)}>表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default ScheduleMailList;
