import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BLOG_TYPE, MEMBER_USER_STATUS } from '../../Common/constant';
import LoadingBar from '../../Components/LoadingBar';
import Pagination from '../../Components/Pagination';
import SortArrows from '../../Components/SortArrows';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { loadBlogs } from '../../Redux/Actions/blogs';
import Util from '../../Util/Util';
import BlogDetailModal from './BlogDetailModal';
import BlogsManagementSearchBox from './BlogsManagementSearchBox';
import NoPhoto from '../../assets/image/no_user_photo.png';
import { convertEnvInfo } from '../../Util/utils';
import UserDelete from '../../assets/image/delete_user.png';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const pageSize = 50;

function BlogsManagement() {
  const [filter, setFilter] = useState({
    sort: { 'blog.createdDate': 'DESC' },
  });
  const [pageIndex, setPageIndex] = useState(1);
  const [blogDetail, setBlogDetail] = useState(null);

  const loading = useSelector((state) => state.blogs.loading);
  const blogs = useSelector((state) => state.blogs.items);
  const totalRow = useSelector((state) => state.blogs.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const dispatch = useDispatch();

  const handleClickDetail = (blog) => {
    setBlogDetail(blog);
  };

  const handleClose = () => {
    setBlogDetail(null);
  };

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadBlogs({ pageIndex, pageSize, ...filter }));
  }, [dispatch, filter, pageIndex]);

  const reload = () => {
    dispatch(loadBlogs({ pageIndex: 1, pageSize, ...filter }));
  };

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort });
    setPageIndex(1);
  };

  return (
    <div>
      <LoadingBar show={loading} />
      {blogDetail && (
        <BlogDetailModal onClose={handleClose} blog={blogDetail} reload={reload} />
      )}
      <BlogsManagementSearchBox onSubmit={handleSearch} labelDb={labelDb} />
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top" style={{ margin: '10px 0 20px 0' }}>
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="sender.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="sender.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="性別"
                    name="sender.gender"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>投稿文</TableCell>
                <TableCell>投稿画像</TableCell>
                <TableCell>種類</TableCell>
                <TableCell>×Status</TableCell>
                <TableCell>×表示</TableCell>
                <TableCell>×認証</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="登録日"
                    name="blog.createdDate"
                    onClick={onSort}
                    defaultName="blog.createdDate"
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {blogs.map((blog, index) => (
                <TableRow key={blog.id} style={{ height: 48 }}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{blog?.sender?.phoneNumber}</TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [blog?.sender?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(blog?.sender?.id)}
                    >
                      <img
                        className="imgRound"
                        src={blog?.sender?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                             blog?.sender?.avatar?.url ?? NoPhoto}
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{blog?.sender?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(blog.sender?.gender)}
                  </TableCell>
                  <TableCell>
                    {blog.type === BLOG_TYPE.TEXT ? blog.content : null}
                  </TableCell>
                  <TableCell>
                    {blog.type === BLOG_TYPE.IMAGE ? (
                      <img src={blog.content ?? NoPhoto} className="imgSquare object-fit-cover" alt="" />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {Util.blogTypeToText(blog?.type)}
                  </TableCell>
                  <TableCell>{blog.status}</TableCell>
                  <TableCell>{Util.hiddenToText(blog.hidden)}</TableCell>
                  <TableCell>
                    {Util.inspectionToText(blog.inspection)}
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(blog.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link onClick={() => handleClickDetail(blog)} to="#" className="d-color-link">
                      表示
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default BlogsManagement;
