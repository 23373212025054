import React, { useEffect, useState } from 'react';
import {
  Button,
  createMuiTheme,
  FormGroup,
  FormLabel,
  MuiThemeProvider,
  TextField,
  InputLabel,
} from '@material-ui/core';
import { Card, Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { createOperator } from '../../../../Api/operatorAPI';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { TOAST_MESSAGE } from '../../../../Common/constant_text';
import {
  CREATE_USER_TYPE,
  REGEX_ALPHABET,
  REGEX_EMAIL,
  REGEX_NUMBER,
} from '../../../../Common/constant';
import {
  checkFullWidth,
  convertEnvInfo,
  validateFullWidthVsAlphabet,
} from '../../../../Util/utils';
import { useSelector } from 'react-redux';
import { checkExistingUserBeforeCreate } from '../../../../Api/userAPI';
import { ROUTES_API_DEFINED } from 'Config/listConstants';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
    MuiFormLabel: {
      root: {
        display: 'flex !important',
      },
      asterisk: {
        color: 'red',
        order: '-1',
        marginLeft: '-4px',
      },
      required: {
        display: 'flex',
      },
    },
  },
});

function Index() {
  const [values, setValues] = useState({});
  const [open, setOpen] = useState(false);
  const envInfo = useSelector((state) => state.envInfo.data);
  const history = useHistory();
  const [colorLabel, setColorLabel] = useState('');
  const [colorLabelDialog, setColorLabelDialog] = useState('');

  useEffect(() => {
    setColorLabel(
      convertEnvInfo(envInfo, ['admin_lable_form_1'], true)?.admin_lable_form_1
        ?.content,
    );
    setColorLabelDialog(
      convertEnvInfo(envInfo, ['admin_lable_form_2'], true)?.admin_lable_form_2
        ?.content,
    );
  }, [envInfo]);

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      event.target.blur();
    }
  };

  const handleSubmit = async () => {
    let error = false;
    if (!values.phoneNumber) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.phoneNumber);
      error = true;
    } else if (
      values.phoneNumber &&
      (checkFullWidth(values.phoneNumber) ||
        !REGEX_NUMBER.test(String(values.phoneNumber)))
    ) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.phoneNumber);
      error = true;
    }

    if (!values.operatorNumber) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.operatorNumber);
      error = true;
    } else if (
      values.operatorNumber &&
      !REGEX_ALPHABET.test(values.operatorNumber)
    ) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.operatorNumber);
      error = true;
    }

    if (!values.password) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.password810);
      error = true;
    } else if (
      values.password &&
      (values.password.length < 8 ||
        values.password.length > 10 ||
        !REGEX_ALPHABET.test(values.password) ||
        checkFullWidth(values.password))
    ) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
      error = true;
    }

    if (!values.operatorName) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.operator.name);
      error = true;
    }

    if (values.emailAddressMain && !REGEX_EMAIL.test(values.emailAddressMain)) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.email);
      error = true;
    }

    if (values.operatorNumber || values.phoneNumber) {
      try {
        const { operatorNumber, phoneNumber } = values;
        const { data: existingResult } = await checkExistingUserBeforeCreate({
          role: CREATE_USER_TYPE.OPERATOR,
          operatorNumber,
          phoneNumber,
        });
        if (existingResult?.errMsgs?.length) {
          error = true;
          existingResult.errMsgs.forEach((m) => {
            toast.error(m);
          });
        }
      } catch (err) {
        toast.warn(
          'オペレーターデータがDB上に存在するかどうかを確認できません(WebWarning)',
        );
      }
    }

    if (!error) {
      setOpen(true);
    }
  };

  const validatePassword = (value, name) => {
    validateFullWidthVsAlphabet(value, (valid) => {
      if (valid) {
        toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
        values[name] = '';
      } else {
        values[name] = value;
      }
      setValues({ ...values });
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateOpeSubmit = () => {
    createOperator(values)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          history.push(ROUTES_API_DEFINED.OPERATOR.SEARCH);
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <>
      <MuiThemeProvider theme={customTheme}>
        <div style={{ textAlign: 'right', marginTop: 10, marginBottom: 10 }}>
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleSubmit}
          >
            登録
          </Button>
        </div>
        <Row>
          <Col xs={4}>
            <Card body className="mb-4">
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
            </Card>
            <Card body className="mb-4">
              {/* <Col xs={12} style={{ marginBottom: 10 }}>
                <FormGroup row>
                  <TextField
                    id="standard-required"
                    label="×メールアドレス"
                    type="text"
                    name="emailAddressMain"
                    onChange={handleChange}
                    className="w-100"
                  />
                </FormGroup>
              </Col> */}
              <Col xs={12} style={{ marginBottom: 10 }}>
                <FormGroup row>
                  <InputLabel style={{ color: colorLabel }}>
                    <span className="text-red">*</span>
                    オペレーターNumber（英数字のみ）（重複NG）
                  </InputLabel>
                  <TextField
                    id="standard-required"
                    value={values.operatorNumber || null}
                    name="operatorNumber"
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    type="text"
                    className="w-100"
                    required={true}
                  />
                </FormGroup>
              </Col>
            </Card>
          </Col>
          <Col xs={8}>
            <Card body className="mb-4">
              <Row>
                <Col xs={6}>
                  <FormGroup row>
                    <InputLabel style={{ color: colorLabel, paddingTop: 5 }}>
                      <span className="text-red">*</span>
                      電話番号（重複NG）
                    </InputLabel>
                    <TextField
                      id="standard-required"
                      value={values.phoneNumber || null}
                      name="phoneNumber"
                      onChange={handleChange}
                      onKeyUp={handleKeyUp}
                      type="text"
                      className="w-100"
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup row>
                    <InputLabel style={{ color: colorLabel, paddingTop: 5 }}>
                      <span className="text-red">*</span>
                      パスワード（英数字8文字～10文字）
                    </InputLabel>
                    <TextField
                      id="standard-required"
                      value={values.password}
                      name="password"
                      onKeyUp={handleKeyUp}
                      autoComplete={'new-password'}
                      className="w-100"
                      required={true}
                      type="text"
                      onChange={(e) =>
                        validatePassword(e.target.value, e.target.name)
                      }
                    />
                  </FormGroup>
                  <FormGroup row>
                    <InputLabel style={{ color: colorLabel, paddingTop: 5 }}>
                      <span className="text-red">*</span>
                      オペレーター名
                    </InputLabel>
                    <TextField
                      id="standard-required"
                      value={values.operatorName || null}
                      name="operatorName"
                      onChange={handleChange}
                      onKeyUp={handleKeyUp}
                      type="text"
                      className="w-100"
                      required={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-4">
              <Row>
                <Label xs={12}>
                  <FormLabel style={{ color: colorLabel }} component="legend">
                    管理者メモ
                  </FormLabel>
                  <textarea
                    name="adminMemo"
                    rows="3"
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    style={{ width: '100%', padding: 10 }}
                  />
                </Label>
              </Row>
            </Card>
          </Col>
        </Row>
      </MuiThemeProvider>

      {/* Modal */}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogContent>
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          />
          <div
            style={{
              position: 'absolute',
              top: 6,
              right: 0,
              width: 'auto',
              bottom: 10,
              paddingRight: 50,
            }}
          >
            <center>
              <Button
                className="btn-red"
                style={{
                  padding: '4px 20px',
                  fontSize: 16,
                  fontWeight: 500,
                  color: 'white',
                  marginRight: 20,
                }}
                variant="contained"
                onClick={handleClose}
              >
                戻る
              </Button>
              <Button
                className="btn-custom btn-blue"
                variant="contained"
                onClick={handleCreateOpeSubmit}
              >
                登録
              </Button>
            </center>
          </div>
          <Row>
            <Col xs={6}>
              <Card body className="mb-4">
                <Row>
                  <Label style={{ color: colorLabelDialog }} xs={7}>
                    オペレーターNumber
                  </Label>
                  <Label xs={5}>{values.operatorNumber}</Label>

                  <Label style={{ color: colorLabelDialog }} xs={7}>
                    電話番号
                  </Label>
                  <Label xs={5}>{values.phoneNumber}</Label>

                  <Label style={{ color: colorLabelDialog }} xs={7}>
                    パスワード
                  </Label>
                  <Label xs={5}>{values.password}</Label>

                  <Label style={{ color: colorLabelDialog }} xs={7}>
                    オペレーター名
                  </Label>
                  <Label xs={5}>{values.operatorName}</Label>
                </Row>
              </Card>
            </Col>
            <Col xs={6}>
              <Card body className="mb-4">
                <Label xs={12}>&nbsp;</Label>
                <Label xs={12}>&nbsp;</Label>
                <Label xs={12}>&nbsp;</Label>
                <Label xs={12}>&nbsp;</Label>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card body className="mb-4">
                <Row>
                  <Label style={{ color: colorLabelDialog }} xs={3}>
                    管理者メモ
                  </Label>
                  <Label
                    style={{ height: 'auto', whiteSpace: 'pre-line' }}
                    xs={9}
                  >
                    {values?.adminMemo}
                  </Label>
                </Row>
              </Card>
            </Col>
          </Row>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Index;
