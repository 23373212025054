import { handleActions } from 'redux-actions';
import ScheduleChanegProfile from '../Types/scheduleChangeProfile';

const initialState = {
  items: [],
  loading: false,
  loadingReceivers: false,
  totalRow: 0,
};

const actions = {
  [ScheduleChanegProfile.LOAD_SCHEDULE_CHANGE_PROFILE]: (state) => ({
    ...state,
    loading: true,
  }),
  [ScheduleChanegProfile.LOAD_SCHEDULE_CHANGE_PROFILE_SUCCESS]: (
    state,
    action,
  ) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loading: false,
  }),
  [ScheduleChanegProfile.LOAD_SCHEDULE_CHANGE_PROFILE_FAIL]: (state) => ({
    ...state,
    items: [],
    loading: false,
  }),
  [ScheduleChanegProfile.ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS]: (
    state,
  ) => ({
    ...state,
    loadingReceivers: true,
  }),
  [ScheduleChanegProfile.ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS_SUCCESS]: (
    state,
    action,
  ) => ({
    ...state,
    items: action.payload.items,
    totalRow: action.payload.totalRow,
    loadingReceivers: false,
  }),
  [ScheduleChanegProfile.ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS_FAIL]: (
    state,
  ) => ({
    ...state,
    items: [],
    loadingReceivers: false,
  }),
};
export default handleActions(actions, initialState);
