const ScheduleChangeProfile = {
  LOAD_SCHEDULE_CHANGE_PROFILE: 'LOAD_SCHEDULE_CHANGE_PROFILE',
  LOAD_SCHEDULE_CHANGE_PROFILE_SUCCESS: 'LOAD_SCHEDULE_CHANGE_PROFILE_SUCCESS',
  LOAD_SCHEDULE_CHANGE_PROFILE_FAIL: 'LOAD_SCHEDULE_CHANGE_PROFILE_FAIL',

  ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS:
    'ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS',
  ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS_SUCCESS:
    'ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS_SUCCESS',
  ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS_FAIL:
    'ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS_FAIL',
};

export default ScheduleChangeProfile;
