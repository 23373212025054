import { Card, MuiThemeProvider, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import SortArrows from '../../Components/SortArrows';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import NoPhoto from '../../assets/image/no_user_photo.png';
import { loadLikeLog } from '../../Redux/Actions/likeLog';
import Pagination from '../../Components/Pagination';
import Util from '../../Util/Util';
import { getAllConversation } from '../../Api/conversation';
import { Link } from 'react-router-dom';
import LikeDetailModal from './LikeDetailModal';
import LikeSearchBox from './LikeSearchBox';
import { LIKE_MATCH_TYPE, MEMBER_USER_STATUS } from '../../Common/constant';
import { convertEnvInfo } from '../../Util/utils';
import UserDelete from '../../assets/image/delete_user.png';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const pageSize = 50;

function Index() {
  const likeLog = useSelector((state) => state.like.items);
  const totalRow = useSelector((state) => state.like.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sort: { 'like.createdDate': 'DESC' },
  });
  const [chatRooms, setChatRooms] = useState();
  const [likeDetail, setLikeDetail] = useState(null);

  const handleClickDetail = (like) => {
    setLikeDetail(like);
  };

  const handleClose = () => {
    setLikeDetail(null);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    if (filter.gender && Array.isArray(filter.gender)) {
      filter.gender = filter.gender.join(',');
    }
    dispatch(loadLikeLog({ pageIndex: 1, pageSize, ...filter }));
    const fetchData = async () => {
      const { data } = await getAllConversation();
      setChatRooms(data.data);
    };

    fetchData();
  }, [dispatch, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);

    dispatch(loadLikeLog({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const checkMatch = (like) => {
    return Util.likeMatchTypeToText(like?.matching ? LIKE_MATCH_TYPE.MATCH : LIKE_MATCH_TYPE.NO_MATCH);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const reload = () => {
    dispatch(loadLikeLog({ pageIndex: 1, pageSize, ...filter }));
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort });
    setPageIndex(1);
  };

  const onClear = () => setFilter({ sort: { 'like.createdDate': 'DESC' } });

  return (
    <div style={{ marginTop: 20 }}>
      <LikeSearchBox onSubmit={handleSearch} onClear={onClear} labelDb={labelDb} />
      {likeDetail && (
        <LikeDetailModal
          onClose={handleClose}
          item={likeDetail}
          reload={reload}
          chatRooms={chatRooms}
          labelDb={labelDb}
        />
      )}
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                {/* liker */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（送信）"
                    name="liker.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="liker.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>

                {/* target */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（受信）"
                    name="target.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="target.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>
                <TableCell>いいね</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="登録日"
                    name="like.createdDate"
                    onClick={onSort}
                    defaultName="like.createdDate"
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {likeLog.map((like, index) => (
                <TableRow key={like.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{like?.liker?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [like?.liker?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(like?.liker?.id)}
                    >
                      <img
                        className="imgRound"
                        src={like?.liker?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete : like?.liker?.avatar ? like.liker.avatar.url : NoPhoto}
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{like?.liker?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(like?.liker?.gender)}
                  </TableCell>
                  <TableCell>
                    <span>{like?.target?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [like?.target?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(like?.target?.id)}
                    >
                      <img
                        className="imgRound"
                        src={like?.target?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete : like?.target?.avatar ? like?.target?.avatar.url : NoPhoto}
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{like?.target?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(like?.target?.gender)}
                  </TableCell>
                  <TableCell>
                    {checkMatch(like)}
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        like.createdDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link onClick={() => handleClickDetail(like)} className="d-color-link">表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Index;
