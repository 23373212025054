import React, { useEffect } from 'react';
import history from './history';
import { Router } from 'react-router-dom';
import './App.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import configureStore from './Redux/Store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import { getEnvironment } from './Api/environmentApi';
import PublicRoutes from 'Components/Routes/PublicRoutes';

function App() {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getEnvironment({});
        // const cipherText = data.data;
        // const plainText = CryptoJS.AES.decrypt(
        //   cipherText,
        //   configs.resources.aesSecretKey,
        // );
        // const rawData = JSON.parse(plainText.toString(CryptoJS.enc.Utf8));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Provider store={configureStore()}>
      <Router history={history}>
        <CssBaseline />
        <PublicRoutes />
        {
          // uncomment this and delete PublicRoutes if PublicRoutes are not working
          //<Switch>
          //  <Route exact path="/login_sys" component={SystemLogin} />
          //  <Route exact path="/login_admin" component={AdminLogin} />
          //  <Route exact path="/login_ope" component={OpeLogin} />
          //  <Route exact path="/login_agent" component={AgentLogin} />
          //  <Route path='/404' exact component={NotFound} />
          //
          //  <PrivateRoute path="/" component={Main} />
          //  <PrivateRoute path="/home" component={Main} />
          //  <Route path='*' exact component={NotFound} />
          //
          //  <Redirect to="/home" />
          //</Switch>
        }
        <ToastContainer hideProgressBar />
      </Router>
    </Provider>
  );
}

export default App;
