import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import RadioGroup from '../../../Components/RadioGroup';
import LoadingBar from '../../../Components/LoadingBar';
import Util from '../../../Util/Util';
import { PROFILE_TYPE, REGISTER_TYPE, ROUTES_API_DEFINED } from '../../../Config/listConstants';
import {
  CREATE_USER_TYPE,
  GENDER,
  MEMBER_STATUS,
  REGEX_EMAIL,
  REGEX_NUMBER,
} from '../../../Common/constant';
import {
  checkExistingUserBeforeCreate,
  createUser,
  getSerialChara,
} from '../../../Api/userAPI';
// import ImageList from './ImageList';
import moment from 'moment';
import find from 'lodash/find';
import {
  FormControl,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { loadOperators } from '../../../Redux/Actions/operators';
import { loadAgents } from '../../../Redux/Actions/agents';
import { loadAllArea } from '../../../Redux/Actions/nameState';
import DatePicker from '../../../Components/DatePicker';
import {
  charaStatusOptionsRequired,
  checkFullWidth,
  convertCharaStatusText,
  convertEnvInfo,
  convertInspectionStatusText,
  convertMemberCategoryTypeText,
  convertMemberStatusText,
  convertMemberTypeArrayText,
  convertNeedOtpText,
  convertRegisterStatusText,
  emailAddressMainStatusRequired,
  inspectionStatusRequired,
  memberArrayOptionsRequired,
  memberCategoryTypeRequireOptions,
  memberTypeOfUserOptionsRequired,
  needOtpOptionsRequired,
  registerStatusUserOptionsRequired,
  sexOptionsRequired,
  validateFullWidthVsNumber,
} from '../../../Util/utils';
import { TOAST_MESSAGE } from '../../../Common/constant_text';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
      },
      shrink: {
        transform: 'none',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
      },
    },
    MuiFormLabel: {
      root: {
        display: 'flex !important',
        fontSize: '16px',
      },
      asterisk: {
        color: 'red',
        order: '-1',
        marginLeft: '-4px',
      },
      required: {
        display: 'flex',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    marginTop: '6px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Index() {
  const classes = useStyles();
  const [avatar, setAvatar] = useState(null);
  const [images, setImages] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [password, setPassword] = useState('');
  const [emailAddressMain, setEmailAddressMain] = useState(null);
  const [nickName, setNickName] = useState(null);
  const [gender, setGender] = useState(null);
  const [state, setState] = useState(null);
  const [birthday, setBirthday] = useState(null);
  const [aboutMe, setAboutMe] = useState(null);
  const [adminMemo, setAdminMemo] = useState(null);
  const [ageverification, setAgeverification] = useState(null);
  const [memberStatus, setMemberStatus] = useState(null);
  const loginControl = 0;
  const [paymentStatus] = useState(0);
  const [registerType] = useState(REGISTER_TYPE.CMS);
  const [charaStatus, setCharaStatus] = useState(null);
  const [operatorId, setOperatorId] = useState(null);
  const [agentId, setAgentNumber] = useState(null);
  const [open, setOpen] = useState(false);
  const [needOtp, setNeedOtp] = useState(null);
  const [attributes, setAttributes] = useState([]);
  const [memberType, setMemberType] = useState(null);
  const [memberCategory, setMemberCatememberCategory] = useState(null);
  const [registerStatus, setRegisterStatus] = useState(null);
  const [colorLabel, setColorLabel] = useState('');
  const history = useHistory();
  const [area, setArea] = useState(null);
  const redirectCancelCreateUser = () => {
    history.push(ROUTES_API_DEFINED.MEMBER_USER_SEARCH);
  };
  const [isOpenSelect, setIsOpenSelect] = useState({
    operatorId: false,
    agentId: false,
  });

  const handleSubmit = async () => {
    const now = moment();

    let isNotError = false;
    if (!phoneNumber) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.phoneNumber);
      isNotError = true;
    } else if (
      phoneNumber &&
      (checkFullWidth(phoneNumber) || !REGEX_NUMBER.test(String(phoneNumber)))
    ) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.phoneNumber);
      isNotError = true;
    }

    if (!nickName) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.nickname);
      isNotError = true;
    }
    if (!birthday) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.birthday);
      isNotError = true;
    }
    if (!gender) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.gender);
      isNotError = true;
    }
    if (!state) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.state);
      isNotError = true;
    }
    // Has 0, is active
    if (charaStatus == null || charaStatus === undefined) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.character);
      isNotError = true;
    }
    if (Number(charaStatus) && !operatorId) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.operatorNumber);
      isNotError = true;
    }
    if (Number(charaStatus) && !agentId) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.agencyNumber);
      isNotError = true;
    }
    if (!charaStatus && needOtp && !password) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.password);
      isNotError = true;
    }
    if (now.diff(moment(new Date(birthday)), 'years') < 18) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.birthday18);
      isNotError = true;
    }

    if (!password) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.passwordNumber);
      isNotError = true;
    } else if (
      password &&
      (String(password).match(/\d+/g) ?? []).join('').length !== 6
    ) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.passwordNumber6);
      isNotError = true;
    } else if (password && checkFullWidth(password)) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.passwordNumber6);
      isNotError = true;
    }
    // Has is 0, pass
    if (needOtp == null || needOtp === undefined) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.needOtp);
      isNotError = true;
    }
    // Has is 0, pass
    if (memberType == null || memberType === undefined) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.memberType);
      isNotError = true;
    }
    // Has is 0, pass
    if (memberCategory == null || memberCategory === undefined) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.memberCategoryType);
      isNotError = true;
    }
    // Has is 0, pass
    if (registerStatus == null || registerStatus === undefined) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.registerSatus);
      isNotError = true;
    }
    // Has is 0, pass
    if (memberStatus == null || memberStatus === undefined) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.memberStatus);
      isNotError = true;
    }
    // Has is 0, pass
    if (ageverification == null || ageverification === undefined) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.ageVerification);
      isNotError = true;
    }

    if (emailAddressMain && !REGEX_EMAIL.test(emailAddressMain)) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.email);
      isNotError = true;
    }
    // toast.map((error) => toast.error(error));

    if (emailAddressMain || phoneNumber) {
      try {
        const { data: existingResult } = await checkExistingUserBeforeCreate({
          role: CREATE_USER_TYPE.USER,
          emailAddressMain: emailAddressMain || undefined,
          phoneNumber: phoneNumber || undefined,
        });
        if (existingResult?.errMsgs?.length) {
          isNotError = true;
          existingResult.errMsgs.forEach((m) => {
            toast.error(m);
          });
        }
      } catch (err) {
        toast.error(err.response?.data?.message);
      }
    }

    if (!isNotError) setOpen(true);
  };

  let profile;
  const nameState = useSelector((state) => state.nameState.data);
  const loadingState = useSelector((state) => state.nameState.loading);
  const resource = useSelector((state) => state.resource.data);
  const loadingResource = useSelector((state) => state.resource.loading);
  const loadingOperator = useSelector((state) => state.operators.loading);
  const operators = useSelector((state) => state.operators.items);
  const loadingAgents = useSelector((state) => state.agents.loading);
  const agents = useSelector((state) => state.agents.items);
  const areas = useSelector((state) => state.nameState.dataChild);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
    setColorLabel(
      convertEnvInfo(envInfo, ['admin_lable_form_2'], true)?.admin_lable_form_2
        ?.content,
    );
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadOperators({ pageIndex: 1, pageSize: 1000 }));
    dispatch(loadAgents({ pageIndex: 1, pageSize: 1000 }));
  }, [dispatch]);

  const handleCreateAvatar = (url) => {
    setAvatar(url);
  };

  const handleCreateImages = (images) => {
    setImages(images);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = (e) => {
    const { value } = e.target;
    setCharaStatus(Number(value));

    if (Number(value) && +value === MEMBER_STATUS.CHARA) {
      getSerialChara({}).then((response) => {
        const { data } = response;
        const phoneNumber =
          data && data.data ? data.data.replace('+0', '') : '';

        setPhoneNumber(phoneNumber);
      });
    } else {
      setOperatorId('');
      setAgentNumber('');
      if (charaStatus === MEMBER_STATUS.CHARA) {
        setPhoneNumber('');
      }

      return;
    }
  };

  const handleChangeState = (stateId) => {
    setState(stateId);
    dispatch(loadAllArea(stateId));
    setArea(null);
  };

  const handleCreateUser = () => {
    const data = {
      phoneNumber,
      password,
      emailAddressMain,
      nickName,
      gender: parseInt(gender),
      state,
      birthday,
      aboutMe,
      adminMemo,
      ageverification,
      memberStatus,
      logincontrol: loginControl,
      paymentStatus,
      registerType,
      avatar,
      images,
      charaStatus: parseInt(charaStatus),
      operatorId,
      agentId,
      needOtp,
      memberType,
      area,
      registerStatus,
      attributes,
      memberCategory,
    };
    if (data.operatorId === '') {
      delete data.operatorId;
    }
    if (data.agentId === '') {
      delete data.agentId;
    }

    createUser(data)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          localStorage.setItem('userId', data.data.id);
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
          window.open(`/user/${data.data.id}/detail`, '_blank');
          window.close();
        } else {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  const showResource = () => {
    let profileItem = [];
    let userGender = Number(charaStatus)
      ? gender === GENDER.FEMALE
        ? 'chara_female'
        : 'chara_male'
      : gender === GENDER.FEMALE
        ? 'female'
        : 'male';
    resource.items
      .filter(
        (item) =>
          item.value === PROFILE_TYPE.ROOT && item.gender === userGender,
      )
      .sort((a, b) => a.profileOrder - b.profileOrder)
      .map((resourceItem) => {
        profileItem.push({
          resource: findResources(resourceItem.label),
          label: resourceItem.label,
          name: resourceItem.profile,
          value: findAttributes(resourceItem.label),
        });
        return resourceItem;
      });

    profile = profileItem;

    return profileItem?.map((item, key) => (
      <Row key={key}>
        <Label xs={12}>
          <FormLabel style={{ color: colorLabel }} component="legend">
            {item.label}
          </FormLabel>
          {!loadingResource ? (
            <Select
              key={item?.name}
              name={item.name}
              style={{ width: '100%' }}
              onChange={onChangeAttribute}
            >
              <MenuItem value="" />
              {item.resource
                .sort((a, b) => a.value - b.value)
                .map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <div></div>
          )}
        </Label>
      </Row>
    ));
  };

  const onChangeAttribute = (e) => {
    setAttributes({ ...attributes, [e.target.name]: Number(e.target.value) });
  };

  const findAttributes = (resourceName) => {
    let userGender = Number(charaStatus)
      ? gender === GENDER.FEMALE
        ? 'chara_female'
        : 'chara_male'
      : gender === GENDER.FEMALE
        ? 'female'
        : 'male';
    let parrentName = resource.items.find(
      (x) => x.gender === userGender && x.label === resourceName,
    );
    let responseResources = resource.items.find(
      (x) => x.gender === userGender && x.profile === parrentName?.profile,
      // x.value === user.attributes[snakeToCamel(parrentName.profile)],
    );
    return responseResources?.label;
  };

  const findResources = (resourceName) => {
    let userGender = Number(charaStatus)
      ? gender === GENDER.FEMALE
        ? 'chara_female'
        : 'chara_male'
      : gender === GENDER.FEMALE
        ? 'female'
        : 'male';
    let parrentName = resource.items.find(
      (x) => x.gender === userGender && x.label === resourceName,
    );
    let responseResources = resource.items.filter((x) => {
      return (
        x.gender === userGender &&
        x.profile === parrentName.profile &&
        x.value !== 0
      );
    });

    return responseResources;
  };

  const findOperatorNumber = (data) => {
    return (operators ?? []).find((item) => item.id === data)?.operatorNumber;
  };

  const findAgentNumber = (data) => {
    return (agents ?? []).find((item) => item.id === data)?.agentNumber;
  };

  const onChangeAgentOrOpeId = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case 'operatorId':
        setOperatorId(value);
        break;
      case 'agentId':
        setAgentNumber(value);
        break;
      default:
        break;
    }
  };

  const onCheckUserStatus = (name) => () => {
    if (charaStatus === 0) {
      if (name === 'operatorId') {
        toast.info(TOAST_MESSAGE.INFO.operatorNumber);
      }
      if (name === 'agentId') {
        toast.info(TOAST_MESSAGE.INFO.agentNumber);
      }

      setIsOpenSelect(false);
      setOperatorId('');
      setAgentNumber('');

      setIsOpenSelect({ operatorNumber: false, agentNumber: false });
      return;
    }
    setIsOpenSelect({ ...isOpenSelect, [name]: !isOpenSelect[name] });
  };

  const validatePassword = (value) => {
    validateFullWidthVsNumber(value, (valid) => {
      if (valid) {
        toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.passwordNumber6);
        setPassword('');
        return;
      }
      setPassword(value);
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingBar show={loadingState} />
      <MuiThemeProvider theme={customTheme}>
        <div className="align">
          <Button
            className="btn-custom btn-red"
            variant="contained"
            onClick={redirectCancelCreateUser}
          >
            閉じる
          </Button>
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleSubmit}
          >
            登録
          </Button>
        </div>
        <Row>
          <Col xs={4}>
            <Card body className="mb-4">
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
            </Card>
            <Card body className="mb-4">
              <Col xs={12} style={{ marginBottom: 10 }}>
                <FormGroup row>
                  <InputLabel style={{ color: colorLabel }} shrink>
                    メールアドレス（重複NG）
                  </InputLabel>
                  <TextField
                    id="standard-required"
                    type="text"
                    name="emailAddressMain"
                    onChange={(e) => setEmailAddressMain(e.target.value)}
                    className="w-100"
                  />
                </FormGroup>
              </Col>

              <Col xs={12} style={{ marginBottom: 10 }}>
                <FormGroup row>
                  {!loadingOperator && (
                    <FormControl className={classes.formControl}>
                      <InputLabel style={{ color: colorLabel }} shrink>
                        オペレーターNumber
                      </InputLabel>
                      <Select
                        name="operatorId"
                        onOpen={onCheckUserStatus('operatorId')}
                        onClose={onCheckUserStatus('operatorId')}
                        onChange={onChangeAgentOrOpeId}
                        displayEmpty
                        className={classes.selectEmpty}
                        required={true}
                        open={isOpenSelect.operatorId}
                        value={operatorId || ''}
                      >
                        <MenuItem key={''} value={''}>
                          ---
                        </MenuItem>
                        {operators.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.operatorNumber}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </FormGroup>
              </Col>
              <Col xs={12} style={{ marginBottom: 10 }}>
                <FormGroup row>
                  {!loadingAgents && (
                    <FormControl className={classes.formControl}>
                      <InputLabel style={{ color: colorLabel }} shrink>
                        会社Number
                      </InputLabel>
                      <Select
                        name="agentId"
                        onOpen={onCheckUserStatus('agentId')}
                        onClose={onCheckUserStatus('agentId')}
                        onChange={onChangeAgentOrOpeId}
                        displayEmpty
                        className={classes.selectEmpty}
                        required={true}
                        open={isOpenSelect.agentId}
                        value={agentId || ''}
                      >
                        <MenuItem key={''} value={''}>
                          ---
                        </MenuItem>
                        {agents.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.agentNumber}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </FormGroup>
              </Col>
            </Card>
            <Card body className="mb-4">
              {resource && showResource()}
            </Card>
          </Col>
          <Col xs={8}>
            <Card body className="mb-4">
              <Row>
                <Col xs={6}>
                  <Row>
                    <Col xs={12} style={{ marginBottom: 10 }}>
                      <FormGroup row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            {`${labelDb?.label_nickName?.content}（全角15文字）`}
                          </InputLabel>
                          <TextField
                            id="standard-required"
                            type="text"
                            name="nickName"
                            onChange={(e) => setNickName(e.target.value)}
                            className="w-100"
                            required={true}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs={12} style={{ marginBottom: 10 }}>
                      <FormGroup row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            電話番号（010登録NG）（重複NG）
                          </InputLabel>
                          <TextField
                            id="standard-required"
                            type="text"
                            name="phoneNumber"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="w-100"
                            value={phoneNumber}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs={12} style={{ marginBottom: 10 }}>
                      <FormGroup row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            パスワード（数字6桁）
                          </InputLabel>
                          <TextField
                            id="standard-required"
                            autoComplete={'new-password'}
                            type="text"
                            name="password"
                            value={password}
                            onChange={(e) => validatePassword(e.target.value)}
                            className="w-100"
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs={12} className="mt-18">
                      <FormGroup row>
                        <Col xs={12}>
                          <FormLabel
                            style={{ color: colorLabel }}
                            component="legend"
                          >
                            <span className="text-red">*</span>ログインSMS必要
                          </FormLabel>
                          <RadioGroup
                            name="needOtp"
                            defaultValue=""
                            options={needOtpOptionsRequired()}
                            onChange={(e) => setNeedOtp(Number(e.target.value))}
                            disabled={false}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup row>
                        <FormLabel
                          style={{ color: colorLabel }}
                          component="legend"
                        >
                          <span className="text-red">*</span>性別
                        </FormLabel>
                        <RadioGroup
                          name="gender"
                          defaultValue=""
                          options={sexOptionsRequired()}
                          onChange={(e) => setGender(e.target.value)}
                          disabled={false}
                          required={true}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} className="mt-18">
                      <FormGroup row>
                        <FormLabel
                          style={{ color: colorLabel }}
                          component="legend"
                        >
                          <span className="text-red">*</span>
                          キャラクター(後で変更できません、ご注意下さい）
                        </FormLabel>
                        <RadioGroup
                          name="charaStatus"
                          defaultValue=""
                          options={charaStatusOptionsRequired()}
                          onChange={handleChangeStatus}
                          disabled={false}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
                      <FormGroup row>
                        <FormLabel
                          style={{ color: colorLabel }}
                          component="legend"
                        >
                          <span className="text-red">*</span>生年月日
                        </FormLabel>
                        <DatePicker
                          name="birthday"
                          onChange={(e) =>
                            setBirthday(new Date(e.target.value))
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
                      <FormGroup row>
                        <FormLabel
                          style={{ color: colorLabel }}
                          component="legend"
                        >
                          <span className="text-red">*</span>会員種類
                        </FormLabel>
                        <RadioGroup
                          name="memberType"
                          defaultValue=""
                          options={memberTypeOfUserOptionsRequired()}
                          onChange={(e) =>
                            setMemberType(Number(e.target.value))
                          }
                          disabled={false}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
                      <FormGroup row>
                        <FormLabel
                          style={{ color: colorLabel }}
                          component="legend"
                        >
                          <span className="text-red">*</span>登録種類
                        </FormLabel>
                        <RadioGroup
                          name="memberCategory"
                          defaultValue=""
                          options={memberCategoryTypeRequireOptions()}
                          onChange={(e) =>
                            setMemberCatememberCategory(Number(e.target.value))
                          }
                          disabled={false}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} style={{ marginBottom: 10, marginTop: 10 }}>
                      <FormGroup row>
                        <FormLabel
                          style={{ color: colorLabel }}
                          component="legend"
                        >
                          <span className="text-red">*</span>電話認証状態
                        </FormLabel>
                        <RadioGroup
                          name="registerStatus"
                          defaultValue=""
                          options={registerStatusUserOptionsRequired()}
                          onChange={(e) =>
                            setRegisterStatus(Number(e.target.value))
                          }
                          disabled={false}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Row>
              <Col xs={6}>
                <Card body className="mb-4">
                  <Col xs={12} style={{ marginBottom: 10 }}>
                    <FormGroup row>
                      {nameState && (
                        <FormControl className={classes.formControl}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>都道府県
                          </InputLabel>
                          <Select
                            name="state"
                            onChange={(e) => handleChangeState(e.target.value)}
                            displayEmpty
                            className={classes.selectEmpty}
                            required={true}
                          >
                            {nameState.items
                              .filter((val) => !!val.label)
                              .map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={12} style={{ marginBottom: 10 }}>
                    <FormGroup row>
                      {
                        <FormControl className={classes.formControl}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            エリア
                          </InputLabel>
                          <Select
                            name="area"
                            onChange={(e) => setArea(e.target.value)}
                            displayEmpty
                            className={classes.selectEmpty}
                            required={true}
                          >
                            {(areas || []).map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      }
                    </FormGroup>
                  </Col>
                  <Col xs={12} className="mt-18">
                    <FormGroup row>
                      <FormLabel
                        style={{ color: colorLabel }}
                        component="legend"
                      >
                        <span className="text-red">*</span>表示ステイタス
                      </FormLabel>
                      <RadioGroup
                        name="memberStatus"
                        defaultValue=""
                        options={memberArrayOptionsRequired()}
                        onChange={(e) => setMemberStatus(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} className="mt-18">
                    <FormGroup row>
                      <FormLabel
                        style={{ color: colorLabel }}
                        component="legend"
                      >
                        <span className="text-red">*</span>年齢認証
                      </FormLabel>
                      <RadioGroup
                        name="ageverification"
                        defaultValue=""
                        options={inspectionStatusRequired()}
                        onChange={(e) =>
                          setAgeverification(Number(e.target.value))
                        }
                      />
                    </FormGroup>
                  </Col>
                </Card>
              </Col>
              <Col xs={6}>
                <Card body className="mb-4">
                  <Label style={{ marginTop: 7 }} xs={12}>
                    &nbsp;
                  </Label>
                  <Label xs={12}>&nbsp;</Label>
                  <Label xs={12}>&nbsp;</Label>
                  <Label xs={12}>&nbsp;</Label>
                  <Label xs={12}>&nbsp;</Label>
                  <Label xs={12}>&nbsp;</Label>
                </Card>
              </Col>
              <Col xs={12}>
                <Card body className="mb-4">
                  <Row>
                    <Label xs={12}>
                      <FormLabel
                        style={{ color: colorLabel }}
                        component="legend"
                      >
                        自己PR
                      </FormLabel>
                      <textarea
                        name="aboutMe"
                        rows="3"
                        onChange={(e) => setAboutMe(e.target.value)}
                        style={{ width: '100%', padding: 10 }}
                      />
                    </Label>
                  </Row>
                </Card>
                <Card body className="mb-4">
                  <Row>
                    <Label xs={12}>
                      <FormLabel
                        style={{ color: colorLabel }}
                        component="legend"
                      >
                        管理者メモ
                      </FormLabel>
                      <textarea
                        name="adminMemo"
                        rows="3"
                        onChange={(e) => setAdminMemo(e.target.value)}
                        style={{ width: '100%', padding: 10 }}
                      />
                    </Label>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </MuiThemeProvider>

      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogContent>
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          />
          <div
            style={{
              position: 'absolute',
              top: 6,
              right: 0,
              width: 'auto',
              bottom: 10,
              paddingRight: 50,
              zIndex: 1000,
            }}
          >
            <center>
              <Button
                className="btn-red"
                style={{
                  padding: '4px 20px',
                  fontSize: 16,
                  fontWeight: 500,
                  marginRight: 20,
                  color: 'white',
                }}
                variant="contained"
                onClick={handleClose}
              >
                戻る
              </Button>
              <Button
                className="btn-custom btn-blue"
                variant="contained"
                onClick={handleCreateUser}
              >
                登録
              </Button>
            </center>
          </div>
          <Row>
            <Col xs={4}>
              <Card body className="mb-4">
                <Row>
                  <Label style={{ color: colorLabel }} xs={6}>
                    メールアドレス（重複NG）
                  </Label>
                  <Label xs={6}>{emailAddressMain}</Label>
                  <Label style={{ color: colorLabel }} xs={6}>
                    オペレーターNumber
                  </Label>
                  <Label xs={6}>{findOperatorNumber(operatorId)}</Label>
                  <Label style={{ color: colorLabel }} xs={6}>
                    会社Number
                  </Label>
                  <Label xs={6}>{findAgentNumber(agentId)}</Label>
                </Row>
              </Card>
              <Card body className="mb-4">
                {profile &&
                  profile.map((item, key) => (
                    <Row key={key}>
                      <Label style={{ color: colorLabel }} xs={5}>
                        {item.label}
                      </Label>
                      <Label xs={7}>
                        {item.resource.map((option, index) => {
                          let data;
                          if (option.value === attributes[option.profile]) {
                            data = option.label;
                          }
                          return data;
                        })}
                      </Label>
                    </Row>
                  ))}
              </Card>
            </Col>
            <Col xs={8}>
              <Card body className="mb-4">
                <Row>
                  <Col xs={6}>
                    <Row>
                      <Label style={{ color: colorLabel }} xs={6}>
                        {labelDb?.label_nickName?.content}
                      </Label>
                      <Label xs={6}>{nickName}</Label>
                      <Label style={{ color: colorLabel }} xs={6}>
                        電話番号（重複NG）
                      </Label>
                      <Label xs={6}>{phoneNumber}</Label>
                      <Label style={{ color: colorLabel }} xs={6}>
                        パスワード（数字6桁）
                      </Label>
                      <Label xs={6}>{password}</Label>
                      <Label style={{ color: colorLabel }} xs={6}>
                        ログインSMS必要
                      </Label>
                      <Label xs={6}>{convertNeedOtpText(needOtp)}</Label>
                    </Row>
                  </Col>
                  <Col xs={6}>
                    <Row>
                      <Label style={{ color: colorLabel }} xs={6}>
                        性別
                      </Label>
                      <Label xs={6}>
                        {Util.genderIdToText(Number(gender))}
                      </Label>
                      <Label style={{ color: colorLabel }} xs={6}>
                        キャラクター
                      </Label>
                      <Label xs={6}>
                        {convertCharaStatusText(+charaStatus)}
                      </Label>
                      <Label style={{ color: colorLabel }} xs={6}>
                        生年月日
                      </Label>
                      <Label xs={6}>
                        {moment(birthday).format('YYYY/MM/DD')}
                      </Label>
                      <Label style={{ color: colorLabel }} xs={6}>
                        会員種類
                      </Label>
                      <Label xs={6}>
                        {convertMemberTypeArrayText(memberType)}
                      </Label>
                      <Label style={{ color: colorLabel }} xs={6}>
                        登録種類
                      </Label>
                      <Label xs={6}>
                        {convertMemberCategoryTypeText(memberCategory)}
                      </Label>
                      <Label style={{ color: colorLabel }} xs={6}>
                        電話認証状態
                      </Label>
                      <Label xs={6}>
                        {convertRegisterStatusText(registerStatus)}
                      </Label>
                    </Row>
                  </Col>
                  <Col xs="12">
                    <Row>
                      <Label xs={12}>&nbsp;</Label>
                    </Row>
                  </Col>
                </Row>
              </Card>
              <Row>
                <Col xs={6}>
                  <Card body className="mb-4">
                    <Row>
                      <Label style={{ color: colorLabel }} xs={6}>
                        都道府県
                      </Label>
                      <Label xs={6}>
                        {state
                          ? find(nameState.items, (x) => x.id === state)[
                              'label'
                            ]
                          : null}
                      </Label>
                      <Label style={{ color: colorLabel }} xs={6}>
                        表示ステイタス
                      </Label>
                      <Label xs={6}>
                        {convertMemberStatusText(memberStatus)}
                      </Label>
                      <Label style={{ color: colorLabel }} xs={6}>
                        年齢認証
                      </Label>
                      <Label xs={6}>
                        {convertInspectionStatusText(ageverification)}
                      </Label>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Card body className="mb-4">
                <Row>
                  <Label style={{ color: colorLabel }} xs={3}>
                    自己PR
                  </Label>
                  <Label xs={9}>{aboutMe}</Label>
                </Row>
              </Card>
              <Card body className="mb-4">
                <Row>
                  <Label style={{ color: colorLabel }} xs={3}>
                    管理者メモ
                  </Label>
                  <Label xs={9}>{adminMemo}</Label>
                </Row>
              </Card>
            </Col>
          </Row>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Index;
