import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ImageModal from '../UserManagement/UserEdit/ImageModal';
import NoPhoto from '../../assets/image/no_user_photo.png';
import Util from '../../Util/Util';
import { Link } from 'react-router-dom';
import SortArrows from '../../Components/SortArrows';
import { Card, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import {
  convertImageProfStatusText,
  convertProfImageMainTypesText,
  convertProfImageSubTypesText,
} from '../../Util/utils';
import { MEMBER_USER_STATUS, PROF_IMAGE_MAIN_TYPE } from '../../Common/constant';
import { IMAGE_TYPE, ROUTES_CLIENT_DEFINED } from '../../Config/listConstants';
import UserDelete from '../../assets/image/delete_user.png';

function ImageList(props) {
  const {
    images,
    onUpdate,
    pageSize = 2,
    pageIndex = 1,
    filter = { sort: {} },
    onSort = (sortByKey) => {
    },
    labelDb
  } = props;

  const [editingImage, setEditingImage] = useState();

  const showEditModal = (image) => {
    setEditingImage(image);
  };

  const hideEditModal = () => {
    setEditingImage(null);
  };

  const onUpdateImage = () => {
    hideEditModal();
    onUpdate();
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <Card className="scroll-x-on-top">
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="電話番号"
                  name="user.phoneNumber"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell className="text-center">画像</TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text={labelDb?.label_nickName?.content}
                  name="user.nickName"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="性別"
                  name="user.gender"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell className="text-center">画像</TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="画像種類"
                  name="image.type"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="写真認証"
                  name="image.inspection"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="登録日"
                  name="image.createdDate"
                  onClick={onSort}
                  defaultName="image.createdDate"
                />
              </TableCell>
              <TableCell align="center">ボタン</TableCell>
            </TableRow>

            {images.map((image, index) => (
              <TableRow key={image.id}>
                <TableCell>{(pageIndex - 1) * pageSize + index + 1}</TableCell>
                <TableCell>
                  <span>{image.user?.phoneNumber}</span>
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={Util.applyDynamicRouteParams(
                      ROUTES_CLIENT_DEFINED.USER.DETAIL,
                      [image.user?.id],
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => saveLocalStore(image.user?.id)}
                  >
                    <img
                      className="imgRound"
                      src={image.user?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete : image.user?.avatar ? image.user?.avatar?.url : NoPhoto}
                      alt=""
                    />
                  </Link>
                </TableCell>
                <TableCell>{image.user?.nickName}</TableCell>
                <TableCell>{Util.genderIdToText(image.user?.gender)}</TableCell>
                <TableCell align="center">
                  <img
                    className="imgSquare object-fit-cover"
                    src={image.url ? image.url : NoPhoto}
                    alt=""
                  />
                </TableCell>
                <TableCell>
                  {image.type === IMAGE_TYPE.AVATAR
                    ? convertProfImageMainTypesText(PROF_IMAGE_MAIN_TYPE.STYLE)
                    : convertProfImageSubTypesText(image.subImgNumber)}
                </TableCell>
                <TableCell>
                  {convertImageProfStatusText(+image.inspection)}
                </TableCell>
                <TableCell className="text-muted">
                  <time>
                    {Util.timestampToDate(image.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                  </time>
                </TableCell>
                <TableCell align="center">
                  <div>
                    <Link onClick={() => showEditModal(image)} className="d-color-link">
                      表示
                    </Link>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {editingImage && (
          <ImageModal
            image={editingImage}
            onClose={hideEditModal}
            onUpdate={onUpdateImage}
            type={'management_image_list'}
          />
        )}
      </Card>
    </MuiThemeProvider>
  );
}

export default ImageList;
