import { Card, MuiThemeProvider, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import SortArrows from '../../Components/SortArrows';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import NoPhoto from '../../assets/image/no_user_photo.png';
import Pagination from '../../Components/Pagination';
import Util from '../../Util/Util';
import ChatRoomSearchBox from './ChatRoomSearchBox';
import { loadListConversation } from '../../Redux/Actions/listConversation';
import { Link } from 'react-router-dom';
import ChatRoomDetailModal from './ChatRoomDetailModal';
import { convertEnvInfo } from '../../Util/utils';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const pageSize = 50;

function ChatRoomList() {
  const conversations = useSelector((state) => state.listConversation.items);
  const totalRow = useSelector((state) => state.listConversation.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sort: { 'conversation.createdDate': 'DESC' },
  });
  const [converstionDetail, setConverstionDetail] = useState(null);

  const handleClickDetail = (like) => {
    setConverstionDetail(like);
  };

  const handleClose = () => {
    setConverstionDetail(null);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadListConversation({ pageIndex: 1, pageSize, ...filter }));
  }, [dispatch, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadListConversation({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (filter.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'DESC';
    }
    if (filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: {} });
    setPageIndex(1);

  };

  const reload = () => {
    dispatch(loadListConversation({ pageIndex: 1, pageSize, ...filter }));
  };

  const onClear = () => setFilter({ sort: { 'conversation.createdDate': 'DESC' } });

  return (
    <div style={{ marginTop: 20 }}>
      <ChatRoomSearchBox onSubmit={handleSearch} onClear={onClear} labelDb={labelDb} />
      {converstionDetail && (
        <ChatRoomDetailModal
          onClose={handleClose}
          conversation={converstionDetail}
          reload={reload}
        />
      )}
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                {/* sender */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（送信）"
                    name="sender.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="sender.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>

                {/* receiver */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（受信）"
                    name="receiver.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="受信者"
                    name="receiver.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="登録日（開設日）"
                    name="conversation.createdDate"
                    onClick={onSort}
                    defaultName="conversation.createdDate"
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="更新日時"
                    name="conversation.updatedDate"
                    onClick={onSort}
                    defaultName="conversation.updatedDate"
                  />
                </TableCell>
                <TableCell align="center">表示</TableCell>
              </TableRow>

              {conversations.map((conversation, index) => (
                <TableRow key={conversation?.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{conversation?.sender?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [conversation?.sender?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(conversation?.sender?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          conversation?.sender?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          conversation?.sender?.avatar
                            ? conversation?.sender?.avatar?.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{conversation?.sender?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(conversation?.sender?.gender)}
                  </TableCell>
                  <TableCell>
                    <span>{conversation?.receiver?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [conversation?.receiver?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(conversation?.receiver?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          conversation?.receiver?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          conversation?.receiver?.avatar
                            ? conversation?.receiver?.avatar?.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{conversation?.receiver?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(conversation?.receiver?.gender)}
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(conversation.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(conversation.updatedDate, 'YYYY/MM/DD HH:mm:ss')}
                    </time>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link onClick={() => handleClickDetail(conversation)} className="d-color-link">
                      表示
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default ChatRoomList;
