import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { updateNameRank } from '../../Redux/Actions/nameRank';
import { useDispatch } from 'react-redux';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import './index.scss';
import Util from 'Util/Util';

function RankSettingList({
  nameRank = [],
  pageIndex,
  pageSize,
  loading,
  isEditing,
  setIsEditing,
}) {
  const dispatch = useDispatch();
  const [renderNameRankArrays, setRenderNameRankArrays] = useState([]);
  const COLS_NUM = 4;

  useEffect(() => {
    if (!nameRank) return;
    setRenderNameRankArrays(Util.splitArrayIntoSubArrays(nameRank, COLS_NUM));
  }, [nameRank]);

  const handleChangeItem = (e, currentColIdx, index) => {
    e.persist();
    setRenderNameRankArrays((prev) => {
      const arr = prev[currentColIdx];
      arr[index][`${e.target.name}`] = e.target.value;
      return [...prev];
    });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const onClose = () => {
    setRenderNameRankArrays([
      ...Util.splitArrayIntoSubArrays(nameRank, COLS_NUM),
    ]);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    const values = renderNameRankArrays.flat();
    dispatch(updateNameRank(values));
    setIsEditing(false);
  };

  function renderColumnLayout(elementArr = [], isEdittingMode, crrColIdx) {
    return (
      <Card style={{ width: `${(100 - 1.4 * (COLS_NUM - 1)) / COLS_NUM}%` }}>
        <Table padding="checkbox" className="table text-nowrap mb-0">
          <TableBody>
            <TableRow>
              <TableCell>Rank ID</TableCell>
              <TableCell
                className="font-weight-normal"
                style={{ width: '50%' }}
              >
                表示名
              </TableCell>
              <TableCell className="font-weight-normal">マーク</TableCell>
            </TableRow>
            {elementArr.map((item, key) => (
              <TableRow key={key}>
                <TableCell>{item.id}</TableCell>
                <TableCell>
                  {isEdittingMode ? (
                    <input
                      value={item.label}
                      name={`label`}
                      onChange={(e) => handleChangeItem(e, crrColIdx, key)}
                      className="input-rank"
                    />
                  ) : (
                    item.label
                  )}
                </TableCell>
                <TableCell>
                  {isEditing ? (
                    <input
                      value={item.markWord}
                      name={`markWord`}
                      onChange={(e) => handleChangeItem(e, crrColIdx, key)}
                      className="input-rank"
                    />
                  ) : (
                    item.markWord
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    );
  }

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div className="align align-fixed-top">
        {/* <h4 className="page-title">ランク設定</h4> */}
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className="main-content-custom"
      >
        {renderNameRankArrays.map((e, idx) =>
          renderColumnLayout(e, isEditing, idx),
        )}
      </div>
    </MuiThemeProvider>
  );
}

export default RankSettingList;
