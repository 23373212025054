import React, { useEffect, useState } from 'react';
import {
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { loadFavoriteLog } from '../../Redux/Actions/favoriteLog';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import SortArrows from '../../Components/SortArrows';
import { Link } from 'react-router-dom';
import NoPhoto from '../../assets/image/no_user_photo.png';
import Util from '../../Util/Util';
import Pagination from '../../Components/Pagination';
import FavoriteLogDetailModal from './FavoriteLogDetailModal';
import FavoriteSearchBox from './FavoriteSearchBox';
import { convertEnvInfo } from '../../Util/utils';
import { MEMBER_USER_STATUS } from '../../Common/constant';
import UserDelete from '../../assets/image/delete_user.png';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const pageSize = 50;
function FavoriteLogManagement() {
  const favoriteLog = useSelector((state) => state.favorite.items);
  const totalRow = useSelector((state) => state.favorite.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sort: { 'favorite.createdDate': 'DESC' },
  });
  const [favoriteDetail, setFavoriteDetail] = useState(null);

  const handleClickDetail = (favorite) => {
    setFavoriteDetail(favorite);
  };

  const handleClose = () => {
    setFavoriteDetail(null);
  };

  const reload = () => {
    dispatch(loadFavoriteLog({ pageIndex: 1, pageSize, ...filter }));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadFavoriteLog({ pageIndex: 1, pageSize, ...filter }));
  }, [dispatch, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);

    dispatch(loadFavoriteLog({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort  });
    setPageIndex(1);
  };

  const onClear = () => setFilter({sort: { 'favorite.createdDate': 'DESC' }});

  return (
    <div style={{ marginTop: 20 }}>
      <FavoriteSearchBox onSubmit={handleSearch} onClear={onClear} labelDb={labelDb}/>
      {favoriteDetail && (
        <FavoriteLogDetailModal
          onClose={handleClose}
          item={favoriteDetail}
          reload={reload}
          labelDb={labelDb}
        />
      )}
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                {/* favoriter */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（送信）"
                    name="favoriter.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="favoriter.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>

                {/* target */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（受信）"
                    name="target.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="target.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="登録日"
                    name="favorite.createdDate"
                    onClick={onSort}
                    defaultName="favorite.createdDate"
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {favoriteLog.map((favorite, index) => (
                <TableRow key={favorite.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{favorite?.favoriter?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [favorite?.favoriter?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(favorite?.favoriter?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          favorite?.favoriter?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          favorite?.favoriter?.avatar
                            ? favorite?.favoriter?.avatar?.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{favorite?.favoriter?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(favorite?.favoriter?.gender)}
                  </TableCell>
                  <TableCell>
                    <span>{favorite?.target?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [favorite?.target?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(favorite?.target?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          favorite?.target?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          favorite?.target?.avatar
                            ? favorite?.target?.avatar.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{favorite?.target?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(favorite?.target?.gender)}
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        favorite?.createdDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link onClick={() => handleClickDetail(favorite)} className="d-color-link">
                      表示
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default FavoriteLogManagement;
