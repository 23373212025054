import React, { useEffect, useState } from 'react';
import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { loadFootprintLog } from '../../Redux/Actions/footprintLog';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import SortArrows from '../../Components/SortArrows';
import NoPhoto from '../../assets/image/no_user_photo.png';
import { Link } from 'react-router-dom';
import Util from '../../Util/Util';
import Pagination from '../../Components/Pagination';
import FootprintDetailModal from './FootprintDetailModal';
import FootPrintSearchBox from './FootPrintSearchBox';
import { convertEnvInfo } from '../../Util/utils';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const pageSize = 50;

function Index() {
  const footprintLog = useSelector((state) => state.footprint.items);
  const totalRow = useSelector((state) => state.footprint.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});
  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sort: { 'footprint.createdDate': 'DESC' },
  });

  const [footprintDetail, setFootprintDetail] = useState(null);

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  const handleClickDetail = (footprint) => {
    setFootprintDetail(footprint);
  };

  const handleClose = () => {
    setFootprintDetail(null);
  };

  const reload = () => {
    dispatch(loadFootprintLog({ pageIndex: 1, pageSize, ...filter }));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadFootprintLog({ pageIndex: 1, pageSize, ...filter }));
  }, [dispatch, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);

    dispatch(loadFootprintLog({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined || filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter });
    setPageIndex(1);
  };

  const onClear = () => setFilter({ sort: { 'footprint.createdDate': 'DESC' } });

  return (
    <div style={{ marginTop: 20 }}>
      <FootPrintSearchBox onSubmit={handleSearch} onClear={onClear} labelDb={labelDb} />
      {footprintDetail && (
        <FootprintDetailModal
          onClose={handleClose}
          item={footprintDetail}
          reload={reload}
          labelDb={labelDb}
        />
      )}
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                {/* footprinter */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（登録）"
                    name="footprinter.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="footprinter.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>

                {/* target */}
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（受信）"
                    name="footprinter.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="target.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="登録日"
                    name="footprint.createdDate"
                    onClick={onSort}
                    defaultName="footprint.createdDate"
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {footprintLog.map((footprint, index) => (
                <TableRow key={footprint.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{footprint?.footprinter?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [footprint?.footprinter?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(footprint?.footprinter?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          footprint?.footprinter?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          footprint?.footprinter?.avatar
                            ? footprint?.footprinter.avatar.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{footprint?.footprinter?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(footprint?.footprinter?.gender)}
                  </TableCell>
                  <TableCell>
                    <span>{footprint?.target?.phoneNumber}</span>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [footprint?.target?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(footprint?.target?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          footprint?.target?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          footprint?.target?.avatar
                            ? footprint?.target.avatar.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{footprint?.target?.nickName}</TableCell>
                  <TableCell>
                    {Util.genderIdToText(footprint?.target?.gender)}
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        footprint.createdDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link onClick={() => handleClickDetail(footprint)} className="d-color-link">
                      表示
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default Index;
