import { Card, MuiThemeProvider } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import LoadingBar from '../../Components/LoadingBar';
import SortArrows from '../../Components/SortArrows';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import Util from '../../Util/Util';
import {
  convertCharaStatusText,
  convertInspectionStatusText,
} from '../../Util/utils';
import NoPhoto from '../../assets/image/no_user_photo.png';
import SearchBox from './SearchBox';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

function UserArchiveList(props) {
  const {
    filter = {},
    onSort = (sortByKey) => {},
    users,
    pageIndex,
    pageSize,
    loading,
    totalRow,
    onSearch,
    onClear,
    labelDb,
  } = props;

  const nameRank = useSelector((state) => state.nameRank.data);

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const checkNameRank = (item) => {
    return nameRank.find((rank) => rank.id === item)?.markWord;
  };

  return (
    <div>
      <React.Fragment>
        <SearchBox onSubmit={onSearch} onClear={onClear} labelDb={labelDb} />

        <LoadingBar show={loading} />
        <Row style={{ alignItems: 'flex-end' }}>
          <Col xs={6}>
            <h6 className="color-900 ml-3">検索結果 {totalRow}人</h6>
          </Col>
        </Row>

        <MuiThemeProvider theme={tableNewTheme}>
          <Card className="scroll-x-on-top">
            <Table padding="checkbox" className="table mb-0">
              <TableBody>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="電話番号"
                      name="phoneNumber"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell className="text-center">画像</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text={labelDb?.label_nickName?.content}
                      name="nickName"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={
                        filter.sort?.gender && {
                          ...filter.sort,
                          gender:
                            filter.sort?.gender === 'ASC' ? 'DESC' : 'ASC',
                        }
                      }
                      text="性別"
                      name="gender"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="states.label"
                      text="都道府県"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>年齢</TableCell>
                  <TableCell>ランク</TableCell>
                  <TableCell>会員種類</TableCell>
                  <TableCell>月額会員期限</TableCell>
                  <TableCell>キャラクター</TableCell>
                  <TableCell>オペNum</TableCell>
                  <TableCell>会社Num</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={
                        filter.sort?.ageverification && {
                          ...filter.sort,
                          ageverification:
                            filter.sort?.ageverification === 'ASC'
                              ? 'DESC'
                              : 'ASC',
                        }
                      }
                      name="ageverification"
                      text="年齢認証"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="registerDate"
                      onClick={onSort}
                      text="登録日"
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="ログイン"
                      name="lastlogin"
                      onClick={onSort}
                    />
                  </TableCell>
                </TableRow>

                {users.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {(pageIndex - 1) * pageSize + index + 1}
                    </TableCell>
                    <TableCell>{user.phoneNumber}</TableCell>
                    <TableCell className="text-center">
                      <div>
                        <Link
                          to={Util.applyDynamicRouteParams(
                            ROUTES_CLIENT_DEFINED.USER.DETAIL,
                            [user?.id],
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => saveLocalStore(user.id)}
                        >
                          <img
                            className="imgRound"
                            src={user.avatar ? user.avatar.url : NoPhoto}
                            alt=""
                          />
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell>{user.nickName}</TableCell>
                    <TableCell>{Util.genderIdToText(user.gender)}</TableCell>
                    <TableCell>{user.states?.label}</TableCell>
                    <TableCell>
                      <time>
                        {Util.calcAge(
                          Util.timestampToDate(user.birthday, 'YYYY-MM-DD'),
                        )}
                      </time>
                    </TableCell>
                    <TableCell>{checkNameRank(user.rank)}</TableCell>
                    <TableCell>
                      {Util.memberTypeToText(user.memberType)}
                    </TableCell>
                    <TableCell>
                      {Util.timestampToDate(user.planExpireDate, 'YYYY/MM/DD')}
                    </TableCell>
                    <TableCell>
                      {convertCharaStatusText(user?.charaStatus)}
                    </TableCell>
                    <TableCell>{user.operator?.operatorNumber}</TableCell>
                    <TableCell>{user.agent?.agentNumber}</TableCell>
                    <TableCell>
                      {convertInspectionStatusText(+user.ageverification)}
                    </TableCell>
                    <TableCell>
                      <time>
                        {Util.timestampToDate(user.registerDate, 'YYYY/MM/DD')}
                      </time>
                    </TableCell>
                    <TableCell>
                      <time>
                        {Util.timestampToDate(
                          user.lastlogin,
                          'YYYY/MM/DD HH:mm:ss',
                        )}
                      </time>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </MuiThemeProvider>
      </React.Fragment>
    </div>
  );
}

export default UserArchiveList;
