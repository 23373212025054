// TODO change text by monolit

// Text modal manager user
export const TEXT_HTML_ADD_POINT_MODAL = '入金処理を行う画面です<br>下記より入金処理をして下さい。'; // 入金処理
export const TEXT_HTML_BLOCK_MODAL = 'TEXT_HTML_BLOCK_MODAL'; // ユーザー詳細-ブロック
export const TEXT_HTML_CHAT_DETAIL_MODAL = 'TEXT_HTML_CHAT_DETAIL_MODAL'; // ユーザー詳細-チャットログ個別
export const TEXT_HTML_BLOG_LOG_MODAL = 'TEXT_HTML_BLOG_LOG_MODAL'; // ユーザー詳細-ブログ
export const TEXT_HTML_CHAT_MODAL = 'TEXT_HTML_CHAT_MODAL'; // ユーザー詳細-チャットリスト
export const TEXT_HTML_FAVORITE_MODAL = 'TEXT_HTML_FAVORITE_MODAL'; // ユーザー詳細-お気に入り
export const TEXT_HTML_FOOTER_PRINT_MODAL = 'TEXT_HTML_FOOTER_PRINT_MODAL'; // ユーザー詳細-足跡
export const TEXT_HTML_HELP_MODAL = 'TEXT_HTML_HELP_MODAL'; // ヘルプ
export const TEXT_HTML_LIKE_MODAL = 'TEXT_HTML_LIKE_MODAL'; // ユーザー詳細-いいね
export const TEXT_HTML_POINT_LOG_MODAL = 'TEXT_HTML_POINT_LOG_MODAL'; // ポイント取得ログ
export const TEXT_HTML_POINT_MODAL = 'TEXT_HTML_POINT_MODAL'; // ユーザー詳細-入金ログ
export const TEXT_HTML_POINT_USAGE_MODAL = 'TEXT_HTML_POINT_USAGE_MODAL'; // 消費ポイント
export const TEXT_HTML_REMOVE_USER_MODAL = 'TEXT_HTML_REMOVE_USER_MODAL'; // このユーザーを削除し、一時保存に移動します。
export const TEXT_HTML_SALARY_LOG_MODAL = 'TEXT_HTML_SALARY_LOG_MODAL'; // ユーザー詳細-給与ログ
export const TEXT_HTML_SEND_EMAIL_MODAL = 'TEXT_HTML_SEND_EMAIL_MODAL'; // ユーザー詳細-個別メール送信
export const TEXT_HTML_SEND_NOTIFY_MODAL = 'TEXT_HTML_SEND_NOTIFY_MODAL'; // ユーザー詳細-個別プッシュ送信
export const TEXT_HTML_SEND_SMS_MODAL = 'TEXT_HTML_SEND_SMS_MODAL'; // ユーザー詳細-個別SMS送信
export const TEXT_HTML_UPDATE_MEMBER_PROFILE_MODAL = 'TEXT_HTML_UPDATE_MEMBER_PROFILE_MODAL'; // ユーザー詳細-個別SMS送信
export const TEXT_HTML_TWILIO_HISTORY_MODAL = 'TEXT_HTML_TWILIO_HISTORY_MODAL'; // 会話ログ
export const TEXT_HTML_POINT_JOB_ENTRY = 'TEXT_HTML_POINT_JOB_ENTRY'; // job entry
export const TEXT_HTML_ENTRY_PAID_MODAL = '求人掲載のお申し込み画面です。<br>下記の事項をご確認下さい。'; // 求人掲載申込
export const TEXT_HTML_FAVORITE_JOB_MODAL = 'TEXT_HTML_FAVORITE_JOB_MODAL'; // ユーザー詳細-お気に入り(仕事）

// Text modal management by link
// https://github.com/monolithinter/APP-Admin/issues/950#issuecomment-1307228318
export const TEXT_HTML_MANAGEMENT_IMAGE_PROF_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_IMAGE_PROF_LINK_MODAL'; // プロフィール画像管理-画像承認
export const TEXT_HTML_MANAGEMENT_LIKE_LOG_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_LIKE_LOG_LINK_MODAL'; // いいね一覧-詳細
export const TEXT_HTML_MANAGEMENT_FAVORITE_LOG_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_FAVORITE_LOG_LINK_MODAL'; // お気に入り一覧-詳細
export const TEXT_HTML_MANAGEMENT_FAVORITE_JOB_LOG_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_FAVORITE_JOB_LOG_LINK_MODAL'; // お気に入り一覧 (仕事) - 詳細
export const TEXT_HTML_MANAGEMENT_FOOTPRINT_LOG_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_FOOTPRINT_LOG_LINK_MODAL'; // 足跡一覧-詳細
export const TEXT_HTML_MANAGEMENT_BLOCK_LOG_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_BLOCK_LOG_LINK_MODAL'; // ブロック一覧-詳細
export const TEXT_HTML_MANAGEMENT_CHAT_ROOM_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_CHAT_ROOM_LINK_MODAL'; // チャットルーム管理
export const TEXT_HTML_MANAGEMENT_CHAT_LOG_USER_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_CHAT_LOG_USER_LINK_MODAL'; // チャットログ-詳細
export const TEXT_HTML_MANAGEMENT_BLOG_LOG_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_BLOG_LOG_LINK_MODAL'; // ブログログ-詳細
export const TEXT_HTML_MANAGEMENT_LOG_SEND_NOTIFY_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_LOG_SEND_NOTIFY_LINK_MODAL'; // 一斉送信ログ-プッシュ-詳細
export const TEXT_HTML_MANAGEMENT_LOG_SEND_SMS_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_LOG_SEND_SMS_LINK_MODAL'; // 一斉送信ログ-SMS-詳細
export const TEXT_HTML_MANAGEMENT_LOG_SEND_CHAT_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_LOG_SEND_CHAT_LINK_MODAL'; // 一斉送信ログ-CHAT-詳細
export const TEXT_HTML_MANAGEMENT_LOG_SEND_MAIL_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_LOG_SEND_MAIL_LINK_MODAL'; // 一斉送信ログ-メール-詳細
export const TEXT_HTML_MANAGEMENT_TIMER_STANDBY_CHAT_ROM_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_TIMER_STANDBY_CHAT_ROM_LINK_MODAL'; // チャットルーム管理
export const TEXT_HTML_MANAGEMENT_TIMER_STANDBY_MAIL_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_TIMER_STANDBY_MAIL_LINK_MODAL'; // 予約一覧メール-詳細
export const TEXT_HTML_MANAGEMENT_TIMER_STANDBY_NOTIFY_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_TIMER_STANDBY_NOTIFY_LINK_MODAL'; // 予約一覧プッシュ-詳細
export const TEXT_HTML_MANAGEMENT_TIMER_STANDBY_SMS_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_TIMER_STANDBY_SMS_LINK_MODAL'; // 予約一覧SMS-詳細
export const TEXT_HTML_MANAGEMENT_TIMER_STANDBY_CHAT_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_TIMER_STANDBY_SMS_LINK_MODAL'; // 予約一覧SMS-詳細

export const TEXT_HTML_MANAGEMENT_USER_SHOW_IMAGE_MAIN_LINK_MODAL = `TEXT_HTML_MANAGEMENT_USER_SHOW_IMAGE_MAIN_LINK_MODAL`; // プロフィール画像管理-画像承認
export const TEXT_HTML_MANAGEMENT_USER_SHOW_IMAGE_SUB_LINK_MODAL = `TEXT_HTML_MANAGEMENT_USER_SHOW_IMAGE_SUB_LINK_MODAL`; // プロフィール画像管理-画像承認

export const TEXT_HTML_MANAGEMENT_USER_SEARCH_NOTIFY_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_USER_SEARCH_NOTIFY_LINK_MODAL'; // PUSH送信
export const TEXT_HTML_MANAGEMENT_USER_SEARCH_EMAIL_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_USER_SEARCH_EMAIL_LINK_MODAL'; // EMAIL送信
export const TEXT_HTML_MANAGEMENT_USER_SEARCH_EMAIL_LOOP_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_USER_SEARCH_EMAIL_LOOP_LINK_MODAL'; // EMAIL送信（スケジュール）
export const TEXT_HTML_MANAGEMENT_USER_SEARCH_SMS_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_USER_SEARCH_SMS_LINK_MODAL'; // SMS送信
export const TEXT_HTML_MANAGEMENT_USER_SEARCH_SMS_LOOP_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_USER_SEARCH_SMS_LOOP_LINK_MODAL'; // SMS送信（スケジュール）
export const TEXT_HTML_MANAGEMENT_USER_SEARCH_SCHEDULE_CHANGE_PROFILE_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_USER_SEARCH_SCHEDULE_CHANGE_PROFILE_LINK_MODAL '; // プロフィールの変更（スケジュール)
export const TEXT_HTML_MANAGEMENT_USER_EDIT_UPLOAD_IMAGE_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_USER_EDIT_UPLOAD_<br>IMAGE_LINK_MODAL'; // 画像を切り取るCropperDialog

// Text modal log by link
// https://github.com/monolithinter/APP-Admin/issues/950#issuecomment-1307228828
export const TEXT_HTML_MANAGEMENT_LOG_GET_POINT_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_LOG_GET_POINT_LINK_MODAL'; // チャットログ詳細
export const TEXT_HTML_MANAGEMENT_LOG_POINT_USAGE_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_LOG_POINT_USAGE_LINK_MODAL'; // 入金管理-検索条件入力詳細
export const TEXT_HTML_MANAGEMENT_LOG_TWILIO_HISTORY_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_LOG_TWILIO_HISTORY_LINK_MODAL'; // Twilioログ
export const TEXT_HTML_MANAGEMENT_LOG_CHANGE_PHONE_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_LOG_CHANGE_PHONE_LINK_MODAL'; // 電話番号変更ログ-詳細

// Text modal other by link
export const TEXT_HTML_MANAGEMENT_CONTENTS_POPUP_SETTING_ADD_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_CONTENTS_POPUP_SETTING_ADD_LINK_MODAL'; // ポップアップ設定－新規登録
export const TEXT_HTML_MANAGEMENT_CONTENTS_POPUP_SETTING_EDIT_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_CONTENTS_POPUP_SETTING_EDIT_LINK_MODAL'; // ポップアップ設定－編集
export const TEXT_HTML_MANAGEMENT_CONTENTS_CAMPAIGN_ADD_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_CONTENTS_CAMPAIGN_ADD_LINK_MODAL'; // キャンペーン設定－新規登録
export const TEXT_HTML_MANAGEMENT_CONTENTS_CAMPAIGN_EDIT_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_CONTENTS_CAMPAIGN_EDIT_LINK_MODAL'; // キャンペーン設定－編集
export const TEXT_HTML_MANAGEMENT_CONTACTS_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_CONTACTS_LINK_MODAL'; // 問合せ-詳細
export const TEXT_HTML_MANAGEMENT_REPORTS_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_REPORTS_LINK_MODAL'; // 報告-詳細
export const TEXT_HTML_MANAGEMENT_CHAT_SUPPORT_LOG_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_CHAT_SUPPORT_LOG_LINK_MODAL'; // サポートチャットログ-詳細
export const TEXT_HTML_MANAGEMENT_CHAT_AGENT_LOG_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_CHAT_AGENT_LOG_LINK_MODAL'; // サポートチャットログ-詳細
export const TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_UPLOAD_FILE_TO_WEBVIEW_LINK_MODAL'; // ファイルアップロード
export const TEXT_HTML_MANAGEMENT_CHAT_ADMIN_LOG_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_CHAT_ADMIN_LOG_LINK_MODAL'; // TEXT_HTML_MANAGEMENT_CHAT_ADMIN_LOG_LINK_MODAL


// Text modal job by link
export const TEXT_HTML_MANAGEMENT_JOB_JOB_LIST_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_JOB_JOB_LIST_LINK_MODAL'; // 応募者一覧
export const TEXT_HTML_MANAGEMENT_JOB_JOB_ENTRY_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_JOB_JOB_ENTRY_LINK_MODAL'; // 仕事応募一覧：job-entry
export const TEXT_HTML_MANAGEMENT_JOB_SHOW_IMAGE_MAIN_LINK_MODAL = `TEXT_HTML_MANAGEMENT_JOB_SHOW_IMAGE_MAIN_LINK_MODAL`; // title show image
export const TEXT_HTML_MANAGEMENT_JOB_SHOW_IMAGE_SUB_LINK_MODAL = `TEXT_HTML_MANAGEMENT_JOB_SHOW_IMAGE_SUB_LINK_MODAL`; // title show image
export const TEXT_HTML_MANAGEMENT_JOB_UPLOAD_IMAGE_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_JOB_<br>UPLOAD_IMAGE_LINK_MODAL'; // 画像を切り取るCropperDialog

export const TEXT_HTML_MANAGEMENT_AGENT_SHOW_IMAGE_MAIN_LINK_MODAL = `TEXT_HTML_MANAGEMENT_AGENT_SHOW_IMAGE_MAIN_LINK_MODAL`; // プロフィール画像管理-画像承認edit
export const TEXT_HTML_MANAGEMENT_AGENT_SHOW_IMAGE_SUB_LINK_MODAL = `TEXT_HTML_MANAGEMENT_AGENT_SHOW_IMAGE_SUB_LINK_MODAL`; // プロフィール画像管理-画像承認edit
export const TEXT_HTML_MANAGEMENT_AGENT_UPLOAD_IMAGE_LINK_MODAL = 'TEXT_HTML_MANAGEMENT<br>_AGENT_UPLOAD_IMAGE_LINK_MODAL'; // 画像を切り取るCropperDialog

export const TEXT_HTML_MANAGEMENT_OPE_SHOW_IMAGE_MAIN_LINK_MODAL = `TEXT_HTML_MANAGEMENT_OPE_SHOW_IMAGE_MAIN_LINK_MODAL`;
export const TEXT_HTML_MANAGEMENT_OPE_SHOW_IMAGE_SUB_LINK_MODAL = `TEXT_HTML_MANAGEMENT_OPE_SHOW_IMAGE_SUB_LINK_MODAL`;
export const TEXT_HTML_MANAGEMENT_OPE_UPLOAD_IMAGE_LINK_MODAL = 'TEXT_HTML_MANAGEMENT_OPE_UPLOAD_IMAGE_LINK_MODAL';

export const TEXT_HTML_ADMIN_OPE_WORK_PAGE_INDIVIDUAL_UPLOAD_IMAGE_LINK_MODAL = 'TEXT_HTML_<br>ADMIN_OPE_WORK_PAGE_INDIVIDUAL_UPLOAD_IMAGE_LINK_MODAL'; // 画像を切り取るCropperDialog
export const TEXT_HTML_CHAT_SUPPORT_INDIVIDUAL_UPLOAD_IMAGE_LINK_MODAL = 'TEXT_HTML_CHAT_SUPPORT_<br>INDIVIDUAL_UPLOAD_IMAGE_LINK_MODAL'; // 画像を切り取るCropperDialog
export const TEXT_HTML_CHAT_AGENT_INDIVIDUAL_UPLOAD_IMAGE_LINK_MODAL = 'TEXT_HTML_CHAT_AGENT_<br>INDIVIDUAL_UPLOAD_IMAGE_LINK_MODAL'; // 画像を切り取るCropperDialog

// Upload Image contents web 
export const UPLOAD_FILE_IMAGE_TO_CONTENTS_IMAGE_WEB = 'UPLOAD_FILE_IMAGE_TO_CONTENTS_IMAGE_WEB';
