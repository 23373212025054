import React from 'react';
import { Box, TextField } from '@material-ui/core';

export default function ScheduleDynamicTimerInput({
  globalOptionState,
  label,
  radioValue,
  inputValue,
  onChangeRadio,
  onChangeInput,
}) {
  return (
    <>
      <div className="mt-3 mb-1 timer-container">
        <label className="radio-option">
          <input
            checked={+globalOptionState === radioValue}
            type="radio"
            onChange={onChangeRadio}
            value={radioValue}
          />
          <span>{label}</span>
        </label>
      </div>
      <Box>
        <TextField
          onChange={onChangeInput}
          value={+globalOptionState === radioValue ? inputValue : ''}
          disabled={+globalOptionState !== radioValue}
        />
      </Box>
    </>
  );
}
