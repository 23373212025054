import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getEnvSysCms,
  getEnvSysCmsAll,
  updateEnvSysCms,
} from 'Api/EnvSysCmsApi';
import {
  getEnvSysCmsSuccess,
  getEnvSysCmsFail,
  getEnvSysCmsAllSuccess,
  getEnvSysCmsAllFail,
  updateEnvSysCmsSuccess,
  updateEnvSysCmsFail,
} from 'Redux/Actions/envSysCms';
import EnvSysCms from 'Redux/Types/envSysCms';

function* getEnvSysCmsSaga(action) {
  try {
    const response = yield call(getEnvSysCms, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(getEnvSysCmsSuccess({ data: items, totalRow }));
    }
  } catch (error) {
    yield put(getEnvSysCmsFail(error));
  }
}

function* getEnvSysCmsAllSaga(action) {
  try {
    const response = yield call(getEnvSysCmsAll, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      yield put(getEnvSysCmsAllSuccess({ data: items }));
    }
  } catch (error) {
    yield put(getEnvSysCmsAllFail(error));
  }
}

function* updateEnvSysCmsSaga(action) {
  try {
    const response = yield call(updateEnvSysCms, action.payload);
    const { data } = response;
    if (data.success) {
      const items = data.data;
      yield put(updateEnvSysCmsSuccess({ data: items }));
    }
  } catch (error) {
    yield put(updateEnvSysCmsFail(error));
  }
}

export default function* envSysCmsSaga() {
  yield takeLatest(EnvSysCms.GET_ENV_SYS_CMS, getEnvSysCmsSaga);
  yield takeLatest(EnvSysCms.GET_ENV_SYS_CMS_ALL, getEnvSysCmsAllSaga);
  yield takeLatest(EnvSysCms.UPDATE_ENV_SYS_CMS, updateEnvSysCmsSaga);
}
