import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Util from '../../Util/Util';
import NoPhoto from '../../assets/image/no_user_photo.png';
import { Link } from 'react-router-dom';
import SortArrows from '../../Components/SortArrows';
import { Card, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import PointPaymentDetail from './PointPaymentDetail';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

function PointPaymentList(props) {
const {
  reload,
  point,
  pageIndex = 1,
  pageSize = 2,
  filter = { sort: {} },
  onSort = (sortByKey) => { },
  labelDb
} = props;

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  const [detail, setDetail] = useState();

  const showDetail = (data) => {
    setDetail(data);
  };

  const hideDetail = () => {
    setDetail(null);
  };

  return (
    <div>
      {detail && (
        <PointPaymentDetail detail={detail} onClose={hideDetail} reload={reload} type="detail_list" />
      )}
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="user.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell className="text-center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="user.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="性別"
                    name="user.gender"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="入金種類"
                    name="paymentLog.paymentType"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="入金タイプ"
                    name="paymentLog.payingType"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="金額"
                    name="paymentLog.price"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="購入期間"
                    name="paymentLog.month"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="取得ポイント"
                    name="paymentLog.pointGet"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="ボーナスポイント"
                    name="paymentLog.pointBonus"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="決済日時"
                    name="paymentLog.createdDate"
                    onClick={onSort}
                    defaultName="paymentLog.createdDate"
                  />
                </TableCell>
                <TableCell>メモ</TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {point.map((x, index) => (
                <TableRow key={x.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{x.user ? x.user.phoneNumber : null}</span>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [x.user?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(x.user.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          x?.user?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          x.user !== null && x.user.avatar !== null
                            ? x.user.avatar.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{x.user ? x.user.nickName : null}</TableCell>
                  <TableCell>
                    {x.user ? Util.genderIdToText(x.user.gender) : ''}
                  </TableCell>
                  <TableCell>
                    {Util.paymentTypeIdToText(x.paymentType)}
                  </TableCell>
                  <TableCell>{Util.payingTypeIdToText(x.payingType)}</TableCell>
                  <TableCell>{Util.separator(x.price)}円</TableCell>
                  <TableCell>{Util.separator(x.month)}</TableCell>
                  <TableCell>{Util.separator(x.pointGet)}</TableCell>
                  <TableCell>{Util.separator(x.pointBonus)}</TableCell>
                  <TableCell>
                    {Util.timestampToDate(x.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: 50,
                    }}
                  >
                    {x.memo}
                  </TableCell>
                  <TableCell className="text-center" align="center">
                    <Link onClick={() => showDetail(x)} className="d-color-link">表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default PointPaymentList;
