import { call, put, takeLatest } from 'redux-saga/effects';
import {
  estimateScheduleChangeProfileReceiversFail,
  estimateScheduleChangeProfileReceiversSuccess,
  loadScheduleChangeProfileFail,
  loadScheduleChangeProfileSuccess,
} from '../Actions/scheduleChangeProfile';
import ScheduleChangeProfile from 'Redux/Types/scheduleChangeProfile';
import { getAllScheduleChangeProfile } from 'Api/scheduleAPI';

function* handleLoadScheduleChangeProfile(action) {
  try {
    const res = yield call(getAllScheduleChangeProfile, action.payload);
    const { data } = res;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(loadScheduleChangeProfileSuccess({ items, totalRow }));
    }
  } catch (error) {
    yield put(loadScheduleChangeProfileFail(error));
  }
}

function* handleEstimatingScheduleChangeProfileReceivers(action) {
  try {
    const res = yield call(getAllScheduleChangeProfile, action.payload);
    const { data } = res;
    if (data.success) {
      const items = data.data;
      const totalRow = data.totalRow;
      yield put(
        estimateScheduleChangeProfileReceiversSuccess({ items, totalRow }),
      );
    }
  } catch (error) {
    yield put(estimateScheduleChangeProfileReceiversFail(error));
  }
}

export default function* scheduleChangeProfileSaga() {
  yield takeLatest(
    ScheduleChangeProfile.LOAD_SCHEDULE_CHANGE_PROFILE,
    handleLoadScheduleChangeProfile,
  );
  yield takeLatest(
    ScheduleChangeProfile.ESTIMATE_SCHEDULE_CHANGE_PROFILE_RECEIVERS,
    handleEstimatingScheduleChangeProfileReceivers,
  );
}
