import {
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Util from '../../Util/Util';
import { Link } from 'react-router-dom';
import CircularLoading from '../../Components/CircularLoading';
import SortArrows from '../../Components/SortArrows';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { loadMemberAdmin } from '../../Redux/Actions/memberAdmin';
import NoAvatar from '../../assets/image/sys_photo.png';
import Pagination from '../../Components/Pagination';
import LoadingBar from '../../Components/LoadingBar';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const pageSize = 50;

function MemberAdminList() {
  const loading = useSelector((state) => state.memberAdmin.loading);
  const memberAdmins = useSelector((state) => state.memberAdmin.data);
  const totalRow = useSelector((state) => state.memberAdmin.totalRow);

  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sortBy: 'admin.register_date',
    orderBy: 'DESC',
    sort: { 'admin.register_date': 'DESC' },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadMemberAdmin({ ...filter, pageIndex: 1, pageSize }));
  }, [dispatch, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadMemberAdmin({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    setFilter({
      ...filter,
      sortBy: sortByKey,
      orderBy: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      sort: {
        [sortByKey]: filter.orderBy === 'DESC' ? 'ASC' : 'DESC',
      },
    });
    setPageIndex(1);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('memberAdminId', id);
  };

  return (
    <div style={{ marginTop: 10 }}>
      <LoadingBar show={loading} />
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          {loading ? (
            <CircularLoading />
          ) : (
            <Table padding="checkbox" className="table mb-0">
              <TableBody>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell className="text-center">画像</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="管理者名"
                      name="admin.nickName"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="メールアドレス"
                      name="admin.email_address_main"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="会員種類 （0=システム 1=上級)"
                      name="admin.member_type"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="登録日"
                      name="admin.register_date"
                      onClick={onSort}
                    />
                  </TableCell>
                </TableRow>

                {(memberAdmins || []).map((admin, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {(pageIndex - 1) * pageSize + index + 1}
                    </TableCell>
                    <TableCell className="text-center">
                      <div>
                        <Link
                          to={Util.applyDynamicRouteParams(
                            ROUTES_CLIENT_DEFINED.ADMINISTATOR.DETAIL,
                            [admin.id],
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => saveLocalStore(admin.id)}
                        >
                          <img
                            className="imgRound"
                            src={admin?.avatar ? admin?.avatar?.url : NoAvatar}
                            alt=""
                          />
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell>{admin.nickName}</TableCell>
                    <TableCell>{admin.emailAddressMain}</TableCell>
                    <TableCell>{admin?.memberType}</TableCell>
                    <TableCell>
                      <time>
                        {Util.timestampToDate(
                          admin.registerDate,
                          'YYYY/MM/DD HH:mm',
                        )}
                      </time>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default MemberAdminList;
