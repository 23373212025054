import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Pagination from '../../Components/Pagination';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { loadTwilio } from '../../Redux/Actions/twilio';
import NoPhoto from '../../assets/image/no_user_photo.png';
import Util from '../../Util/Util';
import SortArrows from '../../Components/SortArrows';
import TwilioSearchBox from './TwilioSearchBox';
import DetailModal from './DetailModal';
import {
  convertEnvInfo,
  convertTwilioReceiverStatusTypeText,
  convertTwilioResultTypeText,
  convertTwilioStatusTypeText,
} from '../../Util/utils';
import UserDelete from '../../assets/image/delete_user.png';
import { MEMBER_USER_STATUS } from '../../Common/constant';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const pageSize = 50;

function TwilioManagement() {
  const [filter, setFilter] = useState({
    sort: { 'twiliohistory.createdDate': 'DESC' },
  });
  const [pageIndex, setPageIndex] = useState(1);
  const [detail, setDetail] = useState();
  const twilio = useSelector((state) => state.twilio.items);
  const totalRow = useSelector((state) => state.twilio.totalRow);
  const envInfo = useSelector((state) => state.envInfo.data);
  const [labelDb, setLabelDb] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadTwilio({ pageIndex, pageSize, ...filter }));
  }, [dispatch, pageIndex, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadTwilio({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {}; // to convert to client able to read
    if (filter.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    if (filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };
  const handleSearch = (searchFilter) => {
    setFilter({ ...searchFilter, sort: filter.sort });
    setPageIndex(1);
  };


  const showDetail = (data) => {
    setDetail(data);
  };

  const hideDetail = () => {
    setDetail(null);
  };

  const reload = () => {
    dispatch(loadTwilio({ pageIndex, pageSize }));
  };

  const onClear = () => setFilter({ sort: { 'twiliohistory.createdDate': 'DESC' } });

  return (
    <>
      {detail && (
        <DetailModal detail={detail} onClose={hideDetail} reload={reload} labelDb={labelDb} />
      )}
      <TwilioSearchBox onSubmit={handleSearch} onClear={onClear} labelDb={labelDb} />
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="mt-3 scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号（送信）"
                    name="sender.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell className="text-center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="sender.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>性別</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号 (受信)"
                    name="receiver.phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell className="text-center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="receiver.nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>オペNum</TableCell>
                <TableCell>会社Num</TableCell>
                <TableCell>送信電話</TableCell>
                <TableCell>受信電話</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="Twilio番号"
                    name="twiliohistory.twilioCallNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="発信Status"
                    name="twiliohistory.status"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>コール結果</TableCell>
                <TableCell>受信者応答</TableCell>
                <TableCell>通話時間</TableCell>
                <TableCell>Pレート/分</TableCell>
                <TableCell>一時預かりP</TableCell>
                <TableCell>利用P</TableCell>
                <TableCell>戻しP</TableCell>
                <TableCell>会話開始時間</TableCell>
                <TableCell>会話終了時間</TableCell>
                <TableCell>Updated Date</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="Created Date"
                    name="twiliohistory.createdDate"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {twilio.map((x, index) => (
                <TableRow key={x.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{x?.sender?.phoneNumber !== null ? x?.sender?.phoneNumber : null}</span>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [x?.sender?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(x?.sender?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          x?.sender?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          x?.sender?.avatar && x?.sender?.avatar?.url
                            ? x?.sender?.avatar?.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>
                    {x?.sender?.nickName}
                  </TableCell>
                  <TableCell>{Util.genderIdToText(x?.sender?.gender)}</TableCell>
                  <TableCell>
                    <span>
                      {x?.receiver?.phoneNumber}
                    </span>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.USER.DETAIL,
                        [x?.receiver?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => saveLocalStore(x?.receiver?.id)}
                    >
                      <img
                        className="imgRound"
                        src={
                          x?.receiver?.memberStatus === MEMBER_USER_STATUS.IN_ACTIVE ? UserDelete :
                          x?.receiver?.avatar && x?.receiver?.avatar?.url
                            ? x?.receiver?.avatar?.url
                            : NoPhoto
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>
                    {x?.receiver?.nickName}
                  </TableCell>
                  <TableCell>{x?.receiver?.operator?.operatorNumber}</TableCell>
                  <TableCell>{x?.receiver?.agent?.agentNumber}</TableCell>
                  <TableCell>{x?.senderPhone}</TableCell>
                  <TableCell>{x?.receiverPhone}</TableCell>
                  <TableCell>{x?.twilioCallNumber}</TableCell>
                  <TableCell>
                    {convertTwilioStatusTypeText(x?.status)}
                  </TableCell>
                  <TableCell>
                    {convertTwilioResultTypeText(x?.twilioResult)}
                  </TableCell>
                  <TableCell>
                    {convertTwilioReceiverStatusTypeText(x?.receiverStatus)}
                  </TableCell>
                  <TableCell>
                    {x.durationCallTime !== null ? x.durationCallTime : null}
                  </TableCell>
                  <TableCell>
                    {x?.pointRate && Util.separator(x?.pointRate)}
                  </TableCell>
                  <TableCell>
                    {x?.depositPoint && Util.separator(x?.depositPoint)}
                  </TableCell>
                  <TableCell>
                    {x?.usagePoint && Util.separator(x?.usagePoint)}
                  </TableCell>
                  <TableCell>
                    {x?.returnPoint && Util.separator(x?.returnPoint)}
                  </TableCell>
                  <TableCell>
                    {Util.timestampToDate(x.startTime, 'YYYY/MM/DD HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {Util.timestampToDate(x.endTime, 'YYYY/MM/DD HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {Util.timestampToDate(x.updatedDate, 'YYYY/MM/DD HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    {Util.timestampToDate(x.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                  </TableCell>
                  <TableCell className="text-center" align="center">
                    <Link onClick={() => showDetail(x)} className="d-color-link">表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
        <center>
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalRow={totalRow}
            onPageChange={handlePageChange}
          />
        </center>
      </MuiThemeProvider>
    </>
  );
}

export default TwilioManagement;
