import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Util from '../../Util/Util';
import NoPhotoAgent from '../../assets/image/no_agent_photo.png';
import { Link } from 'react-router-dom';
import SortArrows from '../../Components/SortArrows';
import { Card, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import PointPaymentJobDetail from './PointPaymentJobDetail';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

function PointPaymentJobList(props) {
  const {
    reload,
    paymentJob,
    pageIndex = 1,
    pageSize = 2,
    filter = { sort: {} },
    onSort = (sortByKey) => {
    },
    labelDb,
  } = props;

  const [detail, setDetail] = useState();

  const showDetail = (data) => {
    setDetail(data);
  };

  const hideDetail = () => {
    setDetail(null);
  };

  return (
    <div>
      {detail && (
        <PointPaymentJobDetail detail={detail} onClose={hideDetail} reload={reload} type="detail_list" />
      )}
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="電話番号"
                    name="phoneNumber"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell className="text-center">画像</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text={labelDb?.label_nickName?.content}
                    name="nickName"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="入金種類"
                    name="paymentType"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="入金タイプ"
                    name="payingType"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="金額"
                    name="price"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="購入期間"
                    name="month"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="入金前残高"
                    name="balancePaymentBefore"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="入金後残高"
                    name="balancePaymentAfter"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="決済日時"
                    name="createdDate"
                    onClick={onSort}
                    defaultName="createdDate"
                  />
                </TableCell>
                <TableCell>メモ</TableCell>
                <TableCell align="center">ボタン</TableCell>
              </TableRow>

              {paymentJob.map((x, index) => (
                <TableRow key={x.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>
                  <TableCell>
                    <span>{x?.agent ? x.agent.phoneNumber : null}</span>
                  </TableCell>
                  <TableCell className="text-center">
                    <Link
                      to={Util.applyDynamicRouteParams(
                        ROUTES_CLIENT_DEFINED.AGENT.DETAIL,
                        [x.agent?.id],
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="imgRound"
                        src={
                          x?.agent !== null && x?.agent?.avatar !== null
                            ? x?.agent?.avatar?.url
                            : NoPhotoAgent
                        }
                        alt=""
                      />
                    </Link>
                  </TableCell>
                  <TableCell>{x.agent ? x.agent.nickName : null}</TableCell>
                  <TableCell>
                    {Util.paymentTypeIdToText(x.paymentType)}
                  </TableCell>
                  <TableCell>{Util.payingTypeIdToText(x.payingType)}</TableCell>
                  <TableCell>{Util.separator(x.price)}円</TableCell>
                  <TableCell>{Util.separator(x.month)}</TableCell>
                  <TableCell>{Util.separator(x?.balancePaymentBefore)}円</TableCell>
                  <TableCell>{Util.separator(x?.balancePaymentAfter)}円</TableCell>
                  <TableCell>
                    {Util.timestampToDate(x.createdDate, 'YYYY/MM/DD HH:mm:ss')}
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      maxWidth: 50,
                    }}
                  >
                    {x.memo}
                  </TableCell>
                  <TableCell className="text-center" align="center">
                    <Link onClick={() => showDetail(x)} className="d-color-link">表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default PointPaymentJobList;
