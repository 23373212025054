import React, { lazy, Suspense } from 'react';
import { PUBLIC_ROUTES_COMPONENTS_LIST } from 'Config/routesList';
import LoadingBar from 'Components/LoadingBar';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute } from 'Components/PrivateRoute';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

export default function PublicRoutes() {
  return (
    <Suspense fallback={<LoadingBar show={true} />}>
      <Switch>
        {PUBLIC_ROUTES_COMPONENTS_LIST.map((item, index) => {
          return (
            <Route
              exact
              key={index}
              path={item.path}
              component={item.component}
            />
          );
        })}
        <PrivateRoute
          path={ROUTES_CLIENT_DEFINED.HOME.INDEX}
          component={lazy(() => import('Page/Main.js'))}
        />
      </Switch>
    </Suspense>
  );
}
