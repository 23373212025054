import React, { useEffect, useState } from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import Util from '../../../Util/Util';
import { getUserBlocks } from '../../../Api/blockAPI';
import { themeStyleNormal2Modal } from '../theme_modal';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function BlockModal({ visible, onClose, userId, labelDb }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [blocks, setBlocks] = useState([]);

  useEffect(() => {
    handleChange(null, 0);
  }, []);

  const getBlocks = async (type) => {
    const { data: blockDatas } = await getUserBlocks({ type, userId });
    blockDatas.data && setBlocks(blockDatas.data);
  };

  const handleChange = (event, newValue) => {
    getBlocks(Number(newValue) + 1);
    setValue(newValue);
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('userId', id);
  };

  return (
    <MuiThemeProvider theme={themeStyleNormal2Modal}>
      <div style={{ position: 'relative' }}>
        <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={visible}
          onClose={onClose}
          aria-labelledby="max-width-dialog-title"
          className={'d-modal-wrapper'}
        >
          <DialogTitle id="max-width-dialog-title" className={'d-title-modal-custom'}>
            ユーザー詳細-ブロック
          </DialogTitle>
          <Button
            style={{ position: 'absolute', top: 10, right: 0 }}
            onClick={onClose}
          >
            <CloseIcon color="primary" />
          </Button>
          <DialogContent className={'table-modal-wrapper'}>
            <Card>
              <Paper square className={classes.root}>
                <Tabs
                  value={value}
                  variant="fullWidth"
                  indicatorColor="none"
                  aria-label="disabled tabs example"
                  onChange={handleChange}
                >
                  <Tab className="async-blue" label="お相手から" />
                  <Tab className="async-blue" label="あなたから" />
                </Tabs>
              </Paper>
              {value === 0 ? (
                <Table padding="checkbox" className="d-table-modal mb-0">
                  <TableBody>
                    <TableRow>
                      <TableCell className="d-width-no">No</TableCell>
                      <TableCell className="d-width-phone">電話番号</TableCell>
                      <TableCell align="center">画像</TableCell>
                      <TableCell>{labelDb?.label_nickName?.content}</TableCell>
                      <TableCell>性別</TableCell>
                      <TableCell>登録日時</TableCell>
                    </TableRow>
                    {blocks &&
                    blocks.map((block, index) => (
                      <TableRow key={index + 1}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {block?.blocker?.phoneNumber}
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            to={Util.applyDynamicRouteParams(
                              ROUTES_CLIENT_DEFINED.USER.DETAIL,
                              [block?.blocker?.id],
                            )}
                            favoriter="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                              saveLocalStore(block?.blockerId)
                            }
                            style={{ paddingRight: 10 }}
                          >
                            <img
                              className="object-fit-cover"
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: '50%',
                              }}
                              src={block?.blocker?.avatar?.url ?? NoPhoto}
                              alt=""
                            />
                          </Link>
                        </TableCell>
                        <TableCell>
                          {block?.blocker?.nickName}
                        </TableCell>
                        <TableCell>
                          {Util.genderIdToText(block?.blocker?.gender)}
                        </TableCell>
                        <TableCell className="text-muted">
                          {Util.timestampToDate(
                            block.createdDate,
                            'YYYY/MM/DD HH:mm:ss',
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Table padding="checkbox" className="d-table-modal mb-0">
                  <TableBody>
                    <TableRow>
                      <TableCell className="d-width-no">No</TableCell>
                      <TableCell className="d-width-phone">電話番号</TableCell>
                      <TableCell align="center">画像</TableCell>
                      <TableCell>{labelDb?.label_nickName?.content}</TableCell>
                      <TableCell>性別</TableCell>
                      <TableCell>登録日時</TableCell>
                    </TableRow>
                    {blocks &&
                    blocks.map((block, index) => (
                      <TableRow key={index + 1}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {block?.target?.phoneNumber}
                        </TableCell>
                        <TableCell align="center">
                          <Link
                            to={Util.applyDynamicRouteParams(
                              ROUTES_CLIENT_DEFINED.USER.DETAIL,
                              [block?.target?.id],
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => saveLocalStore(block.targetId)}
                            style={{ paddingRight: 10 }}
                          >
                            <img
                              className="object-fit-cover"
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: '50%',
                              }}
                              src={block?.target?.avatar?.url ?? NoPhoto}
                              alt=""
                            />
                          </Link>
                        </TableCell>
                        <TableCell>
                          {block?.target?.nickName}
                        </TableCell>
                        <TableCell>
                          {Util.genderIdToText(block?.target?.gender)}
                        </TableCell>
                        <TableCell className="text-muted">
                          {Util.timestampToDate(
                            block.createdDate,
                            'YYYY/MM/DD HH:mm:ss',
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Card>
          </DialogContent>
        </Dialog>
      </div>
    </MuiThemeProvider>
  );
}

export default BlockModal;
