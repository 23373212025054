import { createAction } from 'redux-actions';
import envSysCms from '../Types/envSysCms';

export const getEnvSysCms = createAction(envSysCms.GET_ENV_SYS_CMS);
export const getEnvSysCmsSuccess = createAction(
  envSysCms.GET_ENV_SYS_CMS_SUCCESS,
);
export const getEnvSysCmsFail = createAction(envSysCms.GET_ENV_SYS_CMS_FAILURE);

export const getEnvSysCmsAll = createAction(envSysCms.GET_ENV_SYS_CMS_ALL);
export const getEnvSysCmsAllSuccess = createAction(
  envSysCms.GET_ENV_SYS_CMS_ALL_SUCCESS,
);
export const getEnvSysCmsAllFail = createAction(
  envSysCms.GET_ENV_SYS_CMS_ALL_FAILURE,
);

export const updateEnvSysCms = createAction(envSysCms.UPDATE_ENV_SYS_CMS);
export const updateEnvSysCmsSuccess = createAction(
  envSysCms.UPDATE_ENV_SYS_CMS_SUCCESS,
);
export const updateEnvSysCmsFail = createAction(
  envSysCms.UPDATE_ENV_SYS_CMS_FAILURE,
);

