import React, { useEffect, useState } from 'react';
import { Card, Col, Label, Row } from 'reactstrap';
import { createMuiTheme, makeStyles, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LoadingBar from '../../../Components/LoadingBar';
import { FormControl, FormGroup, FormLabel, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { loadAgents } from '../../../Redux/Actions/agents';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import JobRegisterConfirm from './JobRegisterConfirm';
import { createJob } from '../../../Api/jobApi';
import { loadAllAreaJob, loadStateJob } from '../../../Redux/Actions/nameStateJob';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import { convertEnvInfo, hiddenStatusRequired, jobPublishingTypeRequired } from '../../../Util/utils';
import DatePicker from 'react-datepicker';
import RadioGroup from '../../../Components/RadioGroup';
import { HIDDEN_TYPE, JOB_PUBLISHING_TYPE } from '../../../Common/constant';
import orderBy from 'lodash/orderBy';
import { getAllTagJobs } from '../../../Api/tag';
import CheckBoxItemModal from '../../../Components/CheckBoxItemModal';
import { loadResourceItemJob } from '../../../Redux/Actions/resourceItemJob';
import { showResourceItemJobForm } from '../resource-item';
import moment from 'moment';
import { ROUTES_API_DEFINED } from 'Config/listConstants';


const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
      },
      shrink: {
        transform: 'none',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
      },
    },
    MuiFormLabel: {
      root: {
        display: 'flex !important',
        fontSize: '16px',
      },
      asterisk: {
        color: 'red',
        order: '-1',
        marginLeft: '-4px',
      },
      required: {
        display: 'flex',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    marginTop: '6px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function OpeJobRegister(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const locationParam = useLocation();
  const [isOpenSelect, setIsOpenSelect] = useState({ operatorId: false, agentId: false });
  
  const loadingState = useSelector((state) => state.nameStateJob.loading);
  useSelector((state) => state.nameStateJob.loadingChild);
  const loadingAgents = useSelector((state) => state.agents.loading);
  const nameStateJob = useSelector((state) => state.nameStateJob.data);
  const areas = useSelector((state) => state.nameStateJob.dataChild);
  const agents = useSelector((state) => state.agents.items);
  const resource = useSelector((state) => state.resourceItemJob.data);
  const loadingResource = useSelector((state) => state.resourceItemJob.loading);
  
  const [agentId, setAgentId] = useState(locationParam?.state?.agentId);
  const [stateText, setStateText] = useState(null);
  const [state, setState] = useState(null);
  const [areaText, setAreaText] = useState(null);
  const [area, setArea] = useState(null);
  
  const [displaySetting, setDisplaySetting] = useState(HIDDEN_TYPE.NO_HIDDEN);
  const [draft, setDraft] = useState(JOB_PUBLISHING_TYPE.DRAFT);
  const [title, setTitle] = useState(null);
  const [salaryDetail, setSalaryDetail] = useState(null);
  const [workHours, setWorkHours] = useState(null);
  const [workDetail, setWorkDetail] = useState(null);
  const [location, setLocation] = useState(null);
  // const [startTime, setStartTime] = useState(null);
  // const [endTime, setEndTime] = useState(null);
  const [selectedTagJobList, setSelectedTagJobList] = useState([]);
  const [tagJobs, setTagJobs] = useState([]);
  const [visitTagShow, setVisitTagShow] = useState(false);
  const [editingItemJob, setEditingItemJob] = useState({});
  const [minimumSalary, setMinimumSalary] = useState(null);
  const [maximumSalary, setMaximumSalary] = useState(null);
  const [colorLabel, setColorLabel] = useState('')
  const [colorLabelConfirm, setColorLabelConfirm] = useState('')
  const envInfo = useSelector((state) => state.envInfo.data);

  useEffect(() => {
    dispatch(loadResourceItemJob({}));
    dispatch(loadStateJob());
    dispatch(loadAllAreaJob());
    dispatch(loadAgents({ pageIndex: 1, pageSize: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    setColorLabel(convertEnvInfo(envInfo, ['admin_lable_form_2'], true)?.admin_lable_form_2?.content)
    setColorLabelConfirm(convertEnvInfo(envInfo, ['admin_lable_form_1'], true)?.admin_lable_form_1?.content)
  }, [envInfo]);


  const getAllTagJobMembers = async () => {
    getAllTagJobs().then(res => {
      if (res.data) {
        setTagJobs((res.data?.data ?? []));
      }
    }).catch(err => console.error('Error!', err));
  };

  useEffect(() => {
    getAllTagJobMembers();
  }, []);

  const onChangeAttribute = (e) => {
    let val = e.target.value;
    setEditingItemJob({
      ...editingItemJob,
      [e.target.name]: {
        label: val.label,
        value: val.value,
        item: val.item,
        labelRoot: e.currentTarget.getAttribute('data-label-root'),
      },
    });
  };

  const handleChangeTag = (event) => {
    const tag = event.target.value;
    const indexFind = selectedTagJobList.indexOf(tag);
    if (indexFind !== -1) {
      selectedTagJobList.splice(indexFind, 1);
    } else {
      selectedTagJobList.push(tag);
    }

    setSelectedTagJobList([...selectedTagJobList]);
  };

  const convertChangeTagMemberList = () => {
    return tagJobs.filter(ele => selectedTagJobList.includes(ele.id));
  };

  const redirectCancelCreateJob = () => {
    if(locationParam?.state?.prevPath){
     return  history.push(`${locationParam?.state?.prevPath}`);
    }
    history.push(ROUTES_API_DEFINED.JOB.LIST);
  };

  const handleChangeState = (event) => {
    setStateText(event.currentTarget.textContent);
    setState(event.target.value);
    dispatch(loadAllAreaJob(event.target.value));
    setArea(null);
  };

  const handleChangeArea = (event) => {
    setAreaText(event.currentTarget.textContent);
    setArea(event.target.value);
  };

  const onChangeAgentOrOpeId = (e) => {
    const { name, value } = e.target;

    setAgentId(value);
  };

  const onCheckUserStatus = (name) => () => {
    setIsOpenSelect({ ...isOpenSelect, [name]: !isOpenSelect[name] });
  };

  const handleSubmitConfirm = () => {
    let isNotError = false;
    if (!title) {
      toast.error('タイトルを入力してください。');
      isNotError = true;
    }
    if (title?.length > 20) {
      toast.error('タイトルは20文字以内で登録して下さい');
      isNotError = true;
    }
    if (!state) {
      toast.error('都道府県を選択してください!');
      isNotError = true;
    }
    if (displaySetting === null) {
      toast.error('表示設定を選択してください。');
      isNotError = true;
    }
    if (draft === null) {
      toast.error('掲載状況を選択してください。');
      isNotError = true;
    }
    // if (!startTime) {
    //   toast.error('掲載開始日を選択してください。');
    //   isNotError = true;
    // }
    // if (!endTime) {
    //   toast.error('掲載終了日を選択してください。');
    //   isNotError = true;
    // }
    // if (startTime && endTime && moment(startTime).format('YYYYMMDD') > moment(endTime).format('YYYYMMDD')) {
    //   toast.error('掲載開始日は掲載終了日の前日をご記入ください。');
    //   isNotError = true;
    // }
    // if (!agentId) {
    //   toast.error('会社Numberを選択してください。');
    //   isNotError = true;
    // }
    if (!salaryDetail) {
      toast.error('給与詳細を記入してください');
      isNotError = true;
    }
    if (!workHours) {
      toast.error('勤務時間を記入してください');
      isNotError = true;
    }
    if (!workDetail) {
      toast.error('仕事内容を記入してください');
      isNotError = true;
    }
    if (!location) {
      toast.error('勤務地を記入してください');
      isNotError = true;
    }
    if(minimumSalary && maximumSalary && +minimumSalary > +maximumSalary){
      toast.error('給与報酬幅は低い金額～高い金額で入力してください');
      isNotError = true;
    }

    if (!isNotError) {
      setOpen(true);
    }
  };

  const handleCreateJob = () => {
    const data = {
      stateId: state,
      areaId: area,
      agentId,
      title,
      salaryDetail,
      workHours,
      workDetail,
      location,
      ...displaySetting && { displaySetting: +displaySetting },
      // ...startTime && { startTime: moment(startTime).startOf('day').valueOf() },
      // ...endTime && { endTime: moment(endTime).endOf('day').valueOf() },
      draft,
      ...selectedTagJobList && selectedTagJobList.length && { tagJobIds: selectedTagJobList },
      itemJobs: Object.values(editingItemJob).map(ele => ({ [ele.item]: ele.value })),
      minimumSalary: minimumSalary,
      maximumSalary: maximumSalary,
    };

    if (data.agentId === '') {
      delete data.agentId;
    }

    createJob(data)
      .then(response => {
        const { data } = response;
        if (data?.success) {
          history.push(locationParam?.state?.prevPath ? locationParam?.state?.prevPath : '/job/job-list');
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });

  };

  // const handleDate = (date, name) => {
  //   const dateStr = date ? new Date(date).getTime() : null;
  //   if (name === 'start_time') {
  //     setStartTime(dateStr);
  //   } else {
  //     setEndTime(dateStr);
  //   }
  // };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingBar show={loadingState} />
      <MuiThemeProvider theme={customTheme}>
        <div className="align">
          <Button
            className="btn-custom btn-red"
            variant="contained"
            onClick={redirectCancelCreateJob}
          >
            閉じる
          </Button>
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleSubmitConfirm}
          >
            登録
          </Button>
        </div>
        <Col>
          <Row>
            <Col xs={12}>
              <Card body className="mb-4">
                <span>仕事の登録を行います。</span>
                <span className="mt-2">プレビューは登録後の詳細画面よりご確認頂けます。</span>
                <span className="mt-2">※画像は登録後に編集画面からアップして下さい。</span>
              </Card>
            </Col>
          </Row>
          <Row>
            {/*left*/}
            <Col xs={4}>
              <Card body className="mb-4">
                {/* <div style={{ marginBottom: 10 }}>
                  <FormControl className={classes.formControl}>
                    <FormGroup row>
                      <FormLabel style={{ color: colorLabel }} component="legend">
                        <span className="text-red">*</span>表示設定
                      </FormLabel>
                      <RadioGroup
                        name="display_setting"
                        defaultValue={displaySetting}
                        options={hiddenStatusRequired()}
                        onChange={(e) => setDisplaySetting(e.target.value)}
                        classCustom="d-cus-item"
                      />
                    </FormGroup>
                  </FormControl>
                </div> */}
                <div style={{ marginBottom: 10 }}>
                  <FormControl className={classes.formControl}>
                    <FormGroup row>
                      <FormLabel style={{ color: colorLabel }} component="legend">
                        <span className="text-red">*</span>掲載状況
                      </FormLabel>
                      <RadioGroup
                        name="draft"
                        defaultValue={draft}
                        options={jobPublishingTypeRequired()}
                        onChange={(e) => setDraft(e.target.value)}
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </Card>

              {/* <Card body className="mb-4">
                <div style={{ marginBottom: 10 }}>
                  <FormControl className={classes.formControl}>
                    <FormGroup row>
                      <FormLabel style={{ color: colorLabel }} component="legend">
                        <span className="text-red">*</span>掲載開始日
                      </FormLabel>
                      <DatePicker
                        selected={startTime ? new Date(startTime) : null}
                        className="date"
                        onChange={(e) => handleDate(e, 'start_time')}
                        locale="ja"
                        dateFormat="yyyy/MM/dd"
                      />
                    </FormGroup>
                  </FormControl>
                </div>

                <div style={{ marginBottom: 10 }}>
                  <FormControl className={classes.formControl}>
                    <FormGroup row>
                      <FormLabel style={{ color: colorLabel }} component="legend">
                        <span className="text-red">*</span>掲載終了日
                      </FormLabel>
                      <DatePicker
                        selected={endTime ? new Date(endTime) : null}
                        className="date"
                        onChange={(e) => handleDate(e, 'end_time')}
                        locale="ja"
                        dateFormat="yyyy/MM/dd"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              </Card> */}
              {/* <Card body className="mb-4">
                <div style={{ marginBottom: 10 }}>
                  <FormGroup row>
                    {!loadingAgents && (
                      <FormControl className={classes.formControl}>
                        <InputLabel style={{ color: colorLabel }} shrink>
                          <span className="text-red">*</span>会社Number
                        </InputLabel>
                        <Select
                          name="agentId"
                          onChange={(e) => setAgentId(e.target.value)}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          {(agents || []).map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.agentNumber}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </FormGroup>
                </div>
              </Card> */}

              <Card body className="mb-4">
                <div style={{ marginBottom: 10 }}>
                  <FormGroup row>
                    {nameStateJob && (
                      <FormControl className={classes.formControl}>
                        <InputLabel style={{ color: colorLabel }} shrink>
                          <span className="text-red">*</span>都道府県
                        </InputLabel>
                        <Select
                          name="state"
                          onChange={(e) => handleChangeState(e)}
                          displayEmpty
                          className={classes.selectEmpty}
                          required={true}
                        >
                          {nameStateJob.items?.filter((state) => !!state?.label)?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </FormGroup>
                </div>
                <div style={{ marginBottom: 10 }}>
                  <FormGroup row>
                    {
                      <FormControl className={classes.formControl}>
                        <InputLabel style={{ color: colorLabel }} shrink>エリア</InputLabel>
                        <Select
                          name="area"
                          onChange={(e) => handleChangeArea(e)}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          {(areas || []).map((opt) => (
                            <MenuItem key={opt.id} value={opt.id}>
                              {opt.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    }
                  </FormGroup>
                </div>

                <div style={{ marginBottom: 20 ,display:'flex', justifyContent:'space-between', alignItems:'center', marginTop:20 }}>
                  <InputLabel style={{ margin:0, color: colorLabel }} shrink>給与報酬幅</InputLabel>
                  <div style={{ display:'flex', justifyContent:'space-between', alignItems:'center' }}>
                    <input
                      name="minimum_salary"
                      type="number"
                      style={{ width:100 }}
                      onChange={(e) => setMinimumSalary(e.target.value)}
                    />円
                    <span style={{ margin:'0 20px' }}>~</span>
                    <input
                      name="maximum_salary"
                      type="number"
                      style={{ width:100 }}
                      onChange={(e) => setMaximumSalary(e.target.value)}
                    />円
                  </div>
                </div>
                {resource && showResourceItemJobForm(resource?.items, loadingResource, onChangeAttribute, colorLabel)}

              </Card>

              <Card body className="mb-4">
                <Row>
                  <Label xs={12} className="">
                    <InputLabel style={{ color: colorLabel }} className={'m-0'} id="mutiple-select-label"
                                onClick={() => setVisitTagShow(true)}>タグ設定</InputLabel>
                    <div className="d-wrapper-custom-select d-border-bottom-form d-flex pb-1"
                         onClick={() => setVisitTagShow(true)}>
                      {convertChangeTagMemberList()
                        ? (<div className="wrapper-tag w-100 cursor-pointer">
                          {orderBy((convertChangeTagMemberList() ?? []), ['displayOrder'], ['asc']).map((v, i) => (
                            <div className="tag" key={i}>{v.label}</div>))}
                        </div>)
                        : <div></div>
                      }
                      <svg className="d-svg-icon-select" focusable="false" viewBox="0 0 24 24"
                           aria-hidden="true" role="presentation">
                        <path d="M7 10l5 5 5-5z"></path>
                      </svg>
                    </div>
                  </Label>
                </Row>
              </Card>
            </Col>
            {/*right*/}
            <Col xs={8}>
              <Card body className="mb-4">
                <Row>
                  <div className="w-100 mb-2">
                    <FormGroup row>
                      <Col xs={12}>
                        <FormLabel style={{ color: colorLabel }} component="legend">
                          <span className="text-red">*</span>タイトル
                        </FormLabel>
                        <TextField
                          id="standard-required"
                          placeholder='仕事のタイトルを20文字以内で入力して下さい'
                          type="text"
                          name="title"
                          className="w-100"
                          required={true}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </div>
                </Row>
              </Card>
              <Card body className="mb-1">
                <div className="mb-1">
                  <Row>
                    <Label xs={12}>
                      <FormLabel style={{ color: colorLabel }} component="legend">
                        <span className="text-red">*</span>給与報酬詳細
                      </FormLabel>
                      <textarea
                        name="salary_detail"
                        placeholder='給与報酬に関して詳しく記入して下さい'
                        rows="3"
                        style={{ width: '100%', padding: 10 }}
                        onChange={(e) => setSalaryDetail(e.target.value)}
                      />
                    </Label>
                  </Row>
                </div>
                <div className="mb-1">
                  <Row>
                    <Label xs={12}>
                      <FormLabel style={{ color: colorLabel }} component="legend">
                        <span className="text-red">*</span>勤務時間
                      </FormLabel>
                      <textarea
                        name="work_hours"
                        placeholder='勤務時間に関して詳しく記入して下さい'
                        rows="3"
                        style={{ width: '100%', padding: 10 }}
                        onChange={(e) => setWorkHours(e.target.value)}
                      />
                    </Label>
                  </Row>
                </div>
                <div className="mb-1">
                  <Row>
                    <Label xs={12}>
                      <FormLabel style={{ color: colorLabel }} component="legend">
                        <span className="text-red">*</span>仕事内容
                      </FormLabel>
                      <textarea
                        name="work_detail"
                        placeholder='仕事内容に関して詳しく記入して下さい'
                        rows="4"
                        style={{ width: '100%', padding: 10 }}
                        onChange={(e) => setWorkDetail(e.target.value)}
                      />
                    </Label>
                  </Row>
                </div>
                <div className="mb-1">
                  <Row>
                    <Label xs={12}>
                      <FormLabel style={{ color: colorLabel }} component="legend">
                        <span className="text-red">*</span>勤務地
                      </FormLabel>
                      <textarea
                        name="location"
                        placeholder='勤務地に関して詳しく記入して下さい'
                        rows="4"
                        style={{ width: '100%', padding: 10 }}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </Label>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </MuiThemeProvider>
      {open && (
        <JobRegisterConfirm {...props}
                            open={open}
                            setOpen={setOpen}
                            title={title}
                            data={{
                              agentId,
                              agents,
                              state,
                              area,
                              stateText,
                              areaText,
                              displaySetting,
                              salaryDetail,
                              workHours,
                              workDetail,
                              location,
                              draft,
                              // startTime,
                              // endTime,
                              tagSelected: convertChangeTagMemberList(),
                              editingItemJob,
                              minimumSalary,
                              maximumSalary,
                            }}
                            handleCreateJob={handleCreateJob}
                            colorLabelConfirm={colorLabelConfirm}
        />
      )}

      {visitTagShow && <CheckBoxItemModal onClose={() => setVisitTagShow(false)}
                                          visible={visitTagShow}
                                          title="タグ設定"
                                          data={tagJobs}
                                          selected={selectedTagJobList}
                                          handleChange={handleChangeTag}

      />}
    </div>
  );
}

export default OpeJobRegister;
