import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NoPhoto from '../../../assets/image/no_user_photo.png';
import { Link } from 'react-router-dom';
import SortArrows from '../../../Components/SortArrows';
import Util from '../../../Util/Util';
import { Card, MuiThemeProvider } from '@material-ui/core';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import { getListCharaByOpe } from '../../../Api/operatorAPI';
import { useDispatch } from 'react-redux';
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

export default function OperatorList({
  operators,
  onUpdate,
  filter = { sort: {} },
  onSort = (sortByKey) => { },
  pageIndex = 1,
  pageSize = 2,
}) {
  const saveLocalStore = (id) => {
    localStorage.setItem('operatorId', id);
  };
  const [charaList, setCharaList] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const loadChara = async () => {
      const { data } = await getListCharaByOpe();
      setCharaList(data.data);
    };
    loadChara();
  }, [dispatch]);

  const setCharaOfOpe = (id) => {
    let count = 0;
    charaList?.map((item) => {
      if (id === item.id) {
        count = item.count;
      }
      return true;
    });

    return count;
  };

  const totalSend = (item) => {
    if (item.users.length > 0) {
      let total = 0;
      item.users.map((user) => {
        total += user.countTextSending;
        return true;
      });
      return total;
    }
    return 0;
  };

  const totalReceived = (item) => {
    if (item.users.length > 0) {
      let total = 0;
      item.users.map((user) => {
        total += user.countTextReceived;
        return true;
      });
      return total;
    }
    return 0;
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <Card className="scroll-x-on-top" style={{ marginBottom: 20 }}>
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="電話番号"
                  name="operator.phoneNumber"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell align="center">画像</TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="オペレーター名"
                  name="operator.operatorName"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="オペレーターNumber"
                  name="operator.operatorNumber"
                  onClick={onSort}
                />
              </TableCell>
              <TableCell>所属キャラ数</TableCell>
              <TableCell>チャット送信数</TableCell>
              <TableCell>チャット受信数</TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  name="operator.registerDate"
                  onClick={onSort}
                  text="登録日"
                  defaultName="operator.registerDate"
                />
              </TableCell>
              <TableCell>
                <SortArrows
                  sortObject={filter.sort}
                  text="ログイン"
                  name="operator.lastlogin"
                  onClick={onSort}
                />
              </TableCell>
            </TableRow>

            {operators.map((operator, index) => (
              <TableRow key={operator.id} style={{ height: 48 }}>
                <TableCell>{(pageIndex - 1) * pageSize + index + 1}</TableCell>
                <TableCell>
                  <span>{operator.phoneNumber}</span>
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={Util.applyDynamicRouteParams(
                      ROUTES_CLIENT_DEFINED.OPERATOR.DETAIL,
                      [operator.id],
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => saveLocalStore(operator.id)}
                  >
                    <img className="imgRound" src={NoPhoto} alt="" />
                  </Link>
                </TableCell>
                <TableCell>
                  <span>{operator.operatorName}</span>
                </TableCell>
                <TableCell>
                  <span>{operator.operatorNumber}</span>
                </TableCell>
                <TableCell>
                  <span>{Util.separator(setCharaOfOpe(operator.id))}</span>
                </TableCell>
                <TableCell>
                  <span>{Util.separator(totalSend(operator))}</span>
                </TableCell>
                <TableCell>
                  <span>{Util.separator(totalReceived(operator))}</span>
                </TableCell>
                <TableCell className=" text-muted">
                  <time>
                    {Util.timestampToDate(operator.registerDate, 'YYYY/MM/DD')}
                  </time>
                </TableCell>
                <TableCell className=" text-muted">
                  <time>
                    {Util.timestampToDate(operator.lastlogin, 'YYYY/MM/DD HH:mm')}
                  </time>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </MuiThemeProvider>
  );
}
