import { Card, MuiThemeProvider, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Util from '../../../Util/Util';
import { Link } from 'react-router-dom';
import CircularLoading from '../../../Components/CircularLoading';
import SortArrows from '../../../Components/SortArrows';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import { loadAgents } from '../../../Redux/Actions/agents';
import NoPhotoAgent from '../../../assets/image/no_agent_photo.png';
import Pagination from '../../../Components/Pagination';
import LoadingBar from '../../../Components/LoadingBar';
import { getListChara } from '../../../Api/agentAPI';
import { memberAgentTypeArrayText } from '../../../Util/utils'
import { ROUTES_CLIENT_DEFINED } from 'Config/listConstants';

const pageSize = 50;

function AgentList() {
  const loading = useSelector((state) => state.agents.loading);
  const agents = useSelector((state) => state.agents.items);
  const totalRow = useSelector((state) => state.agents.totalRow);

  const [pageIndex, setPageIndex] = useState(1);
  const [filter, setFilter] = useState({
    sortBy: 'agent.lastlogin',
    orderBy: 'DESC',
    sort: { 'agent.lastlogin': 'DESC' },
  });
  const [charaList, setCharaList] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadAgents({ ...filter, pageIndex: 1, pageSize }));
    const loadChara = async () => {
      const { data } = await getListChara();
      setCharaList(data.data);
    };
    loadChara();
  }, [dispatch, filter]);

  const handlePageChange = (e, pageIndex) => {
    setPageIndex(pageIndex);
    dispatch(loadAgents({ ...filter, pageIndex, pageSize }));
  };

  const onSort = (sortByKey) => {
    const clientRules = {};
    if (filter.sort[sortByKey] === undefined) {
      clientRules[sortByKey] = 'DESC';
    }
    if (filter.sort[sortByKey] === 'DESC') {
      clientRules[sortByKey] = 'ASC';
    }
    if (filter.sort[sortByKey] === 'ASC') {
      clientRules[sortByKey] = 'DESC';
    }
    const serverRules = {
      sortBy: Object.keys(clientRules)[0] || '',
      orderBy: clientRules[sortByKey] || '',
    };

    setFilter({
      ...filter,
      sort: { ...clientRules },
      ...serverRules,
    });
    setPageIndex(1);
  };

  const setCharaOfAgent = (id) => {
    let count = 0;
    charaList?.map((item) => {
      if (id === item.id) {
        count = item.count;
      }
      return true;
    });

    return count;
  };

  const saveLocalStore = (id) => {
    localStorage.setItem('agentId', id);
  };

  const totalSend = (item) => {
    if (item.users.length > 0) {
      let total = 0;
      item.users.map((user) => {
        total += user.countTextSending;
        return true;
      });
      return total;
    }
    return 0;
  };

  const totalReceived = (item) => {
    if (item.users.length > 0) {
      let total = 0;
      item.users.map((user) => {
        total += user.countTextReceived;
        return true;
      });
      return total;
    }
    return 0;
  };
  
  return (
    <div style={{ marginTop: 10 }}>
      <LoadingBar show={loading} />
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          {loading ? (
            <CircularLoading />
          ) : (
            <Table padding="checkbox" className="table mb-0">
              <TableBody>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="電話番号"
                      name="agent.phoneNumber"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell className="text-center">画像</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="会社名"
                      name="agent.nickName"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="メールアドレス"
                      name="agent.emailAddressMain"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="会社ステイタス"
                      name="agent.memberType"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="会社Number"
                      name="agent.agentNumber"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="states.label"
                      text="都道府県"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="agent.planExpireDate"
                      text="掲載有効期限"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="agent.datePayment"
                      text="支払予定日"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="agent.balancePayment"
                      text="支払残高"
                      onClick={onSort}
                    />
                  </TableCell>
                  <TableCell>所属キャラ数</TableCell>
                  <TableCell>チャット送信数</TableCell>
                  <TableCell>チャット受信数</TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      name="agent.registerDate"
                      onClick={onSort}
                      text="登録日"
                      defaultName="agent.registerDate"
                    />
                  </TableCell>
                  <TableCell>
                    <SortArrows
                      sortObject={filter.sort}
                      text="ログイン"
                      name="agent.lastlogin"
                      onClick={onSort}
                    />
                  </TableCell>
                </TableRow>

                {agents.map((agent, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {(pageIndex - 1) * pageSize + index + 1}
                    </TableCell>
                    <TableCell>{agent.phoneNumber}</TableCell>
                    <TableCell className="text-center">
                      <div>
                        <Link
                          to={Util.applyDynamicRouteParams(
                            ROUTES_CLIENT_DEFINED.AGENT.DETAIL,
                            [agent.id],
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => saveLocalStore(agent.id)}
                        >
                          <img
                            className="imgRound"
                            src={agent.avatar ? agent.avatar.url : NoPhotoAgent}
                            alt=""
                          />
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell>{agent.nickName}</TableCell>
                    <TableCell>{agent.emailAddressMain}</TableCell>
                    <TableCell>{memberAgentTypeArrayText(agent.memberType)}</TableCell>
                    <TableCell>{agent.agentNumber}</TableCell>
                    <TableCell>{agent.states?.label}</TableCell>
                    <TableCell>
                      <time>
                        {Util.timestampToDate(agent.planExpireDate, 'YYYY/MM/DD')}
                      </time>
                    </TableCell>
                    <TableCell>
                      <time>
                        {Util.timestampToDate(agent.datePayment, 'YYYY/MM/DD')}
                      </time>
                    </TableCell>
                    <TableCell>{agent.balancePayment && `${Util.separator(agent.balancePayment)}円`}</TableCell>
                    <TableCell>{setCharaOfAgent(agent.id)}</TableCell>
                    <TableCell>
                      <span>{totalSend(agent)}</span>
                    </TableCell>
                    <TableCell>
                      <span>{totalReceived(agent)}</span>
                    </TableCell>
                    <TableCell>
                      <time>
                        {Util.timestampToDate(agent.registerDate, 'YYYY/MM/DD')}
                      </time>
                    </TableCell>
                    <TableCell>
                      <time>
                        {Util.timestampToDate(agent.lastlogin, 'YYYY/MM/DD HH:mm')}
                      </time>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Card>
      </MuiThemeProvider>
      <center>
        <Pagination
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRow={totalRow}
          onPageChange={handlePageChange}
        />
      </center>
    </div>
  );
}

export default AgentList;
