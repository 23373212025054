import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Card, MuiThemeProvider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SortArrows from 'Components/SortArrows';
import Util from '../../../Util/Util';
import {
  displayTextScheduleCronjobTypeOptions,
  displayTextScheduleSendStatusOptions,
} from '../../../Util/utils';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import { scheduleSendCategoryOptions } from '../../../Common/constant_text';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // ES 2015

dayjs.extend(duration);

function ScheduleChangeProfileList({
  logSendChat = [],
  pageIndex,
  pageSize,
  loading,
  loadingReceivers,
  filter = { sort: {} },
  onSort = (sortByKey) => {},
  handleClickDetail,
}) {
  return (
    <div className="align-top">
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>設定名</TableCell>
                <TableCell>配信カテゴリー</TableCell>
                <TableCell>送信間隔（分）</TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="次回送信日時"
                    name="sendTime"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="最終送信日時"
                    name="lastSentDate"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>
                  <SortArrows
                    sortObject={filter.sort}
                    text="登録日"
                    name="createdDate"
                    onClick={onSort}
                  />
                </TableCell>
                <TableCell>配信処理</TableCell>
                <TableCell>配信設定</TableCell>
                <TableCell>送信想定数</TableCell>
                <TableCell>ボタン</TableCell>
              </TableRow>

              {logSendChat.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>

                  <TableCell>{item.label}</TableCell>
                  <TableCell>
                    {
                      scheduleSendCategoryOptions.find(
                        (e) => e.value === +item.category,
                      )?.label
                    }
                  </TableCell>
                  <TableCell>
                    {item.frameTime &&
                      `${dayjs.duration(+item.frameTime).asMinutes()}分`}
                  </TableCell>
                  <TableCell>
                    {Util.timestampToDate(item.sendTime, 'YYYY/MM/DD HH:mm:ss')}
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        item.lastSentDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        item.createdDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell>
                    {displayTextScheduleCronjobTypeOptions(item.cronjobStatus)}
                  </TableCell>
                  <TableCell>
                    {displayTextScheduleSendStatusOptions(item.sendStatus)}
                  </TableCell>
                  <TableCell>
                    {loadingReceivers ? (
                      <Skeleton.Button block active />
                    ) : (
                      item.countReceivers && `${item.countReceivers}人`
                    )}
                  </TableCell>
                  <TableCell
                    className="d-color-link"
                    style={{ cursor: 'pointer' }}
                  >
                    <Link onClick={() => handleClickDetail(item)}>表示</Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default ScheduleChangeProfileList;
