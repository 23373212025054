import {
  Button,
  Card,
  MenuItem,
  MuiThemeProvider,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { updateEnvSysCms } from '../../Api/EnvSysCmsApi';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import { TOAST_MESSAGE } from '../../Common/constant_text';
import { useSelector } from 'react-redux';

function EnvValueSetting({ data, isEditing, setIsEditing, refetchFn }) {
  const [envSetting, setEnvSetting] = useState([]);
  const [inputUpdate, setInputUpdate] = useState([]);
  const { data: nameRank } = useSelector((state) => state.nameRank);

  useEffect(() => {
    setEnvSetting(data);
  }, [data]);

  const handleChange = (e, id, propKey) => {
    const newArray = [...inputUpdate];
    const elementsIndex = envSetting.findIndex((element) => element.id === id);
    newArray[elementsIndex] = {
      ...newArray[elementsIndex],
      [`${propKey}`]: e.target.value,
    };
    setInputUpdate(newArray);
  };

  const handleEdit = () => {
    setInputUpdate(envSetting);
    setIsEditing(true);
  };

  const onClose = () => {
    setInputUpdate([]);
    setIsEditing(false);
  };

  const handleUpdate = () => {
    updateEnvSysCms(inputUpdate)
      .then((res) => {
        const { data } = res;
        if (data.data) {
          toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
          refetchFn();
        } else {
          toast.error(TOAST_MESSAGE.ERROR_EDIT);
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
    setIsEditing(false);
  };

  const renderEditableField = (item, propKey, isEditing) => {
    const currentValue = item[propKey];
    return (
      <TableCell>
        {isEditing ? (
          <input
            defaultValue={currentValue}
            name={propKey}
            onChange={(e) => handleChange(e, item.id, propKey)}
          />
        ) : (
          currentValue
        )}
      </TableCell>
    );
  };

  const renderEditValueField = ({ id, content, value }) => {
    //this is the most dirty way to do this that I've ever seen....
    switch (content) {
      case 'rank_change_first_paying':
        return (
          <Select
            name={`value.${id}`}
            defaultValue={value}
            onChange={(e) => handleChange(e, id, 'value')}
            fullWidth
          >
            {nameRank.map((e) => (
              <MenuItem
                key={e.id}
                value={e.id}
                style={{ padding: '10px 10px' }}
              >
                {`${e.id}: ${e.markWord}: ${e.label}`}
              </MenuItem>
            ))}
          </Select>
        );
      default:
        return <React.Fragment />;
    }
  };

  return (
    <MuiThemeProvider theme={tableNewTheme}>
      <div className="align align-fixed-top">
        {isEditing ? (
          <div>
            <center>
              <Button
                className="btn-custom btn-red"
                variant="contained"
                onClick={onClose}
              >
                閉じる
              </Button>
              <Button
                className="btn-custom btn-blue mr-3"
                variant="contained"
                onClick={handleUpdate}
              >
                更新する
              </Button>
            </center>
          </div>
        ) : (
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleEdit}
          >
            編集
          </Button>
        )}
      </div>
      <Card className="scroll-x-on-top main-content-custom">
        <Table padding="checkbox" className="table mb-0">
          <TableBody>
            <TableRow>
              <TableCell title="説明" style={{ width: '25%' }}>
                説明
              </TableCell>
              <TableCell title="DATA名(content)" style={{ width: '10%' }}>
                DATA名(content)
              </TableCell>
              <TableCell title="最大送信数制限(max)">
                最大送信数制限(max)
              </TableCell>
              <TableCell title="有効設定（0：制限無 1：制限有） (status)">
                有効設定（0：制限無 1：制限有） (status)
              </TableCell>
              <TableCell title="初回入金ランク変更のみ使用(value)">
                価値 (value)
              </TableCell>
              <TableCell title="display_order">Display order</TableCell>
            </TableRow>
            {envSetting.map((item, key) => {
              const { value, content } = item;
              return (
                <TableRow key={key}>
                  {renderEditableField(item, 'description', isEditing)}
                  <TableCell>{content}</TableCell>
                  {renderEditableField(item, 'max', isEditing)}
                  {renderEditableField(item, 'status', isEditing)}
                  <TableCell>
                    {isEditing ? renderEditValueField(item) : value}
                  </TableCell>
                  {renderEditableField(item, 'displayOrder', isEditing)}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
    </MuiThemeProvider>
  );
}

export default EnvValueSetting;
