import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import LoadingBar from '../../../Components/LoadingBar';
import { PROFILE_TYPE, ROUTES_API_DEFINED } from '../../../Config/listConstants';
import _ from 'lodash';
import {
  FormControl,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { loadResourceAgent } from '../../../Redux/Actions/resourceAgent';
import { createAgent } from '../../../Api/agentAPI';
import {
  loadAllAreaAgent,
  loadStateAgent,
} from '../../../Redux/Actions/nameStateAgent';
import { TOAST_MESSAGE } from '../../../Common/constant_text';
import {
  CREATE_USER_TYPE,
  REGEX_ALPHABET,
  REGEX_EMAIL,
  REGEX_NUMBER,
} from '../../../Common/constant';
import {
  checkFullWidth,
  convertEnvInfo,
  validateFullWidthVsAlphabet,
} from '../../../Util/utils';
import { checkExistingUserBeforeCreate } from '../../../Api/userAPI';

const customTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      h6: {
        fontSize: 16,
        paddingLeft: 30,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
      },
      shrink: {
        transform: 'none',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '16px',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    marginTop: '6px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Index() {
  let profile;
  const classes = useStyles();
  const [avatar, setAvatar] = useState(null);
  const [images, setImages] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [agentNumber, setAgentNumber] = useState(null);
  const [password, setPassword] = useState(null);
  const [emailAddressMain, setEmailAddressMain] = useState(null);
  const [nickName, setNickName] = useState(null);
  const [state, setState] = useState(null);
  const [area, setArea] = useState(null);
  const [aboutMe, setAboutMe] = useState(null);
  const [adminMemo, setAdminMemo] = useState(null);
  const [viewStyle, setViewStyle] = useState(1);
  const [open, setOpen] = useState(false);
  const [editingAgent, setEditingAgent] = useState({});
  const [labelDb, setLabelDb] = useState({});
  const [colorLabel, setColorLabel] = useState('');
  const [colorLabelConfirm, setColorLabelConfirm] = useState('');
  const history = useHistory();
  const redirectCancelCreateAgent = () => {
    history.push(ROUTES_API_DEFINED.AGENT.LIST);
  };
  const dispatch = useDispatch();
  const loadingState = useSelector((state) => state.nameStateAgent.loading);
  const nameStateAgent = useSelector((state) => state.nameStateAgent.data);
  const areas = useSelector((state) => state.nameStateAgent.dataChild);
  const resource = useSelector((state) => state.resourceAgent.data);
  const loadingResource = useSelector((state) => state.resourceAgent.loading);
  const envInfo = useSelector((state) => state.envInfo.data);

  useEffect(() => {
    setLabelDb(convertEnvInfo(envInfo, ['label_'], true));
    setColorLabel(
      convertEnvInfo(envInfo, ['admin_lable_form_2'], true)?.admin_lable_form_2
        ?.content,
    );
    setColorLabelConfirm(
      convertEnvInfo(envInfo, ['admin_lable_form_1'], true)?.admin_lable_form_1
        ?.content,
    );
  }, [envInfo]);

  useEffect(() => {
    dispatch(loadResourceAgent({}));
    dispatch(loadStateAgent());
  }, []);

  const handleSubmit = async () => {
    let error = false;
    if (!nickName) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.agent.name);
      error = true;
    }

    if (
      phoneNumber &&
      (checkFullWidth(phoneNumber) || !REGEX_NUMBER.test(String(phoneNumber)))
    ) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.phoneNumber);
      error = true;
    }

    if (!state) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.state);
      error = true;
    }
    if (!agentNumber) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.agencyNumber);
      error = true;
    } else if (agentNumber && !REGEX_ALPHABET.test(agentNumber)) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.agencyNumber);
      error = true;
    }

    if (!password) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.password810);
      error = true;
    } else if (
      password &&
      (password.length < 8 ||
        password.length > 10 ||
        !REGEX_ALPHABET.test(password) ||
        checkFullWidth(password))
    ) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
      error = true;
    }

    if (!emailAddressMain) {
      toast.error(TOAST_MESSAGE.VALIDATE_REQUIRED.email);
      error = true;
    } else if (emailAddressMain && !REGEX_EMAIL.test(emailAddressMain)) {
      toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.email);
      error = true;
    }

    if (emailAddressMain || agentNumber) {
      try {
        const { data: existingResult } = await checkExistingUserBeforeCreate({
          role: CREATE_USER_TYPE.AGENT,
          emailAddressMain,
          agentNumber,
        });
        if (existingResult?.errMsgs?.length) {
          error = true;
          existingResult.errMsgs.forEach((m) => {
            toast.error(m);
          });
        }
      } catch (err) {
        toast.warn(
          '会社データがDB上に存在するかどうかを確認できません(WebWarning)',
        );
      }
    }

    if (!error && nickName && state && agentNumber) {
      setOpen(true);
    }
  };

  const validatePassword = (value, name) => {
    validateFullWidthVsAlphabet(value, (valid) => {
      if (valid) {
        toast.error(TOAST_MESSAGE.VALIDATE_FORMAT.password810);
        setPassword('');
      } else {
        setPassword(value);
      }
    });
  };

  const handleCreateAvatar = (url) => {
    setAvatar(url);
  };

  const handleCreateImages = (images) => {
    setImages(images);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateAgent = () => {
    const data = {
      phoneNumber,
      agentNumber,
      password,
      emailAddressMain,
      nickName,
      state,
      aboutMe,
      adminMemo,
      viewStyle,
      avatar,
      images,
      attributes: editingAgent.attributes,
      area,
    };
    createAgent(data)
      .then((response) => {
        const { data } = response;
        if (data.success) {
          history.push(ROUTES_API_DEFINED.AGENT.LIST, {
            sortBy: 'agent.registerDate',
          });
          toast.success(TOAST_MESSAGE.SUCCESS_ADD);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_ADD);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const onChangeAttribute = (e) => {
    setEditingAgent({
      ...editingAgent,
      attributes: {
        ...editingAgent.attributes,
        [e.target.name]: Number(e.target.value),
      },
    });
  };

  const showResource = () => {
    let profileItem = [];
    resource.items
      .filter((item) => item.value === PROFILE_TYPE.ROOT)
      .sort((a, b) => a.profileOrder - b.profileOrder)
      .map((resourceItem) => {
        profileItem.push({
          resource: findResources(resourceItem.label),
          label: resourceItem.label,
          name: resourceItem.profile,
          value: findAttributes(resourceItem.label),
        });
        return resourceItem;
      });

    profile = profileItem;

    return profileItem?.map((item, key) => (
      <Row key={key}>
        <Label xs={12}>
          <FormLabel style={{ color: colorLabel }} component="legend">
            {item.label}
          </FormLabel>
          {!loadingResource ? (
            <Select
              name={item.name}
              style={{ width: '100%' }}
              onChange={onChangeAttribute}
            >
              <MenuItem value="" />
              {item.resource
                .sort((a, b) => a.value - b.value)
                .map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <div></div>
          )}
        </Label>
      </Row>
    ));
  };

  const findAttributes = (resourceName) => {
    let parrentName = resource.items.find((x) => x.label === resourceName);
    let responseResources = resource.items.find(
      (x) => x.profile === parrentName?.profile,
    );
    return responseResources?.label;
  };

  const findResources = (resourceName) => {
    let parentName = resource.items.find((x) => x.label === resourceName);

    return resource.items.filter(
      (x) => x.profile === parentName.profile && x.value !== 0,
    );
  };

  const handleChangeState = (stateId) => {
    setState(stateId);
    dispatch(loadAllAreaAgent(stateId));
    setArea(null);
  };

  return (
    <div style={{ position: 'relative' }}>
      <LoadingBar show={loadingState} />
      <MuiThemeProvider theme={customTheme}>
        <div className="align">
          <Button
            className="btn-custom btn-red"
            variant="contained"
            onClick={redirectCancelCreateAgent}
          >
            閉じる
          </Button>
          <Button
            className="btn-custom btn-blue mr-3"
            variant="contained"
            onClick={handleSubmit}
          >
            登録
          </Button>
        </div>
        <Row>
          <Col xs={4}>
            <Card body className="mb-4">
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
              <Label xs={12}>&nbsp;</Label>
            </Card>
            <Card body className="mb-4">
              <Col xs={12} style={{ marginBottom: 10 }}>
                <FormGroup row>
                  <InputLabel style={{ color: colorLabel }} shrink>
                    <span className="text-red">*</span>メールアドレス（重複NG）
                  </InputLabel>
                  <TextField
                    id="standard-required"
                    type="text"
                    name="emailAddressMain"
                    onChange={(e) => setEmailAddressMain(e.target.value)}
                    className="w-100"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} style={{ marginBottom: 10 }}></Col>
            </Card>
            <Card body className="mb-4">
              {resource && showResource()}
            </Card>
          </Col>
          <Col xs={8}>
            <Card body className="mb-4">
              <Row>
                <Col xs={6}>
                  <Row>
                    <Col xs={12} style={{ marginBottom: 10 }}>
                      <FormGroup row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>会社名
                          </InputLabel>
                          <TextField
                            id="standard-required"
                            type="text"
                            name="nickName"
                            onChange={(e) => setNickName(e.target.value)}
                            className="w-100"
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs={12} style={{ marginBottom: 10 }}>
                      <FormGroup row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            電話番号
                          </InputLabel>
                          <TextField
                            id="standard-required"
                            type="text"
                            name="phoneNumber"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="w-100"
                            value={phoneNumber}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs={12} style={{ marginBottom: 10 }}>
                      <FormGroup row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            会社Number（英数字のみ）（重複NG）
                          </InputLabel>
                          <TextField
                            id="standard-required"
                            type="text"
                            name="agentNumber"
                            onChange={(e) => setAgentNumber(e.target.value)}
                            className="w-100"
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col xs={12} style={{ marginBottom: 10 }}>
                      <FormGroup row>
                        <Col xs={12}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>
                            パスワード（英数字8文字～10文字）
                          </InputLabel>
                          <TextField
                            id="standard-required"
                            name="password"
                            autoComplete={'new-password'}
                            type="text"
                            value={password}
                            onChange={(e) =>
                              validatePassword(e.target.value, e.target.name)
                            }
                            className="w-100"
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={12} className="mt-18">
                      <FormGroup row>
                        <FormLabel
                          style={{ color: colorLabel }}
                          component="legend"
                        >
                          プロフレイアウト（1:写真横/2345:既存/6:Job用）
                        </FormLabel>
                        <input
                          name="viewStyle"
                          type="number"
                          maxLength={11}
                          onChange={(e) => setViewStyle(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Row>
              <Col xs={6}>
                <Card body className="mb-4">
                  <Col xs={12} style={{ marginBottom: 10 }}>
                    <FormGroup row>
                      {nameStateAgent && (
                        <FormControl className={classes.formControl}>
                          <InputLabel style={{ color: colorLabel }} shrink>
                            <span className="text-red">*</span>都道府県
                          </InputLabel>
                          <Select
                            name="state"
                            onChange={(e) => handleChangeState(e.target.value)}
                            displayEmpty
                            className={classes.selectEmpty}
                          >
                            {nameStateAgent?.items
                              ?.filter((state) => !!state?.label)
                              ?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.label}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs={12} style={{ marginBottom: 10 }}>
                    <FormGroup row>
                      <FormControl className={classes.formControl}>
                        <InputLabel style={{ color: colorLabel }} shrink>
                          エリア
                        </InputLabel>
                        <Select
                          name="area"
                          onChange={(e) => setArea(e.target.value)}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          {(areas || []).map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Card>
              </Col>
              <Col xs={6}>
                <Card body className="mb-4">
                  <Label style={{ marginTop: 25 }} xs={12}>
                    &nbsp;
                  </Label>
                  <Label xs={12}>&nbsp;</Label>
                  <Label xs={12}>&nbsp;</Label>
                </Card>
              </Col>
              <Col xs={12}>
                <Card body className="mb-4">
                  <Row>
                    <Label xs={12}>
                      <FormLabel
                        style={{ color: colorLabel }}
                        component="legend"
                      >
                        会社/店舗について
                      </FormLabel>
                      <textarea
                        name="aboutMe"
                        rows="3"
                        onChange={(e) => setAboutMe(e.target.value)}
                        style={{ width: '100%', padding: 10 }}
                      />
                    </Label>
                  </Row>
                </Card>
                <Card body className="mb-4">
                  <Row>
                    <Label xs={12}>
                      <FormLabel
                        style={{ color: colorLabel }}
                        component="legend"
                      >
                        管理者メモ
                      </FormLabel>
                      <textarea
                        name="adminMemo"
                        rows="3"
                        onChange={(e) => setAdminMemo(e.target.value)}
                        style={{ width: '100%', padding: 10 }}
                      />
                    </Label>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </MuiThemeProvider>

      {/* Modal */}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={'d-modal-wrapper'}
      >
        <DialogContent>
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          />
          <div
            style={{
              position: 'absolute',
              top: 6,
              right: 0,
              width: 'auto',
              bottom: 10,
              paddingRight: 50,
            }}
          >
            <center>
              <Button
                className="btn-red"
                style={{
                  padding: '4px 20px',
                  fontSize: 16,
                  fontWeight: 500,
                  color: 'white',
                  marginRight: 20,
                }}
                variant="contained"
                onClick={handleClose}
              >
                戻る
              </Button>
              <Button
                className="btn-custom btn-blue"
                variant="contained"
                onClick={handleCreateAgent}
              >
                登録
              </Button>
            </center>
          </div>
          <Row>
            <Col xs={4}>
              <Card body className="mb-4">
                <Row>
                  <Label style={{ color: colorLabelConfirm }} xs={7}>
                    メールアドレス
                  </Label>
                  <Label xs={5}>{emailAddressMain}</Label>
                </Row>
              </Card>
              <Card body className="mb-4">
                {profile &&
                  !!editingAgent?.attributes &&
                  profile.map((item, key) => (
                    <Row key={key}>
                      <Label style={{ color: colorLabel }} xs={5}>
                        {item.label}
                      </Label>
                      <Label xs={7}>
                        {item.resource.map((option, index) => {
                          let data;
                          if (
                            option.value ===
                            editingAgent?.attributes[option.profile]
                          ) {
                            data = option.label;
                          }
                          return data;
                        })}
                      </Label>
                    </Row>
                  ))}
              </Card>
            </Col>
            <Col xs={8}>
              <Card body className="mb-4">
                <Row>
                  <Col xs={6}>
                    <Label style={{ color: colorLabelConfirm }} xs={7}>
                      会社名
                    </Label>
                    <Label xs={5}>{nickName}</Label>
                    <Label style={{ color: colorLabelConfirm }} xs={7}>
                      電話番号
                    </Label>
                    <Label xs={5}>{phoneNumber}</Label>
                    <Label style={{ color: colorLabelConfirm }} xs={7}>
                      会社Number
                    </Label>
                    <Label xs={5}>{agentNumber}</Label>
                    <Label style={{ color: colorLabelConfirm }} xs={7}>
                      パスワード
                    </Label>
                    <Label xs={5}>{password}</Label>
                  </Col>
                  <Col xs={6}>
                    <Label style={{ color: colorLabelConfirm }} xs={7}>
                      プロフレイアウト
                    </Label>
                    <Label xs={5}>{viewStyle}</Label>
                  </Col>
                </Row>
              </Card>
              <Row>
                <Col xs={6}>
                  <Card body className="mb-4">
                    <Col xs={12}>
                      <Label style={{ color: colorLabelConfirm }} xs={7}>
                        都道府県
                      </Label>
                      <Label xs={5}>{state}</Label>
                      <Label style={{ color: colorLabelConfirm }} xs={7}>
                        エリア
                      </Label>
                      <Label xs={5}>{area}</Label>
                    </Col>
                  </Card>
                </Col>
              </Row>
              <Card body className="mb-4">
                <Col xs={12}>
                  <Label style={{ color: colorLabelConfirm }} xs={7}>
                    会社/店舗について
                  </Label>
                  <Label xs={5}>{aboutMe}</Label>
                </Col>
              </Card>
              <Card body className="mb-4">
                <Col xs={12}>
                  <Label style={{ color: colorLabelConfirm }} xs={7}>
                    管理者メモ
                  </Label>
                  <Label xs={5}>{adminMemo}</Label>
                </Col>
              </Card>
            </Col>
          </Row>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Index;
