import { LOGIN_TYPE } from '../Common/constant';

export const PROFILE_TYPE = {
  ROOT: 0,
};

export const ITEM_JOB_TYPE = {
  ROOT: 0,
};

export const IMAGE_STATUS = {
  PENDING: 1,
  CONFIRMED: 2,
  ALL: 3,
};

export const IMAGE_TYPE = {
  AVATAR: 1,
  SUB_IMAGE: 2,
};

export const REGISTER_TYPE = {
  WEB: 1,
  APP: 2,
  CMS: 9,
};

export const TAG_SETTING = {
  DISPLAY: 0,
  HIDDEN: 1,
};

export const TAG_TYPE = {
  USER: 1,
};

export const LINK_DISPLAY_PAGE_AFTER_LOGIN = {
  1: '/home',
  2: '/mypage',
  3: '/mypage-job',
  4: '/job-lp',
};

export const LINK_MAP_DEFINED_API = {
  MEMBER_USER: 'member_user',
  OPERATION_MANAGEMENT: 'operation_management',
  LOG_MANAGEMENT: 'log_management',
  SALES_MANAGEMENT: 'sales_management',
  SUPPORT_MANAGEMENT: 'support_management',
  JOB_MANAGEMENT: 'job_management',
  OPERATOR_MANAGEMENT: 'operator_management',
  AGENT_MANAGEMENT: 'agent_management',
  ADMINISTRATOR_MANAGEMENT: 'administrator_management',
  SETTING_MANAGEMENT: 'setting_management',
  MYSQL_MANAGEMENT: 'mysql_management',

  AGENT_DETAIL_DELETE: 'agent/detail/delete',
  AGENT_DETAIL_CREATE_LP: 'agent/detail/create_lp',
  AGENT_BTN_CHARA_LIST: 'agent/detail/chara_list',
  AGENT_BTN_POINT_USAGE: 'agent/detail/point_usage',
  AGENT_BTN_JOB_LIST: 'agent/detail/job_list',
  AGENT_BTN_FAVORITE_LOG_MODAL: 'agent/detail/favorite',
  AGENT_BTN_POINT_MODAL: 'agent/detail/payment_log',
  AGENT_BTN_ENTRY_PAIL_PLAN_MODAL: 'agent/detail/entry_post',
  AGENT_BTN_PAYMENT_MODAL: 'agent/detail/add_point',
  AGENT_BTN_BALANCE_MODAL: 'agent/detail/pay_credit',
  AGENT_BTN_CHAT_ROOM_MODAL: 'agent/detail/chat_admin',
  FILE_UPLOAD_WEB: 'setting/file_upload/web',
  FILE_UPLOAD_APP: 'setting/file_upload/app',
  FILE_UPLOAD_CONTENT_IMAGE_WEB: 'setting/file_upload/image',
  INFORMATION_HOME: 'information_home',
  HOME: 'home',

  ADMINISTRATOR_DETAIL_DELETE: 'administrator/detail/delete',
  ADMIN_OPE_SEARCH_PRESET: 'operator/search_user/preset',
  ADMIN_OPE_SEARCH_SEND_CHAT_MESSAGE: 'operator/search_user/send_chat_massage',
  ADMIN_OPE_SEARCH_USER_SEND_MESSAGE: 'admin_ope/search_user/send_message',
  ADMIN_OPE_SEARCH_USER_SCHEDULE_SMS:
    'operator/search_user/schedule_sms_standby',
  ADMIN_OPE_SEARCH_USER_SCHEDULE_MAIL:
    'operator/search_user/schedule_mail_standby',
  ADMIN_OPE_SEARCH_USER_SCHEDULE_CHAT:
    'operator/search_user/schedule_chat_standby',
    
  OPERATOR_DETAIL_DELETE: 'operator/detail/delete',
  OPERATOR_DETAIL_WORK_PAGE: 'operator/work_page_individual',
  OPE_BTN_POINT_USAGE: 'agent/ope/point_usage',
  OPE_BTN_FAVORITE_JOB: 'agent/ope/favorite',
  OPE_BTN_ENTRY_PAID_PLAN: 'agent/ope/entry_post',
  OPE_BTN_BALANCE: 'agent/ope/pay_credit',
  OPE_BTN_CHAT_ROOM_ADMIN: 'agent/ope/chat_admin',

  MANAGEMENT_SEND_MAIL_LOG: 'management/send_mail_log',
  MANAGEMENT_SEND_SMS_LOG: 'management/send_sms_log',
  MANAGEMENT_SEND_PUSH_LOG: 'management/send_push_log',
  MANAGEMENT_TIMER_MAIL_STANDBY: 'management/timer_mail_standby',
  MANAGEMENT_TIMER_SMS_STANDBY: 'management/timer_sms_standby',
  MANAGEMENT_TIMER_PUSH_STANDBY: 'management/timer_push_standby',
  MANAGEMENT_TIMER_CHATROOM_STANDBY: 'management/timer_chatroom_standby',
  MANAGEMENT_SCHEDULE_MAIL_STANDBY: 'management/schedule_mail_standby',
  MANAGEMENT_SCHEDULE_SMS_STANDBY: 'management/schedule_sms_standby',
  MANAGEMENT_SCHEDULE_CHAT_STANDBY: 'management/schedule_chat_standby',
  MANAGEMENT_SCHEDULE_CHANGE_PROFILE_STANDBY: 'management/schedule_change_prof_standby',

  USER_SEARCH_DETAIL_CHAT_LOG: 'member_user/detail/chat_log',
  USER_SEARCH_DETAIL_LIKE: 'member_user/detail/like',
  USER_SEARCH_DETAIL_PAYMENT_LOG: 'member_user/detail/payment_log',
  USER_SEARCH_DETAIL_NOTIFY_SEND: 'member_user/detail/noti_send',
  USER_SEARCH_DETAIL_EMAIL_SEND: 'member_user/detail/email_send',
  USER_SEARCH_DETAIL_SMS_SEND: 'member_user/detail/sms_send',
  USER_SEARCH_UPDATE_PROFILE_PAGE_MEMBER:
    'member_user/detail/profile_page',
  USER_SEARCH_DETAIL_EDIT: 'member_user/detail/edit',
  USER_SEARCH_DETAIL_DELETE: 'member_user/detail/delete',
  USER_SEARCH_DETAIL_ADD_POINT: 'member_user/detail/add_point',
  USER_SEARCH_DETAIL_FAVORITE: 'member_user/detail/favorite',
  USER_SEARCH_DETAIL_FOOTPRINT: 'member_user/detail/footprint',
  USER_SEARCH_DETAIL_SALARY_LOG: 'member_user/detail/salary_log',
  USER_SEARCH_DETAIL_BLOG_LOG: 'member_user/detail/blog_log',
  USER_SEARCH_DETAIL_BLOCK: 'member_user/detail/block',
  USER_SEARCH_DETAIL_POINT_LOG: 'member_user/detail/point_log',
  USER_SEARCH_DETAIL_POINT_USAGE: 'member_user/detail/point_usage',
  USER_SEARCH_DETAIL_MOVE_TO_ARCHIVE: 'member_user/detail/archive',
  USER_SEARCH_DETAIL_TWILIO_LOG: 'member_user/detail/twilio_log',
  USER_SEARCH_BULK_CONVERSION: 'search_user/bulk_conversion',
  USER_SEARCH_EMAIL_SEND: 'search_user/send_mail',
  USER_SEARCH_EMAIL_LOOP_SEND: 'search_user/schedule_mail_standby',
  USER_SEARCH_NOTIFY_SEND: 'search_user/send_noti',
  USER_SEARCH_SMS_SEND: 'search_user/send_sms',
  USER_SEARCH_SMS_LOOP_SEND: 'search_user/schedule_sms_standby',
  USER_SEARCH_SCHEDULE_CHANGE_PROF: 'search_user/schedule_change_prof_standby',
  USER_SEARCH_PRESET: 'search_user/preset',
  USER_SEARCH_DETAIL_FAVORITE_JOB: 'member_user/detail/favorite_job',
  USER_SEARCH_DETAIL_JOB_ENTRY: 'member_user/detail/job_entry_list',
  USER_SEARCH_CSV_EXPORT: 'search_user/csv_export',
  USER_SEARCH_CSV_IMPORT: 'search_user/import_csv',
  USER_SEARCH_ARCHIVE_USERS: 'search_user/archive_user',
  USER_SEARCH_DELETE_USERS: 'search_user/delete_user',

  CONTACTS: 'contacts',
  CHAT_SUPPORT_INDIVIDUAL: 'chat_support_individual',
  CHAT_ADMIN_INDIVIDUAL: 'chat_admin_individual',
};

export const ROUTES_API_DEFINED = {
  HOME: {
    INFORMATION_HOME: '/information_home',
  },
  MEMBER_USER: {
    SEARCH: '/member_user/search',
    REGISTER: '/member_user/register',
    ARCHIVE: '/member_user/archive',
  },
  MANAGEMENT: {
    IMAGE_PROFILE: '/management/image_prof',
    LIKE_LOG: '/management/like_log',
    FAVORITE_USER: '/management/favorite_user',
    FOOTPRINT_USER: '/management/footprint_user',
    BLOCK_LIST: '/management/block_list',
    CHAT_ROOM: '/management/chat_room',
    CHAT_MESSAGE_USER: '/management/chat_message_user',
    BLOG_LIST: '/management/blog_list',
    TAG_MEMBER_USER: '/management/tag_member_user',
    LOG_SEND: '/management/log_send',
    TIMER_STANDBY: '/management/timer_standby',
    SCHEDULE_STANDBY: '/management/schedule_standby',
  },
  LOG: {
    PAYMENT_MATCH: '/log/payment_match',
    PAYMENT_JOB: '/log/payment_job',
    GET_POINT: '/log/get_point',
    POINT_USAGE: '/log/point_usage',
    TWILIO_HISTORY: '/log/twilio_history',
    CHANGE_PHONE: '/log/change_phone',
    OTP_LOG: '/log/otp_log',
    ENROLLMENT: '/log/enrollment',
  },
  CONTENTS: {
    POPUP_SETTING: '/contents/popup_setting',
    CAMPAIGN: '/contents/campaign_setting',
  },
  SUPPORT: {
    CONTACT_USER: '/support/contact_user',
    REPORT: '/support/report',
    CHAT_ADMIN_USER_LOG: '/support/chat_admin_user_log',
    CHAT_ADMIN_AGENT_LOG: '/support/chat_admin_agent_log',
    CHAT_AGENT_USER_LOG: '/support/chat_agent_user_log',
    CHAT_ADMIN_USER_INDIVIDUAL: '/support/chat_admin_user_individual',
    CHAT_ADMIN_AGENT_INDIVIDUAL: '/support/chat_admin_agent_individual',
  },
  OPERATOR: {
    SEARCH: '/operator/search',
    REGISTER: '/operator/register',
    SALARY_LOG: '/operator/salary_log',
    SALARY_SUMMARY: '/operator/salary_summary',
  },
  AGENT: {
    LIST: '/agent/agent_list',
    REGISTER: '/agent/register',
  },
  JOB: {
    LIST: '/job/job_list',
    REGISTER: '/job/register',
    ENTRY_USER_LIST: '/job/job_entry_user_list',
    FAVORITE_JOB_USER_LIST: '/job/favorite_job_user_list',
  },
  ADMINISTRATOR: {
    LIST: '/administrator/list',
    REGISTER: '/administrator/register',
  },
  SETTING: {
    PROFILE_USER: '/setting/profile_user',
    PROFILE_AGENT: '/setting/profile_agent',
    ITEM_JOB: '/setting/item_job',
    STATE_USER: '/setting/state_user',
    STATE_AGENT: '/setting/state_agent',
    STATE_JOB: '/setting/state_job',
    POINT_RATE: '/setting/point_rate',
    PAYMENT_RATE: '/setting/payment_rate',
    INFORMATION_SETTING: '/setting/information_setting',
    ENV_MAILSMS_TEXT: '/setting/env_mailsms_text',
    CONVERSION_TAG: '/setting/conversion_tag',
    TAG_NAME: '/setting/tag_name',
    HELP_CMS: '/setting/help_cms',
    RANK: '/setting/rank_setting',
    WEB_TEXT_PAGE: '/setting/web_text_page',
    FILE_UPLOAD: '/setting/file_upload',
    ENV_INFO: '/setting/env_info',
    ENV_UI_WEB: '/setting/env_ui_web',
    ENV_VALUE: '/setting/env_value',
    TOP_NAV: '/setting/top_nav',
    ENV_ASP: '/setting/env_asp',
  },
  DATABASE: {
    MYSQL: '/database_mysql',
  },
};

export const ROUTES_CLIENT_DEFINED = {
  NOT_FOUND: '/404',
  AUTH: {
    LOGIN_SYS: '/login_sys',
    LOGIN_OPERATOR: '/login_ope',
    LOGIN_AGENT: '/login_agent',
    LOGIN_ADMIN: '/login_admin',
  },
  HOME: {
    INDEX: '/',
    HOME: '/home',
  },
  DATABASE: {
    MEMBERS: '/database/members',
    CONTACTS: '/database/contacts',
    MESSAGE: '/database/message',
    CAMPAIGN: '/database/campaign',
    CHAT: '/database/chat',
    PAYMENT: '/database/payment',
    INFORMATION: '/database/information',
    TWILIO_HISTORY: '/database/twilio-history',
    TEXT_WEB_PAGE: '/database/text-web-page',
    OPERATOR: '/database/operator',
    AGENT: '/database/agent',
    MEMBER_ARCHIVE: '/database/member-archive',
    JOB_DETAIL: '/database/job-detail',
    MEMBER_ADMIN: '/database/member-admin',
    OTP: '/database/otp',
    PAYMENT_LOG_JOB: '/database/payment-log-job',
    POPUP_SETTING: '/database/popup-setting',
    LOG_MAIL_VERIFICATION: '/database/log_mail_verification',
  },
  USER: {
    EDIT_MULTI: '/user/edit/multi',
    DETAIL: '/user/:userId/detail',
    DETAIL_SHORT: '/user/:userId/detailshort',
    EDIT: '/user/:userId/edit',
    EDIT_SHORT: '/user/:userId/editshort',
  },
  CHARACTER: {
    DETAIL_SHORT: '/character/:userId/detailshort',
    EDIT_SHORT: '/character/:userId/editshort',
  },
  AGENT: {
    DETAIL: '/agent/:agentId/detailagent',
    EDIT: '/agent/:agentId/editagent',
    LIST_CHARA: '/agent/:agentId/listchara',
  },
  OPERATOR: {
    DETAIL: '/operator/:operatorId/detail',
    EDIT: '/operator/:operatorId/edit',
    SALARYPOINT: '/operator/salarypoint/:operatorId',
    SEARCH_USER: '/operator/detail/search_user',
  },
  ADMINISTATOR: {
    DETAIL: '/administrator/:memberAdminId/detail',
    EDIT: '/administrator/:memberAdminId/edit',
  },
  ADMIN_OPE: {
    SEARCH_USER: '/admin_ope/search_user/:operatorId',
    WORK_PAGE_INDIVIDUAL: '/admin_ope/work_page_individual/:operatorId',
  },
  AGENT_OPE: {
    JOB_LIST: '/agentope/job-list/:agentId',
    POINT_USAGE_LOG: '/agentope/point_usage_log/:agentId',
    DETAIL_AGENT: '/agentope/:agentId/detailagent',
    DETAIL_OPE: '/agentope/:agentId/detailope',
    EDIT_AGENT: '/agentope/:agentId/editagent',
  },
  OPE_JOB: {
    JOB_REGISTER: '/ope-job/job-register',
    JOB_DETAIL: '/job/:jobId/detail',
    JOB_EDIT: '/job/:jobId/edit',
  },
  OPE_CHARA: {
    DETAIL: '/opechara/:operatorId/detail',
    EDIT: '/opechara/:operatorId/edit',
    SALARYPOINT: '/opechara/salarypoint/:operatorId',
  },
  ADMIN_AGENT: {
    WORK_PAGE_INDIVIDUAL: '/admin_agent/work_page_individual/:id',
  },
  SETTING: {
    ENV_SYS_SETTING: '/env_sys_setting',
  },
};

export const CODE_MAP_DEFINED_API = {
  SITE_NAME: 'site_name',
  CONTACT_MAIL_ADDRESS: 'contact_mail_address',
  URL_LP: 'url_lp',
  CONTACT_PHONE_NUMBER: 'contact_phone_number',
  URL_CONTACT: 'url_contact',
  BG_COLOR_SIDE_NAV: '99',
  LOGIN_LOGO: 'login/logo',
  LOGIN_TITLE: 'login/title',
  CMS_TITLE_NAME: 'cms_title_name',
  TOP_NAVI_BAR_LOGO: 'top_navibar_logo',

  // Action type login
  SYSTEM_ADMIN: String(LOGIN_TYPE.SYSTEM_ADMIN), // 0
  OPERATOR: String(LOGIN_TYPE.OPERATOR), // 1,
  AGENT: String(LOGIN_TYPE.AGENT), // 2,
  ADMIN: String(LOGIN_TYPE.SYSTEM_ADMIN), // 3,
};

export const AGENT_ACCESS_MENU_LINKS = [LINK_MAP_DEFINED_API.LOG_MANAGEMENT];

export const REGISTER_TYPE_LOG_ENROLLMENT = {
  WEB: 1,
  APP: 2,
  CSV: 3,
  CMS: 9,
};
