import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
} from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { Col, Label, Row } from 'reactstrap';
import Util from '../../../Util/Util';
import RemoveModal from './RemoveModal';
import { toast } from 'react-toastify';
import { TEXT_HTML_MANAGEMENT_TIMER_STANDBY_CHAT_LINK_MODAL } from 'Common/modal_text_info';
import {
  TOAST_MESSAGE,
  USER_DETAIL_MESSAGES,
  scheduleSendStatusOptions,
  scheduleSendCategoryOptions,
} from 'Common/constant_text';
import { useSelector } from 'react-redux';
import { updateScheduleChangeProfile } from 'Api/scheduleAPI';
import styles from '../shared/scheduleDetailModal.module.scss';
import classNames from 'classnames';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import RadioGroup from '../../../Components/RadioGroup';
import SearchPresetSelect from '../../UserSearch/shared/SearchPresetSelect';
import { displayTextScheduleCronjobTypeOptions } from '../../../Util/utils';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // ES 2015
import ChangeProfileForm from 'Page/UserSearch/SendScheduledChangeProfileModal/changeProfileForm';
import _ from 'lodash';

dayjs.extend(duration);

const style = {
  paddingTop: 0,
  fontSize: 14,
  paddingBottom: 0,
  lineHeight: 'unset',
  position: 'relative',
};

const stylehrleft = {
  width: '100%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};
const stylehrright = {
  width: '90%',
  backgroundColor: 'rgba(0,0,0,.125)',
  margin: 0,
  position: 'absolute',
  top: 18,
};

export default function ScheduleChangeProfileDetailModal({
  onClose,
  scheduleChangeProfile,
  reload,
}) {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [data, setData] = useState({});
  const [selectedPresets, setSelectedPresets] = useState(null);
  const { data: fetchedPresets } = useSelector((state) => state.searchPresets);

  const handleChange = (e) => {
    e.persist();
    setData((prev) => {
      const newData = { ...prev, [e.target.name]: e.target.value };
      delete Object.assign(newData, {
        sendStatus: newData.sendStatusDetail,
      }).sendStatusDetail;
      return newData;
    });
  };

  const handleChangeProfileForm = useCallback((data) => {
    setData((prev) => {
      const newData = { ...prev, ...data };
      return newData;
    });
  }, []);

  const handleClose = () => {
    setConfirmVisible(false);
  };

  const handleRemove = () => {
    setConfirmVisible(true);
  };

  const handleUpdate = async () => {
    await updateScheduleChangeProfile(scheduleChangeProfile.id, {
      ...data,
      searchResult:
        (selectedPresets &&
          fetchedPresets.find((e) => e.id === selectedPresets)?.content) ||
        scheduleChangeProfile.searchResult,
    }).then((response) => {
      const { data } = response;
      if (data.success) {
        onClose();
        reload();
        toast.success(TOAST_MESSAGE.SUCCESS_EDIT);
      } else {
        toast.error(TOAST_MESSAGE.ERROR_EDIT);
      }
    });
  };

  const handleChangeSelectPresets = (e) => {
    setSelectedPresets(e.target.value);
  };

  const handleClickCopyIcon = (event, text) => {
    event.preventDefault();
    try {
      toast.success(USER_DETAIL_MESSAGES.COPY_ID_SUCCESS);
      navigator.clipboard.writeText(text);
    } catch (err) {
      toast.error(USER_DETAIL_MESSAGES.COPY_ID_FAILED);
    }
  };

  const formExistsedData = useMemo(() => {
    return Object.entries(scheduleChangeProfile)
      .filter(([key, value]) => {
        return (
          [
            'label',
            'newMemberTypeValue',
            'newStandbyValue',
            'newRankValue',
            'newAdcodeValue',
          ].includes(key) && !_.isNull(value)
        );
      })
      .map(([key, value]) => [key, String(value)]);
  }, [scheduleChangeProfile]);

  return (
    <div style={{ position: 'relative' }}>
      {confirmVisible && (
        <RemoveModal
          visible={confirmVisible}
          onClose={handleClose}
          scheduleChangeProfileId={scheduleChangeProfile?.id}
          reload={reload}
          onCloseDetail={onClose}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={!!scheduleChangeProfile}
        onClose={onClose}
        aria-labelledby="max-width-dialog-title"
        className={classNames(styles.modal, 'd-modal-wrapper')}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: '10px',
          }}
        >
          <DialogTitle
            id="max-width-dialog-title"
            className={'d-title-modal-custom'}
          >
            プロフィールの変更（スケジュール)
          </DialogTitle>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 24px',
              gap: '8px',
            }}
          >
            <Button
              variant="contained"
              className="btn-custom btn-red "
              onClick={onClose}
            >
              閉じる
            </Button>
            <Button
              variant="contained"
              className="btn-custom btn-blue "
              onClick={handleUpdate}
            >
              更新
            </Button>
          </div>
        </div>
        <div className={'d-title-info-box'}>
          <div
            className={'text mb-0'}
            dangerouslySetInnerHTML={{
              __html: TEXT_HTML_MANAGEMENT_TIMER_STANDBY_CHAT_LINK_MODAL,
            }}
          ></div>
        </div>

        <DialogContent>
          <Row>
            <Col xs={6}>
              <Card className="card card-body mb-2">
                <Row className="mt-2">
                  <Col xs={12}>
                    <ChangeProfileForm
                      onChange={handleChangeProfileForm}
                      existedData={formExistsedData}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={12}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <FormLabel component="legend">Search Result</FormLabel>
                      <Button
                        onClick={(e) =>
                          handleClickCopyIcon(
                            e,
                            JSON.stringify(
                              scheduleChangeProfile.searchResult,
                              null,
                              2,
                            ),
                          )
                        }
                        style={{ padding: '0', minWidth: 'fit-content' }}
                      >
                        <FileCopyOutlined style={{ fontSize: 18 }} />
                      </Button>
                    </div>
                    <div
                      style={{
                        height: '100px',
                        border: '1px solid gray',
                        overflow: 'hidden',
                      }}
                    >
                      {JSON.stringify(
                        scheduleChangeProfile.searchResult,
                        null,
                        2,
                      )}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={6}>
              <Card className="card card-body mb-2">
                <Row>
                  <Label xs={6} style={style}>
                    <p>配信カテゴリー</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {
                      scheduleSendCategoryOptions.find(
                        (e) => e.value === +scheduleChangeProfile.category,
                      )?.label
                    }
                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>送信間隔（分）</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {scheduleChangeProfile.frameTime &&
                      `${dayjs.duration(+scheduleChangeProfile.frameTime).asMinutes()}分`}
                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>次回送信日時</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {Util.timestampToDate(
                      scheduleChangeProfile.sendTime,
                      'YYYY/MM/DD HH:mm:ss',
                    )}
                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>最終送信日時</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {Util.timestampToDate(
                      scheduleChangeProfile.lastSentDate,
                      'YYYY/MM/DD HH:mm:ss',
                    )}
                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>First_Send_Date（最終入金用）</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {Util.timestampToDate(
                      scheduleChangeProfile.firstSendDate,
                      'YYYY/MM/DD HH:mm:ss',
                    )}
                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>登録日</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {Util.timestampToDate(
                      scheduleChangeProfile.createdDate,
                      'YYYY/MM/DD HH:mm:ss',
                    )}

                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>送信元ユーザー</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {scheduleChangeProfile.sender?.nickName}
                    <hr style={stylehrright} />
                  </Label>
                  <Label xs={6} style={style}>
                    <p>配信中ステイタス</p>
                    <hr style={stylehrleft} />
                  </Label>
                  <Label xs={6} style={style}>
                    {displayTextScheduleCronjobTypeOptions(
                      scheduleChangeProfile.cronjobStatus,
                    )}
                    <hr style={stylehrright} />
                  </Label>
                </Row>
                <Row className="mt-2">
                  <Col xs={12}>
                    <FormLabel component="legend">配信設定</FormLabel>
                    <RadioGroup
                      defaultValue={scheduleChangeProfile.sendStatus}
                      name="sendStatusDetail"
                      options={scheduleSendStatusOptions}
                      onChange={handleChange}
                      // classCustom={styles.customRadioGroup}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <SearchPresetSelect
                      isShow={true}
                      isHideLoadBtn
                      selectedPresets={selectedPresets}
                      handleChange={handleChangeSelectPresets}
                      titleStyle={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontWeight: '400',
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Button
            variant="contained"
            className="btn-custom btn-red float-right"
            onClick={handleRemove}
            style={{ marginBottom: 2 }}
          >
            削除
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
