import React, { useEffect, useState } from 'react';
import LoadingBar from '../../Components/LoadingBar';
import { useDispatch, useSelector } from 'react-redux';
import { loadListNameRank } from '../../Redux/Actions/nameRank';
import RankSettingList from './RankSettingList';

const pageSize = 100;
const mutating = false;

function RankSetting() {
  const [pageIndex, setPageIndex] = useState(1);
  const loading = useSelector((state) => state.nameRank.loadingListRank);
  const nameRank = useSelector((state) => state.nameRank.dataListRank);
  const totalRow = useSelector((state) => state.nameRank.totalRowListRank);
  const [nameRankLeft, setNameRankLeft] = useState([]);
  const [nameRankRight, setNameRankRight] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadListNameRank({ pageIndex, pageSize }));
  }, [dispatch, pageIndex]);

  useEffect(() => {
    if (nameRank) {
      const halfLength = Math.ceil(nameRank.length / 2);
      setNameRankLeft(nameRank.slice(0, halfLength));
      setNameRankRight(nameRank.slice(halfLength, nameRank.length));
    }
  }, [nameRank]);

  // const handlePageChange = (e, pageIndex) => {
  //   setPageIndex(pageIndex);
  // };

  return (
    <div>
      <LoadingBar show={loading || mutating} />
      <RankSettingList
        loading={loading}
        nameRank={nameRank}
        pageIndex={pageIndex}
        pageSize={pageSize}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
      {/* <Pagination
        pageIndex={pageIndex || 1}
        pageSize={pageSize}
        totalRow={totalRow}
        onPageChange={handlePageChange}
      /> */}
    </div>
  );
}

export default RankSetting;
